// Define Composable
export default function useUtils() {
  /** Helper function to scroll to specific DOM element */
  function scrollTo(selector) {
    setTimeout(() => {
      const domElement = document.querySelector(selector)
      if (domElement) {
        domElement.scrollIntoView()
      }
    }, 100)
  }

  /** Method to go to a specific page while refreshing the page */
  function goToPage(url) {
    const link = new URL(url, window.location.origin)
    window.location.href = link.toString()
  }

  /** Check if given string is a valid JSON */
  function isJSON(str) {
    try {
      return JSON.parse(str) && !!str
    } catch (e) {
      return false
    }
  }

  function toUppercase(val) {
    return val.toUpperCase()
  }

  function toCapitalize([first, ...rest]) {
    return first.toUpperCase() + rest.join('').toLowerCase()
  }

  function truncateString(data, num) {
    const reqdString = data.split('').slice(0, num).join('')
    const ellipse = data.split('').length > num ? '...' : ''
    return `${reqdString}${ellipse}`
  }

  /** Returns a merged array, considering input can be `null` + removing null members */
  function getMergedArray(...args) {
    const mergedWithNil = [].concat(...args)
    const mergedWithoutNil = mergedWithNil.filter((i) => i)
    const uniqMerged = [...new Set(mergedWithoutNil)]

    return uniqMerged
  }

  /** Delays execution of code for x milliseconds (promise-based) */
  function delay(time) {
    // await promiseTimeout(time)
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  /** Fix decimal point for a number */
  function fixDecimalPoint(value, dp) {
    return +parseFloat(value).toFixed(dp)
  }

  function convertToSnakeCase(str) {
    return str.replace(/[\s\W]+/g, '_').toLowerCase()
  }

  /** Getting a random integer between two values */
  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
  }

  /** Get object key by its value */
  function getObjKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  return {
    scrollTo,
    goToPage,
    isJSON,
    toUppercase,
    toCapitalize,
    getMergedArray,
    truncateString,
    delay,
    fixDecimalPoint,
    convertToSnakeCase,
    getRandomInt,
    getObjKeyByValue
  }
}
