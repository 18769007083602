/*
|--------------------------------------------------------------------------
| Store > Statistics > Actions > loadCustomerStatistics
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'

/**
 * Action for loading Statistics data.
 *
 * @param {object} context - Current context of Vuex module.
 * @param {object} payload - Contains necessary values.
 * @param {int} [payload.user_type=2] - Defines to which user type that statistics to be loaded are for.
 * @param {string} [payload.from_date=''] - Date (from) range value to filter the data.
 * @param {string} [payload.to_date=''] - Date (to) range value to filter the data.
 * @param {string} [payload.sector_scope=''] - Sector scope to filter the data.
 * @returns {*}
 */
export default function (context, payload = {}) {
  if (payload.user_type === 2) context.commit('setCustomerStatisticsLoading', true)
  else if (payload.user_type === 3) context.commit('setTranslatorStatisticsLoading', true)

  // Set defaults for payload values.
  payload.user_type = payload.user_type || 2
  payload.from_date = payload.from_date || ''
  payload.to_date = payload.to_date || ''
  payload.sector_scope = payload.sector_scope || ''

  const method = 'GET'
  let url = API.STATISTICS

  let queryParams = []

  // If Date filters exist, add them as query params.
  if (payload.from_date !== '' && payload.to_date !== '') {
    queryParams.push(`date_range=due,${payload.from_date},${payload.to_date}`)
  }

  if (payload.sector_scope !== '') {
    queryParams.push(`customer_level=${payload.sector_scope}`)
  }

  if (queryParams.length > 0) {
    url = `${url}?${queryParams.join('&')}`
  }

  return new Promise((resolve, reject) => {
    axiosInstance({ method, url })
      .then((r) => {
        if (payload.user_type === 2) {
          // If user is a customer type.
          context.commit('setCustomerStatisticsData', r.data.data)
        } else if (payload.user_type === 3) {
          // If user is a translator type.
          context.commit('setTranslatorStatisticsData', r.data.data)
        }
        if (payload.user_type === 2) context.commit('setCustomerStatisticsLoading', false)
        else if (payload.user_type === 3) context.commit('setTranslatorStatisticsLoading', false)
        resolve(r)
      })
      .catch((e) => {
        if (payload.user_type === 2) context.commit('setCustomerStatisticsLoading', false)
        else if (payload.user_type === 3) context.commit('setTranslatorStatisticsLoading', false)
        reject(e)
      })
  })
}
