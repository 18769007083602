import { freshForm, freshCancelForm } from '@/modules/helpers/leaveRequests'

/*
|--------------------------------------------------------------------------
| Store > Mutations
|--------------------------------------------------------------------------
| File that contains the mutations property for the current Vuex scope.
*/
export default {
  setLeaveRequestListData(state, payload) {
    state.leaveRequests.data = payload
  },

  setLeaveRequestListPagination(state, payload) {
    state.leaveRequests.pagination = payload
  },

  setLeaveRequestSummariesData(state, payload) {
    state.leaveRequests.summaries = payload
  },

  setLeaveRequestListLoading(state, payload) {
    state.leaveRequests.loading = payload
  },

  setLeaveRequestSummariesLoading(state, payload) {
    state.leaveRequests.loadingSummaries = payload
  },

  setLeaveRequestLoading(state, payload) {
    state.leaveRequestForm.loading = payload
  },

  setConflictedBookingIds(state, payload) {
    state.leaveRequestForm.conflictedBookingIds = payload
  },

  resetLeaveRequestForm(state) {
    state.leaveRequestForm = freshForm()
  },

  setCancelLeaveRequestLoading(state, payload) {
    state.cancelLeaveRequestForm.loading = payload
  },

  resetCancelLeaveRequestForm(state) {
    state.cancelLeaveRequestForm = freshCancelForm()
  }
}
