/*
|--------------------------------------------------------------------------
| Custom > Constants > File Type
|--------------------------------------------------------------------------
|
| This file contains the constants for File Type which value is
| equivalent to the File extensions.
|
*/

export const DOCUMENTS = ['.pdf', '.docx', '.doc', '.ppt', '.pptx', '.txt', '.xls', '.xlsx', '.csv']

export const IMAGES = ['.JPEG', '.jpg', '.png']

export const AUDIOS = ['.mp3', '.m4a', '.flac', '.mp4', '.wav', '.wma', '.aac', '.amr']

export const VIDEOS = ['.mp4', '.mov', '.avi', '.wmv', '.avchd', '.webm', '.flv']

export default {
  DOCUMENTS,
  IMAGES,
  AUDIOS,
  VIDEOS
}
