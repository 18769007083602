/*
|--------------------------------------------------------------------------
| Store > Booking > State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

import cloneDeep from 'lodash/cloneDeep'
import VIDEO_OPTIONS from '@/modules/constants/videoOptions'
import { getTranslatorLevelsArray } from '@/modules/constants/translatorLevel'

let freshTimeSlot = {
  date: '',
  start_time: '',
  end_time: '',
  key: Date.now()
}

let freshSlotRangeForm = {
  date: '',
  start_time: '',
  end_time: ''
}

let formFresh = {
  dues: [],
  same_translator: false,
  repeat_period: 1,
  monthly_dates: [],
  repeat_frequency: 'weekly',
  repeat_days: ['monday'],
  monthly_selection: 'days',
  excluded_recurring_dates: [],
  end_on_date: '',
  start_on_date: '',
  is_immediate: false,
  language: '',
  countries: [],
  countries_required: false,
  date: '',
  time: '', // container for start time
  end_time: '', // container for end time
  duration: '',
  type: 'phone',
  gender: ['male', 'female'],
  translator_levels: getTranslatorLevelsArray().map((tl) => tl.id),
  specific_translators: [],
  excluded_translators: [],

  convey: {
    message: '',
    phone: '',
    email: '',
    additional_info: ''
  },

  convey_files: [],

  // booking message and attachments required params
  booking_message: {
    message: '',
    files: []
  },

  message_files: [],

  is_created_by_colleague: false,
  customer_id: '',
  created_by: 0,
  colleague: {},
  excluded_towns: [],
  translator_trainings: [],
  is_created_by_admin: false,
  search_customer_by: 'id',
  assigned_translator: [],
  dialect: [],
  booking_is_past: false,
  new_customer: false,
  dialect_reason: false,
  customer_object: null,
  time_slots: [cloneDeep(freshTimeSlot)],
  slot_range: cloneDeep(freshSlotRangeForm),
  created_via_chatwoot: '',
  chatwoot_conversation_id: '',
  chatwoot_conversation_message_id: ''
}

let confirmFormFresh = {
  booker_name: '',
  client_phone: '',
  staff_name: '',
  reference: '',
  additional_field: '',
  is_valid_address: true,
  address: '',
  address_2: '',
  city: '',
  instructions: '',
  notification_type: ['email'], // Varies between : email | fax | sms
  notification_confirmation_via: {
    email: [],
    fax: '',
    sms: ''
  },
  alternative_languages: [],
  is_show_alternative_languages_field: null,
  allow_change_type: true,
  fallback_type: '',

  // Questions when there are specified booking property requested.
  booking_specific_preference: {
    specific_translator: null,
    gender: null,
    country: null,
    translator_level: null,
    translator_level_alternative: [],
    physical: null,
    travel_time: null,
    travel_time_minutes: null,
    travel_time_km: null,
    encourage_phone: null,

    was_asked_exclude_translator: false,
    was_asked_specific_translator: false,
    was_asked_gender: false,
    was_asked_translator_level: false,
    was_asked_country: false,
    was_asked_physical: false,
    was_asked_travel_time: false,
    was_asked_encourage_phone: false,
    was_asked_alternative_language: false,
    is_notify_at_preference_timeout: false,
    was_asked_suggest_other_times: '',

    // Not included in the API request format.
    has_contract_to_convert_to_phone: false
  },

  // video type required params
  isShowVideo: false,
  video_provider: '',
  video_provider_other: '',
  video_url: '',
  message: '',
  staff_phone: '',

  // appointment address params.
  client_appointment_type: 'phone', // phone or physical
  appointment_address: '',
  appointment_city: '',
  appointment_instructions: '',
  appointment_is_valid_address: true
}

let bcfConfigFresh = {
  booker_name_enabled: false,
  booker_name_required: false,
  staff_name_enabled: false,
  staff_name_required: false,
  reference_enabled: false,
  reference_placeholder: '',
  reference_label: '',
  reference_required: false,
  instructions_enabled: false,
  instructions_required: false,
  alt_language_enabled: false,
  alt_language_required: false,
  exceed_travel_time_enabled: false,
  encourage_phone_enabled: false,
  force_phone_enabled: false,
  force_phone_on_shortage_enabled: false,
  client_phone_enabled: false,
  client_phone_required: false,
  // additional field
  additional_field_enabled: false,
  additional_field_required: false,
  additional_field_autosuggestion: true,
  additional_field_placeholder: '',
  additional_field_label: '',
  staff_phone_enabled: false,
  staff_phone_required: false
}

let confirmSuggestionsFresh = {
  email: [],
  reference: [],
  booker_name: [],
  staff_name: [],
  translator_user: [],
  instructions: [],
  staff_phone: []
}

const translatorShortagesFresh = {
  count: false,
  specific_translator: false,
  gender: false,
  translator_level: false,
  physical: false,
  language: false,
  country: false
}

const videoConfigFresh = {
  is_video_jitsi_enabled: true,
  is_video_skype_enabled: true,
  is_video_physical_enabled: true,
  video_platform_options: [],
  default_video_option: '',
  default_video_platform: ''
}

const customerFormFresh = {
  name: '',
  email: '',
  address: '',
  city: '',
  department_id: '',
  generate_general_login: false,
  allow_send_invoices: false,
  allow_show_invoices: false,
  allow_fallback_type: false,
  allow_sms_confirmation: false,
  new_department: false
}

const departmentFormFresh = {
  add_on_confirmation_setting: '',
  add_on_confirmation: [],
  blacklisted_users: [],
  company_id: '',
  cost_place: '',
  customer_category_id: '',
  customer_success_manager_id: '',
  email_setting_status: '',
  email_setting_enabled: false,
  name: '',
  notes: '',
  number_settings_status: '',
  number_settings_number: '',
  one_booking_per_invoice: '',
  reference_person: '',
  responsible_salesman_id: '',
  statistic_report_schedule_setting: {
    cycle: 0
  }
}

const guestFormFresh = {
  first_name: '',
  last_name: '',
  email: '',
  phone: ''
}

export default function () {
  return {
    // States for options/selections ---
    languageOpts: {
      data: {},
      loading: false
    },
    deepLLanguagesOpts: {
      data: {},
      loading: false
    },
    recruitmentLanguageOpts: {
      data: {},
      loading: false
    },
    languageIsoCodes: [],
    appointmentTemplates: [],
    countryOpts: [],
    languageCountries: [],
    // Container for Booking Form Data.
    freshSlotRangeForm: cloneDeep(freshSlotRangeForm),
    freshTimeSlot: cloneDeep(freshTimeSlot),
    form: cloneDeep(formFresh),
    multipleBookings: [cloneDeep(formFresh)],
    formFresh: cloneDeep(formFresh),
    formPayload: {},
    multipleBookingFormPayload: {},
    multipleBookingConfirmFormPayload: [],
    // Container for Booking Confirm For Data.
    confirmForm: cloneDeep(confirmFormFresh),
    confirmFormFresh: cloneDeep(confirmFormFresh),
    confirmFormPayload: {},
    confirmFormCache: {},
    isConfirmFormHalted: false,
    isShowBookingConfirmModal: false,
    isShowRecurringBookingConfirmModal: false,
    isShowMultipleBookingConfirmModal: false,
    bookingConfirmModalUsedBy: 'normal',

    isLoadingValidate: false,
    isLoadingSubmit: false,
    isLoadingPaymentSetup: false,
    isConfirmBooking: false,
    reloadUserFiles: false,

    disableConfirmBookingButton: false,
    // Container for the newly created bookings.
    // Note: Only for the purpose of displaying it to other pages.
    createdBooking: {},

    // Container for duplicated bookings.
    // Note: Only used for the duplicated booking to be easily accessible.
    duplicatedBooking: {},

    // Container for confirm fields suggestions
    confirmSuggestions: cloneDeep(confirmSuggestionsFresh),
    confirmSuggestionsFresh: cloneDeep(confirmSuggestionsFresh),

    // Container for the customer's Booking Confirm Fields Config values.
    bcfConfig: cloneDeep(bcfConfigFresh),
    bcfConfigFresh: cloneDeep(bcfConfigFresh),

    translatorShortages: cloneDeep(translatorShortagesFresh),
    translatorShortagesFresh: cloneDeep(translatorShortagesFresh),

    availableTranslators: 0,

    assignmentProbability: 0,

    availableTranslatorsByPreferences: {},

    // States Customer for Normal Booking ---
    customerNormalBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    customerNormalBookingForm: {},
    customerNormalBookingPayload: {},

    // States for Customer Emergency Booking ---
    customerEmergencyBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    customerEmergencyBookingForm: {},
    customerEmergencyBookingPayload: {},

    // States for Customer Historic Booking ---
    customerHistoricBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States for Customer and Translator Other Booking ---
    customerAndTranslatorOtherBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States for Translator Potential Booking ---
    translatorPotentialBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    translatorPresumptivePotentialBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States Translator for Normal Booking ---
    translatorNormalBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States for Translator Historic Booking ---
    translatorHistoricBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States Customer for Normal Booking ---
    customerUpcomingBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    customerRecentBookings: {
      data: [],
      pagination: {},
      loading: true
    },
    customerTotalBookings: 0,
    dtLanguagesCookieKey: 'dt_languages',
    dtLanguagesUpdatedCookieKey: 'dt_languages_updated_at',
    dtRecruitmentLanguagesCookieKey: 'dt_recruitment_languages',
    dtRecruitmentLanguagesUpdatedCookieKey: 'dt_recruitment_languages_updated_at',
    dtDeepLLanguagesCookieKey: 'dt_deepl_languages',
    dtDeepLLanguagesUpdatedCookieKey: 'dt_deepl_languages_updated_at',
    newLastUpdate: null,
    filterBookingQueryForExport: {},
    recurring_dates: [],

    //Expense settings
    expense_settings: {},

    acceptRejectBookingLoading: false,

    isDisableNonVocalLanguages: false,

    nonVocalLanguages: null,
    bookingDefaultLoading: false,
    // Container for the customer's Booking Confirm Fields Config values.
    videoConfig: cloneDeep(videoConfigFresh),
    videoConfigFresh: cloneDeep(videoConfigFresh),
    videoOptions: VIDEO_OPTIONS,
    customersList: [],
    customerForm: cloneDeep(customerFormFresh),
    customerFormFresh: cloneDeep(customerFormFresh),
    departmentsList: [],
    departmentForm: cloneDeep(departmentFormFresh),
    departmentFormFresh: cloneDeep(departmentFormFresh),
    companyList: [],
    customerCategoryList: [],
    adminUserList: [],
    specificTranslatorsNames: '',
    guestPayloadForm: cloneDeep(guestFormFresh),
    guestPayloadFormErrors: cloneDeep(guestFormFresh),
    skipFirstConfirmationStep: false,
    mustGoToFirstConfirmationStep: false,
    priceQuote: {
      price: 650,
      // tax_percentage: 0,
      // tax: 2.2,
      total: 650
    },
    languageCallUs: []
  }
}
