/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > validateBooking
|--------------------------------------------------------------------------
|
*/
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import API from '@/modules/constants/api'

/**
 * Action to validate the booking to make sure values are right,
 * and then return the additional values to be used for booking confirmation
 * as response.
 *
 * @param {object} context
 *   The scope of the Vuex store
 *
 * @return {*}
 */
export default function (context) {
  // Prepare the payload to be sent across the API.
  context.commit('setFormPayload', {
    user: context.rootGetters['auth/userMetaData']
  })
  context.commit('setValidateLoading', true)

  const url = `${API.BOOKINGS}/validate`
  const method = 'post'
  const data = {
    ...context.state.formPayload,
    to_language_id: context.state.formPayload.to_languages[0]
  }

  const fileTypes = [
    'text_translation_document_files',
    'text_translation_image_files',
    'text_translation_audio_files',
    'text_translation_video_files',
    'text_translation_files'
  ]

  fileTypes.forEach((fileType) => {
    if (data.hasOwnProperty(fileType)) {
      delete data[fileType]
    }
  })

  const options = {
    enable_error_notif: true,
    data,

    on_success(r, ctx) {
      // Set the following states.
      ctx.commit('setBcfConfig', r.data.booking_field)
      ctx.commit('setConfirmSuggestions', r.data.confirmation_fills)
      ctx.commit('setAvailableTranslators', r.data.total_translators)
      ctx.commit('setConfirmForm', {
        data: r.data.confirmation_fills,
        user: ctx.rootGetters['auth/userMetaData']
      })
      // ctx.commit('setTranslatorShortages', r.data);
    },

    on_finally(ctx) {
      ctx.commit('setValidateLoading', false)
    }
  }

  return reach(this, context, url, method, options, axiosInstance)
}
