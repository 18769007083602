/*
|--------------------------------------------------------------------------
| Store > Booking > Mutations
|--------------------------------------------------------------------------
|
| This file is where the Module's mutation methods are registered.
|
| Feel free to edit this file if you have mutation methods you want
| to include.
|
*/

import { listSetter, listAsOptionsSetter } from '@/modules/helpers/vuexMutations'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import remove from 'lodash/remove'
import cloneDeep from 'lodash/cloneDeep'
import find from 'lodash/find'
import includes from 'lodash/includes'
import assign from 'lodash/assign'
import unset from 'lodash/unset'
import isDate from 'lodash/isDate'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import moment from 'moment-timezone'
import VIDEO_OPTIONS from '@/modules/constants/videoOptions'
import { getCachedBooking } from '@/modules/booking/services/cachedBookingService'
import { PHYSICAL, VIDEO_PHYSICAL } from '@/modules/constants/bookingType'
import { filter } from 'lodash'
import store from '@/store'
import { getTranslatorLevelsArray } from '@/modules/constants/translatorLevel'

export default {
  // --------------------------------------------------
  // Customer Normal Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.customerNormalBookingList.data and
   * state.customerNormalBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerNormalBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerNormalBookingList.data',
      pagination: 'customerNormalBookingList.pagination'
    })
  },

  /**
   * Method to set the state.customerNormalBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerNormalBookingListLoading(state, payload) {
    state.customerNormalBookingList.loading = payload
  },

  // --------------------------------------------------
  // Customer and Translator Other Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.customerAndTranslatorOtherBookingList.data and
   * state.customerAndTranslatorOtherBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerAndTranslatorOtherBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerAndTranslatorOtherBookingList.data',
      pagination: 'customerAndTranslatorOtherBookingList.pagination'
    })
  },

  /**
   * Method to set the state.customerAndTranslatorOtherBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerAndTranslatorOtherBookingListLoading(state, payload) {
    state.customerAndTranslatorOtherBookingList.loading = payload
  },

  // --------------------------------------------------
  // Customer Emergency Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.customerEmergencyBookingList.data and
   * state.customerEmergencyBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerEmergencyBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerEmergencyBookingList.data',
      pagination: 'customerEmergencyBookingList.pagination'
    })
  },

  /**
   * Method to set the state.customerEmergencyBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerEmergencyBookingListLoading(state, payload) {
    state.customerEmergencyBookingList.loading = payload
  },

  // --------------------------------------------------
  // Customer Historic Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.customerHistoricBookingList.data and
   * state.customerHistoricBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerHistoricBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerHistoricBookingList.data',
      pagination: 'customerHistoricBookingList.pagination'
    })
  },

  /**
   * Method to set the state.customerHistoricBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerHistoricBookingListLoading(state, payload) {
    state.customerHistoricBookingList.loading = payload
  },
  /**
   * Method to set the Number of customer's total bookings value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerTotalBookings(state, payload) {
    state.customerTotalBookings = payload.total
  },

  // --------------------------------------------------
  // Translator Potential Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.translatorPotentialBookingList.data and
   * state.translatorPotentialBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorPotentialBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorPotentialBookingList.data',
      pagination: 'translatorPotentialBookingList.pagination'
    })
  },

  setTranslatorPresumptivePotentialBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorPresumptivePotentialBookingList.data',
      pagination: 'translatorPresumptivePotentialBookingList.pagination'
    })
  },

  /**
   * Method to set the state.translatorPotentialBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorPotentialBookingListLoading(state, payload) {
    state.translatorPotentialBookingList.loading = payload
  },

  setTranslatorPresumptivePotentialBookingListLoading(state, payload) {
    state.translatorPresumptivePotentialBookingList.loading = payload
  },

  // --------------------------------------------------
  // Translator Normal Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.translatorNormalBookingList.data and
   * state.translatorNormalBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorNormalBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorNormalBookingList.data',
      pagination: 'translatorNormalBookingList.pagination'
    })
  },

  /**
   * Method to set the state.translatorNormalBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorNormalBookingListLoading(state, payload) {
    state.translatorNormalBookingList.loading = payload
  },

  // --------------------------------------------------
  // Translator Historic Booking List
  // --------------------------------------------------

  /**
   * Method to set the state.translatorHistoricBookingList.data and
   * state.translatorHistoricBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorHistoricBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'translatorHistoricBookingList.data',
      pagination: 'translatorHistoricBookingList.pagination'
    })
  },

  /**
   * Method to set the state.translatorHistoricBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setTranslatorHistoricBookingListLoading(state, payload) {
    state.translatorHistoricBookingList.loading = payload
  },

  // --------------------------------------------------
  // Langauge Opts
  // --------------------------------------------------

  /**
   * Method to set the state.languageOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setLanguageOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'languageOpts.data')
    listAsOptionsSetter(state, payload, 'languageCountries', {
      key: 'id',
      value: 'countries'
    })
    listAsOptionsSetter(state, payload, 'languageIsoCodes', {
      key: 'id',
      value: 'iso_6393'
    })
    listAsOptionsSetter(state, payload, 'languageCallUs', {
      key: 'id',
      value: 'call_us'
    })
  },
  /**
   * Method to set the recruitment language options -> state.recruitmentLanguageOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setRecruitmentLanguageOpts(state, payload) {
    listAsOptionsSetter(state, payload, 'recruitmentLanguageOpts.data')
  },

  /**
   * Method to set the deepl language options -> state.deepLLanguageOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setDeepLLanguagesOpts(state, payload) {
    state.deepLLanguagesOpts = payload
  },
  /**
   * Method to set the state.countryOpts value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCountryOpts(state, payload) {
    state.countryOpts = payload
  },

  /**
   * Method to set the state.languageOpts.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setLanguageOptsLoading(state, payload) {
    state.languageOpts.loading = payload
  },

  setUserBookingFilesFromProfile(state, payload) {
    state.form.message_files = payload

    if (state.formPayload && state.formPayload.message_files) {
      state.formPayload.message_files = payload
    }
  },

  setReloadUserFiles(state, payload) {
    state.reloadUserFiles = payload
  },

  // --------------------------------------------------
  // Payloads
  // --------------------------------------------------

  /**
   * Method to prepare the payload before sending it tru API
   * for validating the booking.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload
   * @param {object} payload.user - contains user data
   * @returns {void}
   */
  setFormPayload(state, payload) {
    const source = state.form
    let dest = {}

    // Set language.
    dest.from_language_id = source.language

    // Set date.
    if (!isNil(source.date) && source.date !== '') {
      if (isDate(source.date)) {
        // noinspection JSValidateTypes
        dest.date = moment(source.date).local().format('YYYY-MM-DD')
      } else if (isString(source.date)) {
        dest.date = source.date
      }
    }

    // Set time.
    if (!isNil(source.time) && source.time !== '') {
      dest.time = source.time.length === 8 ? source.time.trim() : source.time.trim() + ':00'
    }

    // Set duration.
    dest.duration = source.duration

    // Set is_immmediate.
    dest.is_immediate = source.is_immediate ? 1 : 0

    // Set type.
    dest.type = source.type

    // Set gender
    dest.gender = !source.gender.length || source.gender.length === 2 ? '' : source.gender[0]

    // Set translator_levels
    dest.translator_levels = source.translator_levels

    // Set specific_translators
    if (source.specific_translators.length > 0) {
      dest.specific_translators = source.specific_translators
    }

    // Set excluded_translators
    if (source.excluded_translators.length > 0) {
      dest.excluded_translators = source.excluded_translators
    }

    // set payload for convey
    if (dest.type === 'convey') {
      dest.convey = source.convey
      dest.convey_files = source.convey_files
      dest.duration = 15
    }

    // set payload for video
    if (source.type === 'video') {
      dest.video_provider = 'jitsi'
      dest.video_url = ''
    }

    // set default payload for message
    dest.booking_message = source.booking_message

    // set message files
    dest.message_files = source.message_files

    dest.is_created_by_colleague = source.is_created_by_colleague

    // set countries.
    dest.countries_required = source.countries_required ? 1 : 0
    dest.countries = source.countries

    if (dest.is_created_by_colleague) {
      dest.customer_id = source.colleague.id
      dest.created_by = payload.user.id
      if (source.colleague?.customer_data?.address) {
        dest.address = source.colleague.customer_data.address
        dest.city = source.colleague.customer_data.city
      }
    } else if (source.is_created_by_admin) {
      dest.is_created_by_admin = source.is_created_by_admin
      dest.customer_id = source.customer_id
      dest.created_by = payload.user.id
      dest.assigned_translator = source.assigned_translator[0]
      dest.create_booking_in_past = source.booking_is_past
      dest.customer_object = source.customer_object
      if (source.customer_object?.customer_data?.address) {
        dest.address = source.customer_object.customer_data.address
        dest.city = source.customer_object.customer_data.city
      }
    } else {
      unset(dest, 'customer_id')
      unset(dest, 'created_by')
      if (payload.user?.customer_data?.address) {
        dest.address = payload.user.customer_data.address
        dest.city = payload.user.customer_data.city
      }
    }

    if (!isNil(source.excluded_towns)) {
      dest.excluded_towns = source.excluded_towns
    }

    if (!isNil(source.translator_trainings)) {
      dest.translator_trainings = source.translator_trainings
    }

    const selectedTimeSlots = filter(source.time_slots, (item) => item.date && item.start_time && item.end_time)
    dest.time_slots = selectedTimeSlots ?? []

    dest.created_via_chatwoot = source.created_via_chatwoot
    dest.chatwoot_conversation_id = source.chatwoot_conversation_id
    dest.chatwoot_conversation_message_id = source.chatwoot_conversation_message_id
    state.formPayload = dest
  },

  // --------------------------------------------------
  // Booking Confirm Related
  // --------------------------------------------------

  /**
   * @param {object} state - Vuex context's state.
   * @param {boolean} bool
   * @return {void}
   */
  setIsConfirmFormHalted(state, bool) {
    state.isConfirmFormHalted = bool
  },

  /**
   * @param {object} state - Vuex context's state.
   * @return {void}
   */
  cacheConfirmFormValues(state) {
    state.confirmFormCache = cloneDeep(state.confirmForm)
  },

  /**
   * Method to set the Confirm Values from the API to the confirmForm state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @param {object} payload.data - The collection of confirmation_fills.
   * @param {object} payload.user - Contains the current user and its properties.
   * @returns {void}
   */
  setConfirmForm(state, payload = {}) {
    console.log('----------')
    console.log('setConfirmForm')
    // Reset the form first.
    state.confirmForm = cloneDeep(state.confirmFormFresh)

    // If confirm form was halted after booking overview
    if (state.isConfirmFormHalted) {
      state.confirmForm = cloneDeep(state.confirmFormCache)
      return
    }

    let bookingCustomer = payload.user
    if (state.formPayload.is_created_by_admin) {
      bookingCustomer = state.formPayload.customer_object
    }

    // The collection of customer_notification_types.
    const customerNotificationTypes = bookingCustomer.customer_notification_types

    // Define helper for getting the notification type object in collection.
    let getNotif = (k) =>
      find(customerNotificationTypes, (o) => {
        return o.notification_type === k
      })

    // Set address
    state.confirmForm.address = bookingCustomer.customer_data?.address
    state.confirmForm.address_2 = bookingCustomer.customer_data?.address_2
    state.confirmForm.instructions = bookingCustomer.customer_data?.address_2

    // Set city
    state.confirmForm.city = bookingCustomer.customer_data.city

    // If fax exists from the response.
    const notifFax = getNotif('fax')
    if (!isNil(notifFax) && !isNil(notifFax.default_recipient)) {
      // Set fax
      state.confirmForm.notification_confirmation_via.fax = notifFax.default_recipient
    }
    // If sms exists from the response.
    const notifSms = getNotif('sms')
    if (!isNil(notifSms) && !isNil(notifSms.default_recipient)) {
      // Set sms
      state.confirmForm.notification_confirmation_via.sms = notifSms.default_recipient
    }

    const cachedBooking = getCachedBooking(bookingCustomer.id)

    // Set cached values
    if (!isNil(cachedBooking) && !isEmpty(cachedBooking)) {
      // Set booker_name
      state.confirmForm.booker_name = cachedBooking.booker_name

      // // Set additional field
      state.confirmForm.additional_field = cachedBooking.additional_field

      // Set staff_name
      state.confirmForm.staff_name = cachedBooking.staff_name
      // Set staff_phone
      state.confirmForm.staff_phone = cachedBooking.staff_phone

      // set staff_phone
      // state.confirmForm.staff_phone = cachedBooking.staff_phone

      // Set reference
      state.confirmForm.reference = cachedBooking.reference

      // Set email
      if (!isNil(cachedBooking.notification_types) && !isEmpty(cachedBooking.notification_types)) {
        // If cached booking value exist. Assign the last value that was used.
        forEach(cachedBooking.notification_types, (o) => {
          if (o.type === 'email') {
            state.confirmForm.notification_confirmation_via.email.push(o.recipient)
          }
        })
      } else {
        // Else, just to the usual and assign the default recipient.
        const notifEmail = getNotif('email')
        if (!isNil(notifEmail) && !isNil(notifEmail.default_recipient)) {
          if (!includes(notifEmail.default_recipient, '@digitaltolk.se')) {
            state.confirmForm.notification_confirmation_via.email.push(notifEmail.default_recipient)
          }
        }
      }
    }

    // If Email is still empty after assigning cached. Prefill with user's email.
    if (
      !isNil(state.confirmForm.notification_confirmation_via.email) &&
      isEmpty(state.confirmForm.notification_confirmation_via.email)
    ) {
      state.confirmForm.notification_confirmation_via.email.push(bookingCustomer.email)
    }

    // set showing video input
    state.confirmForm.isShowVideo = ['video', 'video_skype', 'video_physical'].includes(state.formPayload.type)
    if (state.formPayload.type === 'video') {
      const videoSettings = store.getters['auth/videoSettings']
      //set default booking video
      if (videoSettings.default_video_option != '') {
        state.confirmForm.video_provider = videoSettings.default_video_option
      }
      //filter allowed video options
      if (!isEmpty(videoSettings.video_platform_options)) {
        state.videoOptions = VIDEO_OPTIONS.filter((option) => {
          return videoSettings.video_platform_options.includes(option.value)
        })
      }
      //set default video platform
      state.confirmForm.video_provider_other = videoSettings.default_video_platform
    }

    state.reloadUserFiles = true
  },

  /**
   * Method to set the Guest Confirm Values from the API to the confirmForm state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @param {object} payload.data - The collection of confirmation_fills.
   * @param {object} payload.user - Contains the current user and its properties.
   * @returns {void}
   */
  setGuestConfirmForm(state) {
    console.log('----------')
    console.log('setConfirmForm')
    // Reset the form first.
    state.confirmForm = cloneDeep(state.confirmFormFresh)

    // If confirm form was halted after booking overview
    if (state.isConfirmFormHalted) {
      state.confirmForm = cloneDeep(state.confirmFormCache)
      return
    }
  },

  /**
   * Method to set the Duplicated Confirm Values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  setDuplicatedConfirmForm(state) {
    const sd = (v, d) => (!isNil(v) ? v : d)
    const src = state.duplicatedBooking

    // Reset the form first.
    state.confirmForm = cloneDeep(state.confirmFormFresh)
    state.confirmForm.is_duplicate = true

    if (!isNil(src.message_files)) {
      state.confirmForm.booking_message = src.message_files
    }

    state.confirmForm.booker_name = sd(src.booker_name, '')
    state.confirmForm.staff_name = sd(src.staff_name, '')
    state.confirmForm.reference = sd(src.reference, '')
    state.confirmForm.address = sd(src.address, '')
    state.confirmForm.city = sd(src.city, '')
    state.confirmForm.instructions = sd(src.instructions, '')
    state.confirmForm.notification_type = sd(src.notification_type, '')

    if (
      !isNil(src.notification_confirmation_via) &&
      !isNil(src.notification_confirmation_via.email) &&
      !isEmpty(src.notification_confirmation_via.email)
    ) {
      state.confirmForm.notification_confirmation_via.email = src.notification_confirmation_via.email
    }

    if (!isNil(src.notification_confirmation_via) && !isNil(src.notification_confirmation_via.fax)) {
      state.confirmForm.notification_confirmation_via.email = src.notification_confirmation_via.fax
    }

    if (!isNil(src.notification_confirmation_via) && !isNil(src.notification_confirmation_via.sms)) {
      state.confirmForm.notification_confirmation_via.email = src.notification_confirmation_via.sms
    }
  },

  /**
   * Method to prepare the payload before sending it tru API
   * for creating/submitting the booking.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @param {object} payload.user - Contains the current user and its properties.
   * @return {object}
   */
  setConfirmFormPayload(state, payload = {}) {
    if (isNil(payload.user)) console.error('payload.user is required.')
    console.log('setConfirmFormPayload', payload)
    state.confirmFormPayload = {} // Reset the payload.

    // Define the key : value to be unset. Most of this came from state.confirmForm.
    let unsets = [
      'notification_confirmation_via',
      'notification_type',
      'allow_change_type',
      'end_time',
      'booking_specific_preference.has_contract_to_convert_to_phone'
    ]

    // Map formPayload and confirmForm into payload container.
    console.log('formPayload')
    console.log(state.formPayload)

    console.log('confirmForm')
    console.log(state.confirmForm)

    assign(state.confirmFormPayload, state.formPayload)
    assign(state.confirmFormPayload, state.confirmForm)

    // Set Status ID to PENDING
    state.confirmFormPayload.status_id = 1 // PENDING

    // Set notification types.
    // As of the moment, this is between email|fax
    let notif_type = state.confirmForm.notification_type

    // Instantiate the container if it's undefined.
    state.confirmFormPayload.notification_types = []
    forEach(notif_type, (nt) => {
      if (nt === 'email') {
        let emails = state.confirmForm.notification_confirmation_via.email // Collection of emails.

        forEach(emails, (email) => {
          state.confirmFormPayload.notification_types.push({
            type: 'email',
            recipient: email
          })
        })
      } else if (nt === 'fax') {
        state.confirmFormPayload.notification_types.push({
          type: 'fax',
          recipient: state.confirmForm.notification_confirmation_via['fax']
        })
      } else {
        state.confirmFormPayload.notification_types.push({
          type: 'sms',
          recipient: state.confirmForm.notification_confirmation_via['sms']
        })
      }
    })

    // Remain / Remove values according to their switches.
    forEach(['booker_name', 'staff_name', 'reference', 'instructions'], (v) => {
      if (!state.bcfConfig[v + '_enabled']) unsets.push(v)
    })

    if (state.confirmFormPayload.is_show_alternative_languages_field) {
      let altLangs = state.confirmFormPayload.alternative_languages
      if (!isNil(altLangs) && !isEmpty(altLangs)) {
        state.confirmFormPayload.alternative_languages = map(altLangs, (v) => parseInt(v))
      }
    } else {
      state.confirmFormPayload.alternative_languages = []
    }

    // Force convert the booking to type - phone if they agree on environmental purpose question.
    if (state.confirmFormPayload.booking_specific_preference.encourage_phone === true) {
      // state.confirmFormPayload.type = 'phone'
      state.confirmFormPayload.type = payload.fallbackBookingType
    }

    // Force convert the booking to type - phone.
    if (state.confirmFormPayload.booking_specific_preference.physical === true) {
      console.log('payload.rootState', payload.rootState)
      // fallbackBookingType is either phone or video.
      state.confirmFormPayload.type = payload.fallbackBookingType
    }

    // Set phone as fallback_type if user agrees to accept it while booking a physical type.
    if (
      state.confirmFormPayload.booking_specific_preference.physical === 'accept_phone_but_prefer_physical' ||
      state.confirmFormPayload.booking_specific_preference.encourage_phone === 'accept_phone_but_prefer_physical' ||
      state.confirmForm.has_contract_to_convert_to_phone
    ) {
      state.confirmFormPayload.fallback_type = 'phone'
    } else if (state.confirmFormPayload.booking_specific_preference.physical === 'accept_video_but_prefer_physical') {
      state.confirmFormPayload.fallback_type = 'video'
    } else {
      // clear out the fallback type if none of the above.
      state.confirmFormPayload.fallback_type = ''
    }

    // Unset fallback type if it's not allowed.
    const isAllowChangeType = state.confirmFormPayload.allow_change_type
    if (!isAllowChangeType || ![PHYSICAL, VIDEO_PHYSICAL].includes(state.confirmFormPayload.type)) {
      unsets.push('fallback_type')
    }

    // //append booking message and attachments
    // if (!isNil(state.confirmFormPayload.booking_message.files) || !isEmpty(state.confirmFormPayload.booking_message.files)) {
    //   state.confirmFormPayload.message_files = state.confirmFormPayload.booking_message.files;
    // }

    if (isNil(state.confirmFormPayload.message)) {
      unsets.push('booking_message')
    }

    // Remove unnecessary keys
    forEach(unsets, (v) => unset(state.confirmFormPayload, v))

    //append default video_provider if type = video

    if (state.confirmFormPayload.type === 'video_skype') {
      state.confirmFormPayload.video_provider = state.confirmFormPayload.video_provider_other
    }

    if (state.confirmFormPayload.type === 'video') {
      console.log(state.confirmFormPayload)
      if (state.confirmFormPayload.video_provider === 'other') {
        state.confirmFormPayload.video_provider = state.confirmFormPayload.video_provider_other
        state.confirmFormPayload.type = 'video_skype'
      } else if (state.confirmFormPayload.video_provider === 'video_physical') {
        state.confirmFormPayload.video_provider = 'video_physical'
      } else {
        state.confirmFormPayload.video_provider = 'jitsi'
      }
    }

    // //append booking message and attachments
    // if (state.confirmFormPayload.booking_message.files.length > 0) {
    //   state.confirmFormPayload.message_files = state.confirmFormPayload.booking_message.files;
    // } else {
    //   delete state.confirmFormPayload.message_files;
    // }

    // if ((isNil(state.confirmFormPayload.booking_message.message)
    //   || state.confirmFormPayload.booking_message.message === '')
    //   && isEmpty(state.confirmFormPayload.message)) {
    //
    //   // Commenting this condition because now files are not required at all while posting message
    //   // if (state.confirmFormPayload.booking_message.files.length === 0) {
    //   delete state.confirmFormPayload.message;
    //   // }
    // }

    // setting additional field_label in confirmFormPayload
    state.confirmFormPayload.additional_field_label = state.bcfConfig.additional_field_label

    if (!isNil(state.recurring_dates)) {
      state.confirmFormPayload.recurring_dates = state.recurring_dates
    }

    const selectedTimeSlots = filter(state.form.time_slots, (item) => item.date && item.start_time && item.end_time)
    state.confirmFormPayload.time_slots = selectedTimeSlots ?? []
  },

  /**
   * Method to set the bcfConfig state.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setBcfConfig(state, payload) {
    if (!isNil(payload)) {
      state.bcfConfig = payload
    }

    const refPlaceholder = state.bcfConfig.reference_placeholder
    if (
      isNil(refPlaceholder) ||
      refPlaceholder === 'null' // Seems odd :(
    ) {
      state.bcfConfig.reference_placeholder = ''
    }
  },

  resetGuestFormPayloadErrors(state) {
    state.guestPayloadFormErrors = {
      first_name: '',
      last_name: '',
      email: '',
      phone: ''
    }
  },

  setAppointmentTemplates(state, payload) {
    state.appointmentTemplates = payload
  },

  /**
   * Set the state.availableTranslators value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of booking_fields configurations.
   * @returns {void}
   */
  setAvailableTranslators(state, payload) {
    state.availableTranslators = payload
  },
  /**
   * Set the state.availableTranslators value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of booking_fields configurations.
   * @returns {void}
   */
  setProbability(state, payload) {
    let number = payload || 0
    // let result = 90.3333333333
    let result = number * 100
    state.assignmentProbability = result.toFixed(2)
  },

  /**
   * Set the state.availableTranslatorsByPreferences value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of booking_fields configurations.
   * @returns {void}
   */
  setAvailableTranslatorsByPreferences(state, payload) {
    state.availableTranslatorsByPreferences = payload
  },

  /**
   * Set the state.confirmSuggestions value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - The collection of confirmation_fills.
   * @returns {void}
   */
  setConfirmSuggestions(state, payload) {
    let keys = [
      'booker_name',
      'staff_name',
      'email',
      'reference',
      'translator_user',
      'additional_field',
      'instructions',
      'staff_phone'
    ] // Set the keys to get the collection.

    forEach(keys, (k) => {
      // Get each collection of suggestions based on the key's given.
      // Make sure values are unique and without empty string.
      let formatted = map(payload, (item) => {
        return { value: item[k], id: item.id }
      })

      remove(formatted, (o) => o.value === '' || o.value == null)

      state.confirmSuggestions[k] = formatted
    })
  },

  /**
   * Action to delete an entry or a field inside confirmSuggestions.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload = {}] - Container for options.
   * @param {int} payload.id - ID for the target entry.
   * @param {int} [payload.field] - Specific field to delete.
   * @returns {void}
   */
  deleteFromConfirmSuggestions(state, payload = {}) {
    let { id, field } = payload
    remove(state.confirmSuggestions[field], (o) => o.id === id)
  },

  /**
   * Mutation to set the boolean values related to q_specifics.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} [payload={}] - Contains necessary values for the process.
   * @returns {void}
   */
  setTranslatorShortages(state, payload) {
    const hasShortageIn = payload.translator_shortages

    state.translatorShortages.count = hasShortageIn.count
    state.translatorShortages.specific_translator = hasShortageIn.specific_translator
    state.translatorShortages.gender = hasShortageIn.gender
    state.translatorShortages.translator_level = hasShortageIn.translator_level
    state.translatorShortages.physical = hasShortageIn.physical
    state.translatorShortages.language = hasShortageIn.language
    state.translatorShortages.country = hasShortageIn.country
  },

  /**
   * @param {Object} state
   */
  resetTranslatorShortages(state) {
    state.translatorShortages = cloneDeep(state.translatorShortages)
  },

  resetTranslatorLevels(state) {
    state.form.translator_levels = getTranslatorLevelsArray().map((tl) => tl.id)
    state.formPayload.translator_levels = getTranslatorLevelsArray().map((tl) => tl.id)
  },

  /**
   * Method to set the createdBooking state.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value to be assigned.
   *
   * @return {void}
   */
  setCreatedBooking(state, payload) {
    state.createdBooking = payload
  },

  // --------------------------------------------------
  // Resets
  // --------------------------------------------------

  /**
   * Reset state.form to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetForm(state) {
    state.form = cloneDeep(state.formFresh)
    state.availableTranslatorsByPreferences = {}
  },

  /**
   * Reset state.formPayload to pristine.\
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetFormPayload(state) {
    state.formPayload = {}
  },

  /**
   * Reset state.confirmForm to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetConfirmForm(state) {
    state.confirmForm = cloneDeep(state.confirmFormFresh)
  },

  resetMultipleBookingConfirmFormPayload(state) {
    state.multipleBookingConfirmFormPayload = []
  },

  resetMultipleBookingForm(state) {
    state.multipleBookings = [cloneDeep(state.formFresh)]
  },

  /**
   * Reset state.confirmFormPayload to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetConfirmFormPayload(state) {
    state.confirmFormPayload = {}
  },

  /**
   * Reset state.resetConfirmSuggestions to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetConfirmSuggestions(state) {
    state.confirmSuggestions = cloneDeep(state.confirmSuggestionsFresh)
  },

  /**
   * Reset state.resetBcfConfig to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetBcfConfig(state) {
    state.bcfConfig = cloneDeep(state.bcfConfigFresh)
  },

  /**
   * Reset state.availableTranslators to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetAvailableTranslators(state) {
    state.availableTranslators = 0
  },
  /**
   * Reset state.availableTranslators to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetProbability(state) {
    state.assignmentProbability = 0
  },

  /**
   * Reset state.duplicatedBooking to pristine.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @returns {void}
   */
  resetDuplicatedBooking(state) {
    state.duplicatedBooking = {}
  },

  // --------------------------------------------------
  // Misc
  // --------------------------------------------------

  /**
   * Set value for state.isLoadingValidate.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setValidateLoading(state, payload) {
    state.isLoadingValidate = payload
  },

  /**
   * Set value for state.isConfirmBooking.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setConfirmBooking(state, payload) {
    state.isConfirmBooking = payload
  },

  /**
   * Set value for state.isLoadingSubmit.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setSubmitLoading(state, payload) {
    state.isLoadingSubmit = payload
  },

  /**
   * Set value for state.isShowBookingConfirmModal
   *
   * @param {object} state - State access of the current module scope.
   * @param {Boolean} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setIsShowBookingConfirmModal(state, payload) {
    state.isShowBookingConfirmModal = payload
  },

  /**
   * Set value for state.isShowRecurringBookingConfirmModal
   *
   * @param {object} state - State access of the current module scope.
   * @param {Boolean} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setIsShowRecurringBookingConfirmModal(state, payload) {
    state.isShowRecurringBookingConfirmModal = payload
  },

  setIsShowMultipleBookingConfirmModal(state, payload) {
    state.isShowMultipleBookingConfirmModal = payload
  },

  /**
   * @param {Object} state
   * @param {string} formType
   * @returns {void}
   */
  setBookingConfirmModalUsedBy(state, formType) {
    state.bookingConfirmModalUsedBy = formType
  },

  /**
   * Method to set the state.customerUpcomingBookingList.loading value.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerUpcomingBookingListLoading(state, payload) {
    state.customerUpcomingBookingList.loading = payload
  },

  /**
   * Method to set the state.customerUpcomingBookingList.data and
   * state.customerUpcomingBookingList.pagination values.
   *
   * @param {object} state - The state property of the current Vuex module.
   * @param {object} payload - Value(s) to be assigned.
   * @returns {void}
   */
  setCustomerUpcomingBookingList(state, payload) {
    listSetter(state, payload, {
      data: 'customerUpcomingBookingList.data',
      pagination: 'customerUpcomingBookingList.pagination'
    })
  },

  /**
   * Method to set the state of customer recent bookings
   * @param state
   * @param payload
   */
  setCustomerRecentBookings(state, payload) {
    state.customerRecentBookings.data = payload.data.data.bookings
    state.customerRecentBookings.pagination = payload.data.meta.pagination
  },

  removeFormSelectedSpecificTranslators(state, ids) {
    remove(state.form.specific_translators, (x) => includes(ids, x))

    // Just to refresh reactivity
    state.form.specific_translators = [...state.form.specific_translators]
  },

  removeFormSelectedExcludedTranslators(state, ids) {
    remove(state.form.excluded_translators, (x) => includes(ids, x))

    // Just to refresh reactivity
    state.form.excluded_translators = [...state.form.excluded_translators]
  },

  removeFormSelectedTimes(state) {
    state.form.time = ''
    state.form.end_time = ''
  },
  /**
   * @param state
   * @param payload
   */
  setNewLastUpdate(state, payload) {
    state.newLastUpdate = payload[0].updated_at
  },
  /**
   *
   * @param state
   * @param payload
   */
  setBookingFilterQueryForExport(state, payload) {
    state.filterBookingQueryForExport = omit(payload, ['page', 'append', 'include', 'sort', 'fields[bookings]', 'with'])
  },

  /**
   * Method to prepare the payload before sending it tru API
   * for validating the booking.
   *
   * @param {object} state - State access of the current module scope.
   * @param {object} payload
   * @param {object} payload.user - contains user data
   * @returns {void}
   */
  setRecurringFormPayload(state, payload) {
    const source = state.form
    let dest = {}

    // Set language.
    dest.from_language_id = source.language
    dest.start_on_date = source.start_on_date
    dest.end_on_date = source.end_on_date
    dest.repeat_frequency = source.repeat_frequency
    dest.repeat_period = source.repeat_period
    dest.repeat_days = source.repeat_days
    dest.excluded_recurring_dates = source.excluded_recurring_dates
    dest.monthly_selection = source.monthly_selection
    dest.monthly_dates = source.monthly_dates

    dest.countries_required = source.countries_required ? 1 : 0
    dest.countries = source.countries

    // Set date.
    if (!isNil(source.date) && source.date !== '') {
      if (isDate(source.date)) {
        // noinspection JSValidateTypes
        dest.date = moment(source.date).local().format('YYYY-MM-DD')
      } else if (isString(source.date)) {
        dest.date = source.date
      }
    }

    // Set time.
    if (!isNil(source.time) && source.time !== '') {
      dest.time = source.time.length === 8 ? source.time.trim() : source.time.trim() + ':00'
    }

    // Set duration.
    dest.duration = source.duration

    // Set is_immmediate.
    dest.is_immediate = source.is_immediate ? 1 : 0

    // Set type.
    dest.type = source.type

    // Set gender
    dest.gender = !source.gender.length || source.gender.length === 2 ? '' : source.gender[0]

    // Set translator_levels
    dest.translator_levels = source.translator_levels

    // Set specific_translators
    if (source.specific_translators.length > 0) {
      dest.specific_translators = source.specific_translators
    }

    // set payload for convey
    if (dest.type === 'convey') {
      dest.convey = source.convey
      dest.convey_files = source.convey_files
      dest.duration = 15
    }

    // set payload for video
    if (source.type === 'video') {
      dest.video_provider = 'jitsi'
      dest.video_url = ''
    }

    // set default payload for message
    dest.booking_message = source.booking_message

    dest.is_created_by_colleague = source.is_created_by_colleague

    dest.countries = source.dialect

    dest.countries_required = source.dialect_reason

    if (dest.is_created_by_colleague) {
      dest.customer_id = source.colleague.id
      dest.created_by = payload.user.id
      dest.address = source.colleague.customer_data?.address
    } else if (source.is_created_by_admin) {
      dest.is_created_by_admin = source.is_created_by_admin
      dest.customer_id = source.customer_id
      dest.created_by = payload.user.id
      dest.assigned_translator = source.assigned_translator[0]
      dest.create_booking_in_past = source.booking_is_past
      dest.customer_object = source.customer_object
      if (source.customer_object?.customer_data?.address) {
        dest.address = source.customer_object.customer_data.address
        dest.city = source.customer_object.customer_data.city
      }
    } else {
      unset(dest, 'customer_id')
      unset(dest, 'created_by')
      dest.address = payload.user.customer_data?.address
    }
    // Set excluded_translators
    if (source.excluded_translators.length > 0) {
      dest.excluded_translators = source.excluded_translators
    }
    if (!isNil(source.excluded_towns)) {
      dest.excluded_towns = source.excluded_towns
    }
    if (!isNil(source.translator_trainings)) {
      dest.translator_trainings = source.translator_trainings
    }

    state.formPayload = dest
  },

  addRecurringSelectedDates(state, payload) {
    state.recurring_dates = payload
  },

  setMultipleConfirmFormPayload(state, payload = {}) {
    payload.data.bookings.map((booking) => {
      state.confirmFormPayload = {} // Reset the payload.

      if (isNil(payload.user)) console.error('payload.user is required.')

      // Define the key : value to be unset. Most of this came from state.confirmForm.
      let unsets = [
        'notification_confirmation_via',
        'notification_type',
        'allow_change_type',
        'end_time',
        'booking_specific_preference.has_contract_to_convert_to_phone'
      ]

      // Map formPayload and confirmForm into payload container.
      state.confirmFormPayload = booking
      state.confirmFormPayload.status_id = 1 // PENDING

      // Set notification types.
      // As of the moment, this is between email|fax
      let notif_type = state.confirmForm.notification_type
      // Instantiate the container if it's undefined.
      state.confirmFormPayload.notification_types = []
      forEach(notif_type, (nt) => {
        if (nt === 'email') {
          let emails = state.confirmForm.notification_confirmation_via.email // Collection of emails.

          forEach(emails, (email) => {
            state.confirmFormPayload.notification_types.push({
              type: 'email',
              recipient: email
            })
          })
        } else if (nt === 'fax') {
          state.confirmFormPayload.notification_types.push({
            type: 'fax',
            recipient: state.confirmForm.notification_confirmation_via['fax']
          })
        } else {
          state.confirmFormPayload.notification_types.push({
            type: 'sms',
            recipient: state.confirmForm.notification_confirmation_via['sms']
          })
        }
      })

      // Remain / Remove values according to their switches.
      forEach(['booker_name', 'staff_name', 'reference', 'instructions'], (v) => {
        if (!state.bcfConfig[v + '_enabled']) unsets.push(v)
      })

      if (state.confirmFormPayload.is_show_alternative_languages_field) {
        let altLangs = state.confirmFormPayload.alternative_languages
        if (!isNil(altLangs) && !isEmpty(altLangs)) {
          state.confirmFormPayload.alternative_languages = map(altLangs, (v) => parseInt(v))
        }
      } else {
        state.confirmFormPayload.alternative_languages = []
      }

      // Force convert the booking to type - phone if they agree on environmental purpose question.
      if (state.confirmFormPayload.booking_specific_preference.encourage_phone === true) {
        state.confirmFormPayload.type = 'phone'
      }

      // Force convert the booking to type - phone.
      if (state.confirmFormPayload.booking_specific_preference.physical === true) {
        state.confirmFormPayload.type = 'phone'
      }

      // Set phone as fallback_type if user agrees to accept it while booking a physical type.
      if (
        state.confirmFormPayload.booking_specific_preference.physical === 'accept_phone_but_prefer_physical' ||
        state.confirmFormPayload.booking_specific_preference.encourage_phone === 'accept_phone_but_prefer_physical' ||
        state.confirmForm.has_contract_to_convert_to_phone
      ) {
        state.confirmFormPayload.fallback_type = 'phone'
      }

      // Unset fallback type if it's not allowed.
      const isAllowChangeType = state.confirmFormPayload.allow_change_type
      if (!isAllowChangeType || ![PHYSICAL, VIDEO_PHYSICAL].includes(state.confirmFormPayload.type)) {
        unsets.push('fallback_type')
      }

      // //append booking message and attachments
      // if (!isNil(state.confirmFormPayload.booking_message.files) || !isEmpty(state.confirmFormPayload.booking_message.files)) {
      //   state.confirmFormPayload.message_files = state.confirmFormPayload.booking_message.files;
      // }

      if (isNil(state.confirmFormPayload.message)) {
        unsets.push('booking_message')
      }

      // Remove unnecessary keys
      forEach(unsets, (v) => unset(state.confirmFormPayload, v))

      //append default video_provider if type = video
      if (state.confirmFormPayload.type === 'video') {
        if (state.confirmFormPayload.video_provider === 'other') {
          state.confirmFormPayload.video_provider = state.confirmFormPayload.video_provider_other
          state.confirmFormPayload.type = 'video_skype'
        } else {
          state.confirmFormPayload.video_provider = 'jitsi'
        }
      }

      // //append booking message and attachments
      // if (state.confirmFormPayload.booking_message.files.length > 0) {
      //   state.confirmFormPayload.message_files = state.confirmFormPayload.booking_message.files;
      // } else {
      //   delete state.confirmFormPayload.message_files;
      // }

      // if ((isNil(state.confirmFormPayload.booking_message.message)
      //   || state.confirmFormPayload.booking_message.message === '')
      //   && isEmpty(state.confirmFormPayload.message)) {
      //
      //   // Commenting this condition because now files are not required at all while posting message
      //   // if (state.confirmFormPayload.booking_message.files.length === 0) {
      //   delete state.confirmFormPayload.message;
      //   // }
      // }

      // setting additional field_label in confirmFormPayload
      state.confirmFormPayload.additional_field_label = state.bcfConfig.additional_field_label

      state.multipleBookingConfirmFormPayload.push(state.confirmFormPayload)
    })
  },

  setMultipleBookingFormPayload(state, payload) {
    const source = payload
    let dest = {}

    // Set language.
    dest.from_language_id = source.language

    // Set date.
    if (!isNil(source.date) && source.date !== '') {
      if (isDate(source.date)) {
        // noinspection JSValidateTypes
        dest.date = moment(source.date).local().format('YYYY-MM-DD')
      } else if (isString(source.date)) {
        dest.date = source.date
      }
    }

    // Set time.
    if (!isNil(source.time) && source.time !== '') {
      dest.time = source.time.length === 8 ? source.time.trim() : source.time.trim() + ':00'
    }

    // Set duration.
    dest.duration = source.duration

    // Set is_immmediate.
    dest.is_immediate = source.is_immediate ? 1 : 0

    // Set type.
    dest.type = source.type

    // Set gender
    dest.gender = !source.gender.length || source.gender.length === 2 ? '' : source.gender[0]

    // Set translator_levels
    dest.translator_levels = source.translator_levels

    // Set specific_translators
    if (source.specific_translators.length > 0) {
      dest.specific_translators = source.specific_translators
    }
    // country
    dest.countries_required = source.countries_required ? 1 : 0
    dest.countries = source.countries

    // set payload for convey
    if (dest.type === 'convey') {
      dest.convey = source.convey
      dest.convey_files = source.convey_files
      dest.duration = 15
    }

    // set payload for video
    if (source.type === 'video') {
      dest.video_provider = 'jitsi'
      dest.video_url = ''
    }

    // set default payload for message
    dest.booking_message = source.booking_message

    dest.is_created_by_colleague = source.is_created_by_colleague

    dest.countries = source.dialect

    dest.countries_required = source.dialect_reason

    if (dest.is_created_by_colleague) {
      dest.customer_id = source.colleague.id
      dest.created_by = payload.user.id
      dest.address = source.colleague.customer_data?.address
    } else if (source.is_created_by_admin) {
      dest.is_created_by_admin = source.is_created_by_admin
      dest.customer_id = source.customer_id
      dest.created_by = payload.user.id
      dest.assigned_translator = source.assigned_translator[0]
      dest.create_booking_in_past = source.booking_is_past
      dest.customer_object = source.customer_object
      if (source.customer_object?.customer_data?.address) {
        dest.address = source.customer_object.customer_data.address
        dest.city = source.customer_object.customer_data.city
      }
    } else {
      unset(dest, 'customer_id')
      unset(dest, 'created_by')
      dest.address = payload.user.customer_data?.address
    }

    // Set excluded_translators
    if (source.excluded_translators.length > 0) {
      dest.excluded_translators = source.excluded_translators
    }

    if (!isNil(source.excluded_towns)) {
      dest.excluded_towns = source.excluded_towns
    }

    if (!isNil(source.translator_trainings)) {
      dest.translator_trainings = source.translator_trainings
    }

    state.formPayload = dest
  },

  /**
   *
   * @param state
   * @param payload
   */
  setExpenseSettings(state, payload) {
    state.expense_settings = payload
  },
  setAcceptRejectBookingLoading(state, payload) {
    state.acceptRejectBookingLoading = payload
  },
  /**
   *
   * @param state
   * @param payload
   */
  setSalarySettings(state, payload) {
    state.salary_settings = payload
  },

  /**
   * @param state
   *
   */
  setNonVocalLanguage(state) {
    state.nonVocalLanguages = ['TSS tolkning', 'Skrivtolkning', 'Dövblindtolkning', 'Teckenspråkstolkning']
  },

  setBookingDefaultLoading(state, payload) {
    state.bookingDefaultLoading = payload
  },
  /**
   * @param state
   * @param payload
   */
  setVideoOptions(state, payload) {
    state.videoOptions = VIDEO_OPTIONS.filter((option) => {
      return payload.includes(option.value)
    })
  },

  setConveyCancelFormValues(state, payload) {
    state.form.date = payload.data.date
    state.form.time = payload.data.time
    state.form.duration = payload.data.duration
    state.form.type = 'convey'
    state.form.convey = payload.data.convey
    state.form.language = payload.data.from_language_id
    state.form.message_files = payload.data.message_files
  },
  /**
   *
   * @param state
   * @param payload
   */
  setDisableConfirmBookingButton(state, payload) {
    state.disableConfirmBookingButton = payload
  },

  // customersList in Admin Booking
  setCustomersList(state, payload) {
    state.customersList = payload
  },

  resetCustomerForm(state) {
    state.customerForm = cloneDeep(state.customerFormFresh)
  },

  // departmentsList in Admin Booking
  setDepartmentsList(state, payload) {
    state.departmentsList = payload
  },

  // companyList in Admin Booking
  setCompanyList(state, payload) {
    state.companyList = payload
  },

  // customerCategoryList in Admin Booking
  seCustomerCategoryList(state, payload) {
    state.customerCategoryList = payload
  },

  // adminUserList in Admin Booking
  setAdminUserList(state, payload) {
    state.adminUserList = payload
  },

  resetDepartmentForm(state) {
    state.departmentForm = cloneDeep(state.departmentFormFresh)
  },

  /**
   *
   * @param state
   */
  setSpecificTransaltorNames(state, payload) {
    state.specificTranslatorsNames = payload
  },
  /**
   *
   * @param state
   */
  resetTimeSlots(state) {
    state.form.time_slots = [cloneDeep(state.freshTimeSlot)]
  },
  setSkipFirstConfirmationStep(state, payload) {
    state.skipFirstConfirmationStep = payload
    state.mustGoToFirstConfirmationStep = !payload
  },
  setMustGoToFirstConfirmationStep(state, payload) {
    state.mustGoToFirstConfirmationStep = payload
    state.skipFirstConfirmationStep = !payload
  },
  setPaymentSetupLoading(state, payload) {
    state.isLoadingPaymentSetup = payload
  },
  setFallbackBookingType(state, payload) {
    state.confirmFormPayload.fallback_type = payload
  }
}
