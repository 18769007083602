/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > getBookingDetails
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { reach } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import isNil from 'lodash/isNil'

/**
 * Action to fetch the booking details via its ID
 *
 * @param {object} context - The scope of the Vuex store.
 * @param {object} [payload = {}] - Container for options.
 * @param {int} payload.id - ID for the target entry.
 * @param {string} payload.signature - Special token for accessing the API.
 *
 * @return {Promise}
 */
export default function (context, payload) {
  context.commit('setCustomerNormalBookingListLoading', true)
  context.commit('textTranslation/setCustomerNormalBookingListLoading', true, { root: true })
  context.commit('textTranslation/setTranslatorNormalBookingListLoading', true, { root: true })
  context.commit('textTranslation/setCustomerHistoricBookingListLoading', true, { root: true })
  context.commit('textTranslation/setTranslatorHistoricBookingListLoading', true, { root: true })
  context.commit('setCustomerHistoricBookingListLoading', true)
  context.commit('setTranslatorNormalBookingListLoading', true)
  context.commit('setTranslatorHistoricBookingListLoading', true)
  context.commit('setTranslatorPotentialBookingListLoading', true)
  context.commit('setCustomerUpcomingBookingListLoading', true)
  // Make error loggers to make sure required values are filled in.
  if (isNil(payload.id)) {
    console.error('Booking ID should exist!')
  }

  let include = [
    'customer.department.company.municipality',
    'translator_levels',
    'countries',
    'assigned_translator',
    'notification_types',
    'specific_translators',
    'excluded_translators',
    'video_booking',
    'messages.files',
    'convey.files',
    'towns',
    'translator_trainings',
    'booking_specific_preference',
    'translator_email',
    'translator_address',
    'translator_dob',
    'text_translation.files',
    'customer.booking_field',
    'feedback',
    'excluded_towns',
    'language_pair_levels',
    'booking_time_slots.translator.translator_unavailable_times',
    'direct_salary',
    'booking_group'
  ]

  if (payload?.bookingStatus == 'planned') {
    include.push('planned_translator.translator.translator_data')
  }

  if (!isNil(payload?.additionalInclude) && Array.isArray(payload?.additionalInclude)) {
    include.push(...payload.additionalInclude)
  }

  include = include.join(',')

  let options = {
    query_params: {
      include: payload.include ?? include,
      append: payload.append
    },
    on_success(r, ctx) {
      ctx.commit('setBcfConfig', r?.data?.data?.booking?.customer?.booking_field)
    },
    on_finally(ctx) {
      ctx.commit('setCustomerNormalBookingListLoading', false)
      ctx.commit('setCustomerHistoricBookingListLoading', false)
      ctx.commit('setTranslatorNormalBookingListLoading', false)
      ctx.commit('setTranslatorHistoricBookingListLoading', false)
      ctx.commit('setTranslatorPotentialBookingListLoading', false)
      ctx.commit('setCustomerUpcomingBookingListLoading', false)
      ctx.commit('textTranslation/setCustomerNormalBookingListLoading', false, { root: true })
      ctx.commit('textTranslation/setTranslatorNormalBookingListLoading', false, { root: true })
      ctx.commit('textTranslation/setCustomerHistoricBookingListLoading', false, { root: true })
      ctx.commit('textTranslation/setTranslatorHistoricBookingListLoading', false, { root: true })
    }
  }

  // Define axios values.
  const url = `${API.BOOKINGS}/${payload.id ?? payload}`

  const method = 'GET'

  return reach(this, context, url, method, options, axiosInstance)
}
