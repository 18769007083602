<template>
  <div :class="['aws-field-wrapper', disabledUpload ? 'disabled-upload' : '']">
    <el-progress v-if="uploadProgress > 0 && uploadProgress < 100 && isShowProgressBar" :percentage="uploadProgress" />
    <el-upload
      :ref="refs"
      class="aws-upload-field"
      :class="{ 'aws-upload-field-word-count': isCountWords }"
      action=""
      :drag="drag"
      :on-remove="handleRemove"
      :auto-upload="true"
      :http-request="handleFilesUpload"
      :on-error="handleOnError"
      :before-remove="beforeRemove"
      :multiple="multiple"
      :file-list="iValue"
      :disabled="disabledUpload || (fileList.length > 0 && uploadProgress < 100)"
      :on-preview="handleOnPreview"
      :show-file-list="getFileListStatus"
      :accept="allowedTypes"
      :on-change="onChange"
      :limit="limit"
      data-cy="aws-file-input"
    >
      <slot>
        <template v-if="drag">
          <el-icon>
            <el-icon><UploadFilled /></el-icon>
          </el-icon>
          <div class="el-upload__text">
            <em data-cy="select-file-text">{{ $t(selectFileText) }}</em>
          </div>
        </template>
        <template v-else-if="$slots.upload">
          <slot name="upload" :upload-progress="uploadProgress" :files="fileList"></slot>
        </template>
        <template v-else>
          <div class="el-upload__text text-left">
            <el-button size="small" type="primary" :disabled="disabledUploadButton">
              <el-icon>
                <el-icon><UploadFilled /></el-icon>
              </el-icon>
              {{ $t('click_to_upload') }}
            </el-button>
          </div>
        </template>
      </slot>
      <template v-if="$slots.file" #file="{ file }">
        <slot name="file" :file="file"></slot>
      </template>
      <template v-else-if="isCountWords" #file="{ file }">
        <slot name="file" :file="file">
          <div
            class="file-name"
            data-cy="selected-file-name"
            :class="{ 'file-error': filesWithErrors?.[file?.id || file?.uid] }"
          >
            {{ file.name }}
          </div>
          <div class="right-section">
            <el-tooltip
              v-if="file.count_not_fetching"
              raw-content
              :visible="isShowWordFetchedNotif === file.uid && tooltips[tooltips.length - 1] === file.uid"
              effect="customized"
              :content="getWordFetchNotificationElement()"
            >
              <div class="word-count">
                <div v-if="isFieldActive(file?.id || file?.uid)" class="word-count">
                  <el-input-number
                    ref="wordCountInput"
                    v-model="file.words_count"
                    class="word-count-input"
                    :placeholder="$t('tt_words')"
                    :controls="false"
                    :min="0"
                    :class="[getBem(blockClass, 'words-field'), getBem(blockClass, 'input-field')]"
                    @mouseleave="handleMouseInteraction(false)"
                    @click="activateWordCountField(file?.id || file?.uid)"
                    @focus="activeWordCountField = file?.id || file?.uid"
                    @blur="file.words_count = file.words_count ? file.words_count : 0"
                  />
                  <span class="words" data-cy="word-count">{{ $t('tt_words') }}</span>
                </div>
                <div
                  v-else
                  class="word-count"
                  @mouseenter="handleMouseInteraction(file?.id || file?.uid)"
                  :class="{ 'file-error': filesWithErrors?.[file?.id || file?.uid] }"
                >
                  {{ $t('tt_label_add_word_count') }} ...
                </div>
              </div>
            </el-tooltip>
            <el-tooltip
              v-else-if="file?.count_complete || file?.id"
              :content="getWordConfigNotificationElement()"
              raw-content
              :visible="isShowWordConfigNotif === file.uid && tooltips[tooltips.length - 1] === file.uid"
              effect="customized"
            >
              <div class="word-count">
                <el-input-number
                  v-if="isFieldActive(file?.id || file?.uid)"
                  ref="wordCountInput"
                  v-model="file.words_count"
                  class="word-count-input"
                  :placeholder="$t('tt_words')"
                  :controls="false"
                  :min="0"
                  :class="[getBem(blockClass, 'words-field'), getBem(blockClass, 'input-field')]"
                  @mouseleave="handleMouseInteraction(false)"
                  @click="activateWordCountField(file?.id || file?.uid)"
                  @focus="activeWordCountField = file?.id || file?.uid"
                  @blur="file.words_count = file.words_count ? file.words_count : 0"
                />
                <span v-else class="words" @mouseenter="handleMouseInteraction(file?.id || file?.uid)">{{
                  file?.id ? file.pivot?.words_count : file.words_count
                }}</span>
                <span class="words" data-cy="word-count">{{ $t('tt_words') }}</span>
              </div>
            </el-tooltip>
            <div v-else class="word-count">{{ $t('tt_counting_words') }} ...</div>
            <div class="remove-file-icon">
              <el-icon :size="20">
                <Close @click="handleRemove(file)" />
              </el-icon>
            </div>
          </div>
        </slot>
      </template>
    </el-upload>
  </div>
</template>

<script>
import { download, storeFile, countFileWords, checkFileCompability, storeFileInChunks } from '@/modules/helpers/file'
import { DOCUMENTS } from '@/modules/constants/fileTypes'
import isEmpty from 'lodash/isEmpty'
import { mapGetters, mapMutations } from 'vuex'
import isObject from 'lodash/isObject'

// import { UploadFilled } from '@element-plus/icons-vue'

export default {
  // components: {
  //   UploadFilled
  // },
  props: {
    modelValue: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    refs: {
      type: String,
      default: 'upload'
    },
    drag: {
      type: Boolean,
      default: false
    },
    isShowFileList: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 5
    },
    buttonText: {
      type: String,
      default: 'select_files'
    },
    customeClass: {
      type: String,
      default: ''
    },
    selectFileText: {
      type: String,
      default: 'text_translation_select_files'
    },

    disabledUpload: {
      type: Boolean,
      default: false
    },
    disabledUploadButton: {
      type: Boolean,
      default: false
    },
    allowedTypes: {
      type: String,
      default: ''
    },
    isCountWords: {
      type: Boolean,
      default: false
    },
    isUploadCountWords: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    onChange: {
      type: Function,
      default: () => null
    },
    onFileUploaded: {
      type: Function,
      default: () => null
    },
    isShowProgressBar: {
      type: Boolean,
      default: () => true
    },
    showFileList: {
      type: Boolean,
      default: () => true
    },
    isUploadInChunks: {
      type: Boolean,
      default: false
    },
    detectLanguage: {
      type: Boolean,
      default: false
    },
    filesWithErrors: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:modelValue', 'deleteFile', 'progress', 'error'],
  data() {
    return {
      blockClass: 'text-translation-booking-form',
      fileList: [],
      uploadProgress: 0,
      delayFileList: false,
      wordsCountThreshold: 5,
      activeWordCountField: null,
      activeWordCountFields: [],
      isShowWordFetchedNotif: false,
      isShowWordConfigNotif: false,
      tooltips: []
    }
  },
  computed: {
    ...mapGetters('auth', ['isUserAuthenticated', 'user']),

    /**
     * Interface for the v-model of this component.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        // If word count is true
        if (this.$props.isCountWords) {
          // Add the listener to the tooltip close button
          this.addWordConfigNotificationListener()
          this.addWordFetchNotificationistener()
        }

        this.$emit('update:modelValue', v)
      }
    },
    getFileListStatus() {
      if (this.showFileList) {
        return this.delayFileList
      }

      return false
    }
  },
  watch: {
    iValue: {
      handler() {
        this.delayFileList = false
        setTimeout(() => {
          // fix uploaded files fly by animation issue
          this.delayFileList = this.isShowFileList
        }, 50)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('auth', ['setIsShowTextTranslationLoginModal']),

    beforeRemove(uploadFile) {
      if (this.$props.isCountWords) {
        // prevent removing file while editing word count
        if (uploadFile?.uid || uploadFile?.id === this.activeWordCountField) {
          return false
        }
      }

      return true
    },
    /**
     * helpers methods to handle removing selected file
     */
    handleRemove(e) {
      this.iValue = this.iValue.filter((file) => {
        return file.uid !== e.uid
      })

      this.$emit('deleteFile', e)
    },
    handleOnError() {},

    handleOnPreview(e) {
      if (!isEmpty(e.path)) {
        download(e)
      } else {
        download({ path: e.key, display_name: e.name })
      }
    },

    isFileTypeEligibleForWordCount(fileName) {
      const extension = '.' + fileName.split('.').pop()
      return DOCUMENTS.includes(extension.toLocaleLowerCase())
    },
    isFileTypeEligibleForIncompabilityCheck(fileName) {
      const extension = '.' + fileName.split('.').pop()
      return ['.pdf', '.docx', '.doc'].includes(extension.toLocaleLowerCase())
    },

    /**
     * helpers methods to handle upload selected file to temp aws s3 storage
     */
    handleFilesUpload(e) {
      console.log('in files upload')
      if (!this.isUserAuthenticated) {
        this.setIsShowTextTranslationLoginModal(true)
        this.$refs.upload.clearFiles()
        return false
      }
      this.$emit('progress', 1)
      let options = {
        progress: (progress) => {
          this.uploadProgress = Math.round(progress * 100)
          this.$emit('progress', this.uploadProgress)
        },
        count_words: this.isCountWords || this.isUploadCountWords,
        chunk_size: 5 * 1024 * 1024, // minimum chunk size should be 5mib
        content_type: e.file.type
      }

      if (this.isUploadInChunks && e.file.size > 0) {
        this.uploadFileInChunks(e.file, options)
      } else {
        storeFile(this, e.file, options)
          .then((r) => {
            let data = r.data
            data.uid = e.file.uid
            data.file_name = e.file.name
            data.type = e.file.type
            data.extension = e.file.type
            data.name = e.file.name
            if ((this.isCountWords || this.isUploadCountWords) && this.isFileTypeEligibleForWordCount(data.name)) {
              this.countWords(data)
            } else {
              this.iValue = [...this.modelValue, data]
              this.uploadProgress = 0

              if (this.onFileUploaded) {
                this.$emit('onFileUploaded', data)
              }
            }
          })
          .catch((error) => {
            let errorMessage = error?.response?.data?.message ?? error
            if (isObject(errorMessage)) {
              errorMessage = JSON.stringify(errorMessage)
            }
            const errorDetails = {
              error: errorMessage,
              file_name: e.file.name,
              file_type: e.file.type,
              file_size: e.file.size,
              browser_info: navigator.userAgent,
              user_id: this.user?.id,
              user_name: this.user?.name,
              user_email: this.user?.email
            }
            console.error('error details', errorDetails)
            this.$emit('error', errorDetails)
          })
      }
    },
    uploadFileInChunks(file, options) {
      storeFileInChunks(this, file, options)
        .then((response) => {
          let data = {
            ...response,
            uid: file.uid,
            file_name: file.name,
            type: file.type,
            extension: file.type,
            name: file.name
          }
          if (
            (this.isCountWords || this.isUploadCountWords) &&
            this.isFileTypeEligibleForIncompabilityCheck(data.name)
          ) {
            this.checkIsFileCompatible(data)
          } else if ((this.isCountWords || this.isUploadCountWords) && this.isFileTypeEligibleForWordCount(data.name)) {
            this.countWords(data)
          } else {
            this.iValue = [...this.modelValue, data]
            this.uploadProgress = 0

            if (this.onFileUploaded) {
              this.$emit('onFileUploaded', data)
            }
          }
        })
        .catch((error) => {
          console.log('error', error)
          let errorMessage = error?.response?.data?.message ?? error
          if (isObject(errorMessage)) {
            errorMessage = JSON.stringify(errorMessage)
          }
          const errorDetails = {
            error: errorMessage,
            file_name: file.name,
            file_type: file.type,
            file_size: file.size,
            browser_info: navigator.userAgent,
            user_id: this.user?.id,
            user_name: this.user?.name,
            user_email: this.user?.email
          }
          console.error('error details', errorDetails)
          this.$emit('error', errorDetails)
        })
    },
    /**
     * Count words in the file
     * @param {*} data
     */
    countWords(data) {
      const payload = {
        file_key: data.key,
        file_name: data.file_name
      }

      if (this.detectLanguage) {
        payload.detect_language = true
        payload.customer_id = this.user.id
      }

      countFileWords(payload)
        .then((response) => {
          const wordsCount = response?.countedFiles[0]?.wordsNumber
          data = {
            ...data,
            words_count: wordsCount > this.wordsCountThreshold ? wordsCount : 0,
            count_not_fetching: wordsCount > this.wordsCountThreshold ? false : true,
            count_complete: true
          }

          if (this.$props.isCountWords) {
            // Show notification if word count is not fetched
            if (data.count_not_fetching && !this.isShowWordFetchedNotif) {
              // attach listener to the tooltip close button
              this.addWordFetchNotificationistener()
              this.isShowWordFetchedNotif = data.uid

              // add tooltip to the list
              this.tooltips = [data.uid, ...this.tooltips]
            }

            // Show notification if word count is not correct or user wants to change it
            else if (!this.isShowWordConfigNotif) {
              // attach listener to the tooltip close button
              this.addWordConfigNotificationListener()
              this.isShowWordConfigNotif = data.uid

              // add tooltip to the list
              this.tooltips = [data.uid, ...this.tooltips]
            }
          }
        })
        .catch((error) => {
          console.error('Error while counting words', error)
          if (this.$props.isCountWords) {
            data = {
              ...data,
              words_count: 0,
              count_not_fetching: true,
              count_complete: true
            }
            this.addWordConfigNotificationListener()
            this.isShowWordFetchedNotif = data.uid

            // add tooltip to the list
            this.tooltips = [data.uid, ...this.tooltips]
          }
        })
        .finally(() => {
          this.iValue = [...this.modelValue, data]
          this.uploadProgress = 0
          if (!data.words_count) {
            data.words_count = 0
          }
          if (this.onFileUploaded) {
            this.$emit('onFileUploaded', data)
          }
        })
    },
    async checkIsFileCompatible(data) {
      try {
        const payload = {
          path: data.key,
          name: data.file_name?.toLocaleLowerCase(),
          type: data.type
        }

        const response = await checkFileCompability(payload)

        if (response?.status === 1) {
          data['is_incompatible'] = response.data?.is_incompatible //nullable when not pdf/docx
          data['incompatible_reason'] = response.data?.incompatible_reason //nullable when not pdf/docx
          data['incompatible_via'] = response.data?.incompatible_via //nullable when not pdf/docx

          // Show notification if word count is not fetched
          if (!this.isShowWordFetchedNotif && response.data?.is_incompatible) {
            // attach listener to the tooltip close button
            this.addWordFetchNotificationistener()
            this.isShowWordFetchedNotif = data.uid

            // add tooltip to the list
            this.tooltips = [data.uid, ...this.tooltips]

            data['count_not_fetching'] = true

            this.iValue = [...this.modelValue, data]
            this.uploadProgress = 0
            data.words_count = 0

            if (this.onFileUploaded) {
              this.$emit('onFileUploaded', data)
            }
          } else {
            this.countWords(data)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    // Method to handle mouse interaction on word count field to show input field
    handleMouseInteraction(uid) {
      this.activeWordCountField = uid
    },
    // Method to activate word count field for input
    activateWordCountField(uid) {
      if (!this.activeWordCountFields.includes(uid)) {
        this.activeWordCountFields = [...this.activeWordCountFields, uid]
      }
    },
    // Method to check if word count field is active
    isFieldActive(uid) {
      return this.activeWordCountFields.includes(uid) || this.activeWordCountField === uid
    },
    // Method to close word unable to fetch word count notification
    closeWordFetchNotification() {
      if (this.isShowWordFetchedNotif) {
        this.isShowWordFetchedNotif = true

        // remove the last tooltip from the list
        this.tooltips.pop()

        this.$nextTick(() => {
          // if there are more tooltips, add listener to the next tooltip
          if (this.tooltips.length > 0) {
            this.addWordConfigNotificationListener()
          }
        })
        this.removeWordFetchNotificationListener()
      }
    },
    // Method to close word count configuration notification
    closeWordConfigNotification() {
      if (this.isShowWordConfigNotif) {
        this.isShowWordConfigNotif = true

        // remove the last tooltip from the list
        this.tooltips.pop()

        this.$nextTick(() => {
          // if there are more tooltips, add listener to the next tooltip
          if (this.tooltips.length > 0) {
            this.addWordFetchNotificationistener()
          }
        })
        this.removeWordConfigNotificationListener()
      }
    },
    addWordFetchNotificationistener() {
      // Wait for the next tick to ensure the DOM is updated
      this.$nextTick(() => {
        setTimeout(() => {
          const closeWordFetchNotification = document.getElementsByClassName('fetch-close-btn')
          if (closeWordFetchNotification.length) {
            closeWordFetchNotification.forEach((element) => {
              element.addEventListener('click', this.closeWordFetchNotification)
            })
          }
        }, 1000)
      })
    },
    removeWordFetchNotificationListener() {
      const closeWordFetchNotification = document.getElementsByClassName('fetch-close-btn')
      if (closeWordFetchNotification.length) {
        closeWordFetchNotification.forEach((element) => {
          element.addEventListener('click', this.closeWordFetchNotification)
        })
      }
    },
    addWordConfigNotificationListener() {
      // Wait for the next tick to ensure the DOM is updated
      this.$nextTick(() => {
        setTimeout(() => {
          const closeWordConfigNotification = document.getElementsByClassName('config-close-btn')
          if (closeWordConfigNotification.length) {
            closeWordConfigNotification.forEach((element) => {
              element.addEventListener('click', this.closeWordConfigNotification)
            })
          }
        }, 1000)
      })
    },
    removeWordConfigNotificationListener() {
      const closeWordConfigNotification = document.getElementsByClassName('config-close-btn')
      if (closeWordConfigNotification.length) {
        closeWordConfigNotification.forEach((element) => {
          element.removeEventListener('click', this.closeWordConfigNotification)
        })
      }
    },
    getWordConfigNotificationElement() {
      return (
        `<div>
                <button class='cross-btn config-close-btn'>×</button>
                <div class='notification-header'>
                  <h4>` +
        this.$t('tt_word_count_not_corect') +
        `</h4>
                </div>
                <div class='notification-body'>
                  <p>` +
        this.$t('tt_change_word_count_desc') +
        `</p>
                </div>
                <div class='notification-footer'>
                  <button  class='popper-button config-close-btn'>` +
        this.$t('tt_got_it') +
        `</button>
                </div>
              </div>`
      )
    },
    getWordFetchNotificationElement() {
      return (
        `<div>
          <button class='cross-btn fetch-close-btn'>×</button>
          <div class='notification-header'>
            <h4>` +
        this.$t('tt_word_count_not_fetching') +
        `</h4>
          </div>
          <div class='notification-body'>
            <p>` +
        this.$t('tt_change_word_count_desc') +
        `</p>
          </div>
          <div class='notification-footer'>
            <button class='popper-button fetch-close-btn'>` +
        this.$t('tt_got_it') +
        `</button>
          </div>
        </div>`
      )
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.aws-upload-field {
  width: 100%;

  .el-upload {
    width: 100% !important;
    margin: 0 0 10px 0;
  }

  .el-upload-dragger {
    width: 100% !important;

    .el-button {
      padding: 12px 20px !important;
      height: unset !important;
      width: unset;
    }
  }

  .el-upload-list__item {
    display: flex;
    padding: 12px 16px;
  }
}

.aws-upload-field-word-count {
  .el-upload-list__item {
    display: flex;
    padding: 5px 16px;

    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--mono-lighter, #f6f6f6);
    width: 100%;
    min-height: 52px;

    .right-section {
      display: flex;
      margin-left: auto;
      align-items: center;
      justify-content: flex-end;
    }

    .file-name {
      display: flex;
      align-items: center;
      width: 70%;
    }

    .file-error {
      color: $app-danger-color;
    }

    .word-count {
      display: flex;
      align-self: center;
      justify-content: flex-end;
      text-align: end;

      .word-count-input {
        display: flex;
        align-self: center;
        justify-content: flex-end;
        width: 27%;
      }
    }

    .word-count-input {
      display: flex;
      align-self: center;
      justify-content: flex-end;
      width: 40%;
    }

    .words {
      display: flex;
      align-items: center;
      margin-left: 5px;
    }

    .remove-file-icon {
      display: flex;
      align-items: center;
      text-align: end;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.el-button {
  padding: 16px 30px;

  &.el-button--primary {
    &.is-disabled {
      background-color: var(--app-primary-color);
    }
  }
}
.disabled-upload {
  .el-upload-dragger {
    background-color: #f5f7fa !important;

    .el-upload__text em {
      color: #c0c4cc !important;
    }
  }
}

.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 12px;
  padding-top: 16px !important;
  background: #de5d83;
  color: #ffff;
  font-family: 'Proxima Nova';
  border-radius: 8px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  /* z-index: 1000 !important; */

  .popper-button {
    background-color: #ffff;
    border: 0px;
    border-radius: 8px;
    max-height: 24px;
  }

  .cross-btn {
    font-weight: 500;
    background-color: transparent;
    color: #fff;
    font-size: 24px;
    border: 0px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}

.el-popper.is-customized .el-popper__arrow::before {
  background: #de5d83;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-header h4 {
  margin: 0;
  font-size: 16px;
  font-family: 'Proxima Nova';
}

.notification-body p {
  margin: 10px 0;
  font-size: 14px;
  font-family: 'Proxima Nova';
}

.notification-footer {
  text-align: right;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
}

button:focus {
  outline: none;
}
</style>
