<template>
  <focus-trap v-model="showModal" :initial-focus="() => $refs.init_focus">
    <el-dialog
      v-model="showModal"
      :title="$t('accept_booking')"
      :close-on-click-modal="false"
      class="app-modal app-modal-secondary"
      :class="blockClass"
      :append-to-body="true"
      width="35%"
      :before-close="handleModalClose"
    >
      <a ref="init_focus" style="font-size: 1px; display: flex" tabindex="-1" href="#">.</a>

      <div v-loading="loading" class="modal-wrapper">
        <p>{{ $t('booking_details_model_description_for_time_slots') }}</p>
        <div class="modal-wrapper-radio">
          <el-radio-group v-model="selectedTimeSlot">
            <el-radio
              v-for="(slot, index) in bookingTimeSlots"
              :key="index"
              :value="`${slot.date} ${slot.start_time}`"
              :label="`${slot.date} ${slot.start_time}`"
              :disabled="slot.reason"
              :class="slot.reason ? 'modal-wrapper-radio-with-raison' : ''"
            >
              <span>
                {{ slot.date }} {{ formatTime(`${slot.date} ${slot.start_time}`) }} -
                {{ formatTime(`${slot.date} ${slot.end_time}`) }}
              </span>
              <br />
              <span v-if="slot.reason" class="modal-wrapper-radio-reason">
                {{ $t(`${slot.reason}_reason`, { booking: slot.conflict_booking }) }}
              </span>
            </el-radio>
          </el-radio-group>
        </div>

        <div class="action-buttons">
          <el-button class="app-button-default" @click="handleModalClose">
            {{ $t('btn_close') }}
          </el-button>
          <el-button class="app-button-success" :disabled="!selectedTimeSlot" @click="handleAcceptBooking">
            {{ isReviewAndAccept ? $t('review_and_accept') : $t('accept') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </focus-trap>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { each, isEmpty, map, toArray } from 'lodash'
import { acceptBookingCommon } from '@/modules/helpers/booking'
import { formatTime } from '@/modules/helpers/common'
import EventBus from '@/modules/helpers/eventBus.js'

export default {
  /*
  |--------------------------------------------------------------------------
  | Component > data
  |--------------------------------------------------------------------------
  */
  data() {
    return {
      blockClass: 'booking-accept-suggested-times-modal',
      loading: false,
      showModal: false,
      booking: {},
      selectedTimeSlot: null,
      isReviewAndAccept: false
    }
  },
  /*
  |--------------------------------------------------------------------------
  | Component > computed
  |--------------------------------------------------------------------------
  */
  computed: {
    ...mapGetters('booking', ['languageOpts']),
    ...mapGetters('auth', ['user', 'isUserTranslator']),

    bookingTimeSlots() {
      if (isEmpty(this.booking)) {
        return
      }

      return (
        toArray(
          this.booking?.acceptable_booking_dates.filter(
            (item) =>
              !item.hasOwnProperty('translator_id') ||
              isEmpty(item.translator_id) ||
              item.translator_id === this.user.id
          )
        ) ?? []
      )
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Component > methods
  |--------------------------------------------------------------------------
  */
  methods: {
    ...mapActions('booking', ['getBookingDetails']),

    formatTime,
    acceptBookingCommon,

    open(payload) {
      let bookingId = payload?.booking?.id
      this.showModal = true
      this.loading = true
      this.isReviewAndAccept = payload?.isReviewAndAccept ?? false

      this.getBookingDetails({ id: bookingId, include: '', append: 'acceptable_booking_dates' })
        .then((r) => {
          this.booking = r.data.data.booking ?? {}
        })
        .finally(() => (this.loading = false))
    },

    headerCellClassName({ columnIndex }) {
      return columnIndex === 0 ? 'disabled-cell' : ''
    },

    rowClassName({ row, rowIndex }) {
      let classes = ''

      if (row.can_accept === false) {
        classes += 'disabled '
      }

      if (rowIndex === 0) {
        classes += 'is-default'
      }

      return classes
    },

    handleSelectionChange(rows) {
      let lastRow = rows
      if (rows.length > 1) {
        lastRow = [rows.pop()]
        each(rows, (row) => this.$refs['time-slots-table'].toggleRowSelection(row))
      }
      this.selectedTimeSlot = map(lastRow, (row) => `${row.date} ${row.start_time}`).pop()
    },

    handleModalClose() {
      this.showModal = false
      this.loading = false
    },

    handleAcceptBooking() {
      this.loading = true
      let payload = {
        booking: this.booking,
        translator_id: this.user.id,
        time_slot: this.selectedTimeSlot,
        isReviewAndAccept: this.isReviewAndAccept
      }
      if (this.isReviewAndAccept) {
        EventBus.emit('update-accept-form-payload', payload)
        this.loading = false
        this.isReviewAndAccept = false
        this.showModal = false
        return
      }

      this.acceptBookingCommon(payload)
        .then(() => {
          this.handleModalClose()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.booking-accept-suggested-times-modal {
  .modal-wrapper,
  p,
  b,
  div {
    word-break: break-word !important;
  }

  .modal-wrapper {
    p {
      margin: 0 7%;
    }

    .disabled-cell {
      pointer-events: none;
    }

    .is-default {
      color: $app-secondary-color;
      font-weight: 600;
    }

    table {
      tbody {
        tr {
          &:hover {
            td {
              background-color: transparent !important;
              cursor: default !important;
            }
          }
        }
      }
    }

    .disabled {
      background-color: $app-lightest-gray;
      color: $app-lighter-gray;
      font-weight: normal;

      .el-checkbox {
        cursor: not-allowed;
        pointer-events: none;

        &__inner {
          background-color: $app-lightest-gray;
          border-color: $app-lightest-gray;
        }
      }
    }

    &-radio {
      text-align: center;
      width: 100%;
      white-space: nowrap;

      .el-radio-group {
        margin-top: 10px;
        width: 75%;
        text-align: start;

        .el-radio {
          display: flex;
          flex-direction: row;
          padding: 10px 10px;
          margin-top: 5px;
          gap: 10px;
          width: 85%;
          height: 30px;
          background-color: #f5f8f7;
          border-radius: 3px;
          font-family: 'Proxima Nova';
          text-align: start;
          color: #555555;
          /* Label/16 Bold */
          font-size: 16px;
          font-weight: 600;
          line-height: 125%;
        }
      }

      &-reason {
        white-space: normal;
        color: #ff0000;
        text-align: left;
      }

      &-with-raison {
        height: 50px !important;
      }
    }
  }

  .action-buttons {
    text-align: center;
    margin-top: 50px;
  }

  .el-dialog {
    text-align: center !important;

    &__header {
      margin-left: 5%;
      margin-right: 5%;
      color: #2c2c2c !important;
      border-bottom: 1px solid #eee;
      background-color: #ffffff !important;
    }

    &__title {
      color: #2c2c2c !important;
      text-align: center;
    }
  }
}
</style>
