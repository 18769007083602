/*
|--------------------------------------------------------------------------
| Store > Booking > > Text Translation State
|--------------------------------------------------------------------------
|
| This file is where the Module's state properties are registered.
|
| Feel free to edit this file if you have state properties you want
| to include.
|
*/

import cloneDeep from 'lodash/cloneDeep'
import { getTranslatorLevelsArray } from '@/modules/constants/translatorLevel'

let formFresh = {
  is_asking_price: 0,
  file: [],
  from_language_id: '',
  to_language_id: [],
  to_languages: [],
  descriptions: '',
  date: '',
  time: '',
  is_express: 0,
  deliver_with: 'dt_web',
  is_stamped: 0,
  is_confidential: 0,
  phone: '',
  email: ''
}

let bookingFormFresh = {
  is_asking_price: 0,
  from_language_id: '',
  to_language_id: [],
  to_languages: [],
  descriptions: '',
  date: '',
  time: '',
  is_express: 0,
  deliver_with: 'dt_web',
  is_stamped: 0,
  is_confidential: 0,
  phone: '',
  reference_email: '',
  is_immediate: 0,
  duration: '0',
  type: 'text_translation',
  translator_levels: getTranslatorLevelsArray().map((tl) => tl.id),
  specific_translators: [],
  text_translation_files: [],
  text_translation_document_files: [],
  text_translation_image_files: [],
  text_translation_audio_files: [],
  text_translation_video_files: [],
  additional_text: '',
  language_pair_levels: ['certified', 'non-certified'],
  text_translation_type: 'document',
  translate_by: 'by_computer',
  is_validate_by_human: true,
  preferred_output_format: 'pdf',

  excluded_translators: [],
  excluded_towns: [],
  translator_trainings: [],
  gender: ['male', 'female'],
  customer_id: '',
  created_by: 0,
  is_created_by_admin: false,
  search_customer_by: 'id',
  assigned_translator: [],
  dialect: [],
  booking_is_past: false,
  new_customer: false,
  dialect_reason: false,
  customer_object: null
}

export let aiBookingFormFresh = {
  is_asking_price: 0,
  from_language_id: '',
  to_language_id: [],
  to_languages: [],
  descriptions: '',
  date: '',
  time: '',
  is_express: 0,
  deliver_with: 'dt_web',
  is_stamped: 0,
  is_confidential: 0,
  phone: '',
  reference_email: '',
  is_immediate: 0,
  duration: '0',
  type: 'text_translation',
  translator_levels: getTranslatorLevelsArray().map((tl) => tl.id),
  specific_translators: [],
  text_translation_files: [],
  text_translation_document_files: [],
  text_translation_image_files: [],
  text_translation_audio_files: [],
  text_translation_video_files: [],
  additional_text: '',
  language_pair_levels: ['certified', 'non-certified'],
  text_translation_type: 'ai',
  translate_by: 'by_computer',
  is_validate_by_human: true,
  preferred_output_format: 'pdf',

  excluded_translators: [],
  excluded_towns: [],
  translator_trainings: [],
  gender: ['male', 'female'],
  customer_id: '',
  created_by: 0,
  is_created_by_admin: false,
  search_customer_by: 'id',
  assigned_translator: [],
  dialect: [],
  booking_is_past: false,
  new_customer: false,
  dialect_reason: false,
  customer_object: null
}

let bcfConfigFresh = {
  booker_name_enabled: true,
  booker_name_required: false,
  staff_name_enabled: true,
  staff_name_required: false,
  reference_enabled: true,
  reference_placeholder: '',
  reference_label: '',
  reference_required: false,
  instructions_enabled: true,
  instructions_required: false,
  alt_language_enabled: true,
  alt_language_required: false,
  // Additional field
  additional_field_enabled: false,
  additional_field_required: false,
  additional_field_placeholder: '',
  additional_field_label: '',
  staff_phone_enabled: false,
  staff_phone_required: false
}

// const translatorShortagesFresh = {
//   count: false,
//   specific_translator: false,
//   gender: false,
//   translator_level: false,
//   physical: false
// };

let confirmFormFresh = {
  booker_name: '',
  staff_name: '',
  reference: '',
  additional_field: '',
  address: '',
  city: '',
  instructions: '',
  notification_type: ['email'], // Varies between : email | fax | sms
  notification_confirmation_via: {
    email: [],
    fax: '',
    sms: ''
  },
  alternative_languages: [],
  allow_change_type: false,
  fallback_type: 'phone',

  // Questions when there are specified booking property requested.
  q_specifics: {
    // Boolean Values
    has_shortage_translator: false,
    has_shortage_gender: false,
    has_shortage_translator_level: false,
    has_shortage_physical: false,

    // Boolean value but nulled by default
    can_accept_another_translator: null,

    // The 3 below are all boolean formats, just nulled as default value.
    translator: null,
    gender: null,
    translator_level: null,

    // Meanwhile for physical, it will be string format and varies
    // between the following values:
    // "convert_to_phone"
    // "wait_for_a_certain_time_then_convert_to_phone",
    // "strict_max_travel_time",
    // "consider_reimburse",
    physical: 'convert_to_phone'
  },
  staff_phone: ''
}
let confirmSuggestionsFresh = {
  email: [],
  reference: [],
  booker_name: [],
  staff_name: [],
  translator_user: []
}
export default () => {
  return {
    form: cloneDeep(formFresh),
    bookingForm: cloneDeep(bookingFormFresh),
    bookingFormFresh: cloneDeep(bookingFormFresh),
    aiBookingFormFresh: cloneDeep(aiBookingFormFresh),
    formFresh: cloneDeep(formFresh),
    formPayload: {},
    loading: false,
    submitError: false,
    item: {},
    list: {
      data: [],
      pagination: {},
      loading: true
    },

    // Container for Booking Confirm For Data.
    confirmForm: cloneDeep(confirmFormFresh),
    confirmFormFresh: cloneDeep(confirmFormFresh),
    confirmFormPayload: {},
    confirmFormCache: {},
    isConfirmFormHalted: false,
    isShowBookingConfirmModal: false,
    isShowManualPriceQuote: false,

    isLoadingValidate: false,
    isLoadingSubmit: false,

    // Container for the newly created bookings.
    // Note: Only for the purpose of displaying it to other pages.
    createdBooking: {},
    createdBookings: [],

    // Container for duplicated bookings.
    // Note: Only used for the duplicated booking to be easily accessible.
    duplicatedBooking: {},

    // Container for confirm fields suggestions
    confirmSuggestions: cloneDeep(confirmSuggestionsFresh),
    confirmSuggestionsFresh: cloneDeep(confirmSuggestionsFresh),

    // Container for the customer's Booking Confirm Fields Config values.
    bcfConfig: cloneDeep(bcfConfigFresh),
    bcfConfigFresh: cloneDeep(bcfConfigFresh),

    availableTranslators: 0,

    // States Customer for Normal Booking ---
    customerNormalBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States Translator for Normal Booking ---
    translatorNormalBookingList: {
      data: [],
      pagination: {},
      loading: true
    },
    customerHistoricBookingList: {
      data: [],
      pagination: {},
      loading: true
    },

    // States Translator for Historic Booking ---
    translatorHistoricBookingList: {
      data: [],
      pagination: {},
      loading: true
    },
    duplicateFields: {},

    // State Awaiting Quotes Text Translations
    awaitingQuotes: [],

    costEstimationPayload: {},
    costEstimations: [],

    // State for Booking Attempt
    bookingAttemptPayload: {},

    // test url for text translation registration
    textTranslationTestUrl: null,
    manualSubmissionTillDate: null,
    manualSubmissionEnabled: false,
    isValidTextTranslator: null,
    customerMarketings: [],
    textTranslationSuggestedDeadlines: [],
    textTranslationExpressHours: {}
  }
}
