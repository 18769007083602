<template>
  <el-select
    :id="getId(blockClass)"
    :ref="iClass"
    v-model="iValue"
    :class="getBem(blockClass)"
    class="app-field"
    popper-class="dropdown-with-footer"
    default-first-option
    filterable
    allow-create
    :placeholder="placeholder"
    :disabled="iDisabled"
    data-cy="booking-time-field"
    @change="sanitizeInput"
    @keydown.tab.native.capture="handleKeyTab"
    @click.native="handleNativeClick"
    @focus="handleFocus"
    @keyup="handleEnterKey"
  >
    <el-option
      v-for="o in timeOpts"
      :key="o.value"
      :value="o.value"
      :label="o.value"
      :data-cy="`booking-time-option-${o.value}`"
    >
      <div v-if="o.showDetails" :style="{ display: 'flex', flexDirection: 'row', alignItems: 'center' }">
        <div
          :style="{
            content: '',
            display: 'inline-block',
            margin: '0 0px 0 -5px',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            background: '#2FBD8E',
            bottom: '1px',
            transform: 'translateX(-50%)'
          }"
        />
        <div
          :style="{
            // background: o.background,
            // padding: '0 5px',
            margin: '0 5px 0 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px'
            // color: 'o.color'
          }"
        >
          {{ o.label }}
        </div>
        <div :style="{ fontSize: '12px', color: o.background }">
          {{ o.text }}
        </div>
      </div>
    </el-option>

    <!--  -->
    <div v-html="footerWithDescription"></div>
  </el-select>
</template>

<script>
import moment from 'moment-timezone'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'

export default {
  /*
    |--------------------------------------------------------------------------
    | Component > props
    |--------------------------------------------------------------------------
    */
  props: {
    modelValue: {
      type: String,
      required: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    className: {
      type: String,
      default: ''
    },
    transalatorAvailableSlots: {
      type: Array,
      default: () => []
    },
    transalatorEndTimeSlots: {
      type: Array,
      default: () => [],
      required: false
    },
    selectdefaulstarttime: {
      type: Boolean,
      default: false
    },
    assignmentProbability: {
      type: Object,
      default: () => {},
      required: false
    }
  },

  emits: ['update:modelValue', 'input', 'change', 'native-click'],

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockClass: 'booking-time-field',
      iTimeOpts: [],
      isTabbed: false,
      isBlurred: false,
      lastValue: ''
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * Interface for this.value.
     */
    iValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    },

    /**
     * Interface for this.value.
     */
    iDisabled: {
      get() {
        return this.disabled
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    /**
     * Time Options.
     * @return {array}
     */
    timeOpts() {
      let start = moment().startOf('day')
      let end = moment().endOf('day')

      let timeSlots = []

      while (start.isBefore(end)) {
        let slot = start.format('HH:mm')

        timeSlots.push({
          time: start.clone(),
          value: slot,
          label: slot,
          background: '',
          color: '#000000',
          text: '',
          showDetails: false
        })

        start.add(30, 'minutes')
      }

      if (!this.assignmentProbability) {
        return timeSlots
      }

      let transalatorSlots = this.transalatorAvailableSlots?.length
        ? this.transalatorAvailableSlots
        : this.transalatorEndTimeSlots

      let isStartField = this.transalatorAvailableSlots?.length > 0
      let probabilityLength = Object.keys(this.assignmentProbability).length !== 0
      if (transalatorSlots?.length && probabilityLength) {
        let availableSlots = transalatorSlots.map((availableSlot) => {
          return {
            startTime: moment(availableSlot.start_time, 'HH:mm:ss'),
            endTime: moment(availableSlot.end_time, 'HH:mm:ss')
          }
        })

        timeSlots.map((timeSlot) => {
          let availableSlot = availableSlots
            .filter((availableSlot) => {
              return timeSlot.time.isBetween(
                availableSlot.startTime,
                availableSlot.endTime,
                undefined,
                isStartField ? '[)' : '(]'
              )
            })
            .sort((a, b) => {
              return b.endTime - a.endTime
            })[0]

          if (availableSlot) {
            let duration = null

            if (isStartField) {
              duration = moment.duration(availableSlot.endTime.diff(timeSlot.time))
            } else {
              duration = moment.duration(timeSlot.time.diff(availableSlot.startTime))
            }

            let hours = Math.floor(duration.asHours())
            let minutes = duration.minutes()

            let available = this.$t('available')

            if (isStartField) {
              timeSlot.text =
                hours > 0 && minutes > 0
                  ? `(${hours}h & ${minutes}min ${available})`
                  : hours > 0 && minutes < 1
                    ? `(${hours}h ${available})`
                    : `(${minutes}min ${available})`
            }

            // timeSlot.background = '#DE5D83'
            timeSlot.background = '#000000'
            timeSlot.color = '#ffffff'
            timeSlot.showDetails = true
          }
        })
      }

      return timeSlots
    },
    footerWithDescription() {
      const highestProbabilityText = this.$t('customer_available_time_description')
      if (this.transalatorAvailableSlots.length > 0) {
        return `<style>
      .dropdown-with-footer .el-select-dropdown {
                padding-bottom: 50px;
                position: relative;
              }
      .dropdown-with-footer .el-select-dropdown::after {
                content: '${highestProbabilityText}';
                position: absolute;
                bottom: 0;
                left: 5%;
                right: 5%;
                background: #fff;
                font-size: 12px;
                color: #666;
                text-align: left;
                border-top: 2px solid #eee;
                box-sizing: border-box;
                padding: 5px 10px 5px 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 15 15' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.5' cy='7.5' r='7.5' fill='%232FBD8E'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: left 0px top 10px;
                }
              </style>
            `
      }
      return ''
    },

    iClass() {
      return this.className === '' ? this.blockClass : this.className
    }
  },
  watch: {
    lastValue(v) {
      if (v) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.iValue = v
            this.sanitizeInput(v)
          }, 10)
        })
      }
    }
  },
  /*
    |--------------------------------------------------------------------------
    | Component > methods
    |--------------------------------------------------------------------------
    */
  methods: {
    /**
     * Sanitize the input before assigning.
     *
     * @returns {void}
     */
    sanitizeInput(v) {
      if (!isNil(v) && isString(v) && v !== '') {
        const pattern = /([:,. ])/
        const hasDivider = v.match(pattern) !== null

        if (hasDivider) {
          v = v.replace(pattern, ':')

          // Check if the splitted members lacks in digit count
          let splitted = v.split(':')
          if (splitted[0].length < 2) splitted[0] = '0' + splitted[0]
          if (splitted[1].length < 2) splitted[1] = splitted[1] + '0'
          v = splitted.join(':')
        } else if (v.length === 4) {
          const HH = v.substr(0, 2)
          const mm = v.substr(2, 2)
          v = `${HH}:${mm}`
        }

        this.iValue = v
        this.$emit('change', v)
      }
    },

    /**
     * @returns {void}
     */
    // handleBlur() {
    //   if (!this.isBlurred) {
    //     this.isBlurred = true
    //     // let className = this.iClass ==='' ? this.blockClass : this.iClass
    //     const el = this.$refs[this.iClass]
    //
    //     if (!this.isTabbed) {
    //       el.selectOption()
    //       el.blur()
    //     }
    //
    //     setTimeout(() => (this.isBlurred = false), 500)
    //   }
    // },

    /**
     * Handler when the tab key was pressed while being focused in the input.
     *
     * @returns {void}
     */
    handleKeyTab() {
      this.isTabbed = true
      const el = this.$refs[this.blockClass]

      if (el) {
        el.selectOption()
        el.blur()
      }

      setTimeout(() => (this.isTabbed = false), 500)
    },

    handleEnterKey(e) {
      let val = e.target.value
      if (e.key === 'Enter' && val) {
        this.$nextTick(() => {
          this.lastValue = val

          this.openEndTimeDropDown()
        })
      }
    },

    handleNativeClick() {
      const el = this.$refs[this.blockClass]
      this.$emit('native-click', el)
    },
    getId(v) {
      return `${v}-${Math.floor(Math.random() * 1000)}`
    },
    handleFocus() {
      const el = this.$refs[this.iClass]

      if (this.selectdefaulstarttime && !this.iValue) {
        const defaultTimeSelected = '07:00'
        const wrapRef = el.$refs.scrollbar.wrapRef
        let timeList = wrapRef.childNodes[0].childNodes

        timeList = [...timeList]
        const choosenChild = timeList.findIndex((i) => i.innerText === defaultTimeSelected)

        this.$nextTick(() => {
          setTimeout(() => {
            if (choosenChild > -1) {
              timeList[choosenChild].classList.add('hover')
              timeList[choosenChild].classList.add('selected')
              wrapRef.scrollTop = timeList[choosenChild].offsetTop
            }
          }, 150)
        })
      }
    },
    openTimeDropDown() {
      this.$refs[this.iClass].focus()
    },

    openEndTimeDropDown() {
      this.$refs[this.iClass].focus()
      setTimeout(() => {
        this.$refs[this.iClass].blur()
      }, 10)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';
@import '@/assets/scss/variables/_containers.scss';
.booking-time-field {
  .el-input {
    .el-form-item__info {
      color: var(--app-primary-color);
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      left: 0;
    }
    .el-input__wrapper {
      input {
        background: #ffff;
      }
    }
  }
}

.el-form-item.is-error {
  .booking-time-field {
    .el-input {
      .el-form-item__info {
        display: none;
      }
    }
  }
}
</style>
