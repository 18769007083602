/*
|--------------------------------------------------------------------------
| Store > Auth > Actions > requestToken
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import USER_TYPE from '@/modules/constants/userType'
import axios from 'axios'
import { getAppTenant } from '@/modules/helpers/multiTenancy'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { AUTH_USER } from '@/modules/constants/api'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import i18n from '@/locales/i18nClient.js'

const { t: $t } = i18n.global

/**
 * Reach out to API for token request
 *
 * @param {Object} context - Current context of Vuex module.
 * @returns {*}
 */
export default function (context) {
  const method = 'POST'
  const url = API.AUTH

  let appTenantId = ''
  let clientId = ''
  const appTenant = getAppTenant()
  if (appTenant) {
    appTenantId = appTenant.uuid
    clientId = appTenant.client_id
  }
  let data = { ...context.state.loginForm, tenant: appTenantId, client_id: clientId }

  context.state.isLoadingLogin = true

  return new Promise((resolve, reject) => {
    axios({ method, url, data })
      .then((r) => {
        // Set the token.
        context.commit('setToken', {
          token_type: r.data.token_type,
          access_token: r.data.access_token,
          expires_in: r.data.expires_in,
          refresh_token: r.data.refresh_token
        })

        context.commit('setRefreshToken', r.data.refresh_token)

        getUser()
          .then((user) => {
            if (
              [USER_TYPE.CUSTOMER, USER_TYPE.TRANSLATOR, USER_TYPE.ADMIN, USER_TYPE.SUPER_ADMIN].indexOf(user.type) > -1
            ) {
              context.commit('setUser', user)
              context.dispatch('initToken')
              context.state.isLoadingLogin = false
              resolve(r)
            } else {
              context.state.isLoadingLogin = false
              context.commit('destroyToken')
              context.commit('clearPusher')
              context.commit('destroyUser')
              context.commit('destroyUserMeta')
              context.commit('setIsUserAuthenticated', false)
              reject()
            }
          })
          .catch((e) => {
            context.state.isLoadingLogin = false
            reject(e)
          })
      })
      .catch((e) => {
        context.state.isLoadingLogin = false
        reject(e)
      })
  })
}

function getUser() {
  return new Promise((resolve, reject) => {
    axiosInstance({ method: 'GET', url: `${AUTH_USER}?include=` })
      .then((r) => {
        resolve(r.data?.data?.user)
      })
      .catch((e) => {
        showSmallErrorNotif($t('invalid_token_error_message'))
        reject(e)
      })
  })
}
