/*
|--------------------------------------------------------------------------
| Store > Getters
|--------------------------------------------------------------------------
|
| This file is where the Module's getter methods are registered.
|
| Feel free to edit this file if you have getter methods you want
| to include.
|
*/
export default {
  /**
   * @returns {Array}
   */
  leaveRequestListSummaries: (state) => state.leaveRequests.summaries,

  /**
   * @returns {Array}
   */
  leaveRequestListData: (state) => state.leaveRequests.data,

  /**
   * @returns {Object}
   */
  leaveRequestListPagination: (state) => state.leaveRequests.pagination,

  /**
   * @returns {Boolean}
   */
  isLoadingLeaveRequestList: (state) => state.leaveRequests.loading,

  /**
   * @returns {Boolean}
   */
  isLoadingLeaveRequestSummaries: (state) => state.leaveRequests.loadingSummaries
}
