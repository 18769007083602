/*
|--------------------------------------------------------------------------
| Store > Statistics > Actions
|--------------------------------------------------------------------------
| File that contains the actions property for the current Vuex scope.
*/

import loadStatistics from './loadStatistics'
import exportToExcel from './exportToExcel'

export default {
  loadStatistics,
  exportToExcel
}
