/*
|--------------------------------------------------------------------------
| Custom > Constants > API
|--------------------------------------------------------------------------
|
| This file contains the list of api endpoints.
|
*/

// Define helper for setting default value.
const sd = (v, d) => (v == null ? d : v)

export const RECRUITMENT_BASE = import.meta.env.VITE_RECRUITMENT_API_BASE || 'https://stage-rekrytering.digitaltolk.com'

export const BASE = sd(import.meta.env.VITE_API_BASE, 'https://api-gateway-stg.digitaltolk.net')

export const AUTH_API_BASE = sd(import.meta.env.VITE_AUTH_API_BASE, 'https://auth-stg.digitaltolk.net')

export const REGISTER = `/api/v3/core/register`

export const SEND_VERIFICATION_CODE = `/api/v3/core/verification-codes`

export const VERIFY_CODE = `/api/v3/core/verification-codes/verify`

export const STRIPE_CREATE_SETUP_INTENT = `/api/v3/core/stripe/setup/intent`

export const BOOKINGS_PRICE_QUOTE = `/api/v3/core/guest-bookings/price-quote`

export const PUBLIC_COMPANIES = `/api/v3/core/companies-get-for-ct`

export const COMPANY_BY_ORG_NUMBER = `/api/v3/core/company-by-org-number`

export const USER_EXISTS_FOR_EMAIL = `/api/v3/core/users/email/:email/exists`

export const MUNICIPALITY_BY_EMAIL = `/api/v3/core/municipalities/domain/for-email`

export const PUBLIC_DEPARTMENTS = `/api/v3/core/departments-get-for-ct`

export const AUTH = `${AUTH_API_BASE}/api/v1/oauth/token`
export const AUTH_USER = `/api/v3/core/users/profile`
export const PASSWORD_FORGOT = `${AUTH_API_BASE}/api/v1/password/forgot` // a.k.a. Request Reset Password
export const PASSWORD_RESET = `${AUTH_API_BASE}/api/v1/password/reset` // a.k.a. Verify Reset Password
export const PASSWORDLESS_LOGIN = `${AUTH_API_BASE}/api/v1/password/request-passwordless-link`
// user sessions
export const USER_SESSIONS = `${AUTH_API_BASE}/api/v1/oauth/sessions`
export const USER_SESSIONS_LOGOUT = `${AUTH_API_BASE}/api/v1/oauth/sessions/logout/{sessionUid}`
export const USER_SESSIONS_LOGOUT_ALL = `${AUTH_API_BASE}/api/v1/oauth/sessions/logout-all`

export const LEAVE_REQUESTS = `/api/v3/core/workforce-management/leave-requests`

export const USERS = `/api/v3/core/users`

export const TRANSLATORS = `/api/v3/core/translators`
export const TRAININGS = `/api/v3/core/trainings`

export const BLACKLISTED_USERS = `/api/v3/core/blacklisted-users`

export const FEEDBACK = `/api/v3/core/feedback`

export const MUNICIPALITIES = `/api/v3/core/municipalities`

export const COMPANIES = `/api/v3/core/companies`

export const DEPARTMENTS = `/api/v3/core/departments`

export const BOOKINGS = `/api/v3/core/bookings`
export const BOOKING = `/api/v3/core/booking`

export const RECURRING_BOOKINGS = `/api/v3/core/recurring-bookings`

export const EXPORT_BOOKING = `/api/v3/core/export-bookings`

export const GUEST_BOOKINGS = `/api/v3/core/guest-bookings`

export const FINANCIAL = `/api/v3/financial/bookings`

export const BOOKING_FIELDS = `/api/v3/core/booking-fields` // a.k.a. Booking Confirm Fields

export const BOOKING_SPECIFIC_PREFERENCES = `/api/v3/core/booking-specific-preferences`

export const CONFIRMATION_FILLS = `/api/v3/core/confirmation-fills`

export const COURSES = `/api/v3/core/courses`

export const COURSE_SECTIONS = `/api/v3/core/course-sections`

export const COURSE_PARTICIPANTS = `/api/v3/core/course-participants`

export const USER_PARTICIPANTS_PROGRESS = `/api/v3/core/user-participants-progress`

export const LANGUAGES = sd(import.meta.env.VITE_LANGUAGES_BASE, '/api/v3/core/languages')

export const TRANSLATOR_LEVELS = `/api/v3/core/translator-levels`

export const TRAVEL_CALCULATION = `/api/v3/travel-time/travel-calculation`

export const RECRUITMENT_LANGUAGES = `${RECRUITMENT_BASE}/api/languages`

export const DEEPL_LANGUAGES = `/api/v3/core/deepl-languages`

export const STATISTICS = `/api/v3/core/statistics`

export const EXPORT_STATISTICS_EXCEL = `/api/v3/core/statistics/export-excel`

export const PAYROLLS = `/api/v3/financial/payrolls`

export const TEXT_TRANSLATION = `/api/v3/core/text-translations`

export const CUSTOMER_TEXT_TRANSLATION = `/api/v3/core/customer-text-translations`

export const STORAGE = `/api/v3/storage/storage`

export const ISSUES = `/api/v3/core/booking-issues`

export const BLOG = `/api/v3/core/blog`

export const MY_ISSUES = `/api/v3/core/my-issues`

export const BOOKING_ISSUES = `/api/v3/core/booking-issues`

export const SURVEY = `/api/v3/core/survey`

export const NOTIFIER_API_PATH = sd(import.meta.env.VITE_NOTIFIER_BASE, 'https://pling.digitaltolk.se')

export const NOTIFIER_PROJECT_CODE = sd(import.meta.env.VITE_NOTIFIER_PROJECT_CODE, 'dt-core')

export const BOOKING_MESSAGES = `/api/v3/core/booking-messages`

export const SIGNED_STORAGE = `/api/v3/storage/signed-storage-url`
export const SIGNED_STORAGE_MULTIPART = `/api/v3/storage/signed-storage-multipart-urls`
export const SIGNED_STORAGE_MULTIPART_COMPLETE = `/api/v3/storage/signed-storage-multipart-complete`
export const NOTIFICATION_PREFERENCE = `/api/v3/core/user-notification-preference`

export const PUBLIC_ACCEPT_BOOKING = `/api/v3/core/accept-booking`
export const PUBLIC_REJECT_BOOKING = `/api/v3/core/reject-booking`

export const CUSTOMER = `/api/v3/core/customer`
export const CUSTOMER_CATEGORIES = `/api/v3/core/customer-categories`
export const CUSTOMER_CREATE = `${CUSTOMER}/create-from-ct`
export const CUSTOMER_CREATE_FROM_BOOKING = `${CUSTOMER}/create-from-booking`
export const CUSTOMERS = `/api/v3/core/customers`

export const SET_UI_URL = `/api/v3/core/error-notification/set-current-url`

export const CUSTOMER_PAYMENT_METHODS = `${CUSTOMER}/payment_methods`
export const MAKE_PAYMENT_METHOD_DEFAULT = `${CUSTOMER}/payment_methods/default`
export const TRANSACTION_HISTORY = `${CUSTOMER}/transaction_history`

export const SALARY_FILES = `/api/v3/financial/salary-files`

export const TRANSLATOR_UNAVAILABLE_TIMES = `/api/v3/core/translator-unavailable-times`
export const TRANSLATOR_WEEKLY_SCHEDULE = `/api/v3/core/translator-weekly-schedules`

export const AVAILABLE_TRANSLATOR_BOOKING_DATES = `/api/v3/core/booking-dates-with-available-timeslots`
export const AVAILABLE_TRANSLATOR_BOOKING_DATES_TIME_SLOTS = `/api/v3/core/available-timeslots-by-date`
export const CALCULATE_PROBABILITES_FOR_ASSIGN_BOOKING = `/api/v3/core/bookings/calculate-probabilities-by-dates`

export const NOTIFICATIONS = `/api/v3/core/notifications`
export const NOTIFICATIONS_MARK_READ = `${NOTIFICATIONS}/markasread`

export const MULTIPLE_BOOKING = `/api/v3/core/bookings/multiple`
export const TRANS_TARGET_URL = sd(import.meta.env.VITE_TRANS_TARGET_URL, 'https://trans-target.akuttolk.se')

export const GENERATE_CERTIFICATE = `/api/v3/core/generate/certificate`
export const INSURANCE_CERTIFICATE = `/api/v3/financial/translators`
export const EXPORT_GDPR = `/api/v3/core/export-gdpr-data`

export const TRANSLATOR_AVAILABILITY = `/api/v3/core/translator-availability`

export const PRICE_LISTS = `/api/v3/core/price-list-documents`
export const BOOKING_TIME_SLOT = `${BASE}/api/v3/core/booking-time-slots`

export const UPDATE_USER_PAYMENT_METHOD = `/api/v3/core/users/update-payment-method-type`

export const CHECK_CREDIT_WORTHINESS = '/api/v3/core/users/credit-check'

export const TEXT_TRANSLATION_COST_ESTIMATION = `${BASE}/api/v3/financial/potential-text-booking-invoice-preview`

export const POTENTIAL_BOOKING_INVOICE_PREVIEW = `${BASE}/api/v3/financial/potential-booking-invoice-preview`

export const BOOKING_ATTEMPT = `${BASE}/api/v3/core/booking-attempt`
export const GET_CUSTOMER_MARKETINGS = `${BASE}/api/v3/core/customer-marketings`

export const TOWNS = `/api/v3/core/towns`
export const TOWNS_FOR_CT = `/api/v3/core/towns-for-ct`

export const INVOICE_TEXT_TRANSLATION_FEE = `/api/v3/financial/customers/{customerId}/text-translation-express-hours`
export const TEXT_TRANSLATION_EXPRESS_HOURS = `/api/v3/financial/text-translation-express-hours`
// export const APPOINTMENT_API = sd(
//   import.meta.env.VITE_APPOINTEMENT_API,
//   'https://polished-truth-ujdoppl7h19f.vapor-farm-a1.com'
// )

export const MULTI_TENANT_BASE = sd(import.meta.env.VITE_CONFIG_API_BASE, 'https://config-stg.digitaltolk.net')
export const TENANT = `${MULTI_TENANT_BASE}/api/v1/tenants`

export const APPOINTMENT_API =
  import.meta.env.VITE_APPOINTEMENT_API || 'https://polished-truth-ujdoppl7h19f.vapor-farm-a1.com'

export const SECTORS = `https://recruitmenttool.digitaltolk.com/api/sectors`

export const REGISTER_TEXT_TRANSLATION = `${RECRUITMENT_BASE}/api/text-translator`

export const CUSTOMER_INTEGRATION_API =
  import.meta.env.VITE_CUSTOMER_INTEGRATION_API || 'https://bookingintegrations.digitaltolk.com'

export const EXCHANGE_OUTLOOK_CODE = '/api/v3/core/outlook/exchange-code'
export const CALENDAR_STATUS = '/api/v3/core/calendar/status'
export const CUSTOMER_ACADEMY = import.meta.env.VITE_ACADEMY_REDIRECT_URL || 'https://www.digitaltolk.se/academy/hem'

export const GET_DOCUMENTS_LANGUAGE_RESULTS = '/documents-language-results'
export const GET_TEXT_TRANSLATION_DEADLINE_SUGGESTIONS = '/api/v3/core/text-translation/deadline-suggestions'
export default {
  BASE,
  RECRUITMENT_BASE,

  // Auth ---
  AUTH,
  AUTH_USER,
  PASSWORD_FORGOT,
  PASSWORD_RESET,
  PASSWORDLESS_LOGIN,

  // Users ---
  USERS,
  TRANSLATORS,
  BLACKLISTED_USERS,
  FEEDBACK,

  LEAVE_REQUESTS,

  // Sectors ---
  MUNICIPALITIES,
  COMPANIES,
  DEPARTMENTS,

  // Bookings ---
  BOOKINGS,
  BOOKING,
  BOOKING_FIELDS,
  BOOKING_SPECIFIC_PREFERENCES,
  CONFIRMATION_FILLS,

  // Courses
  COURSES,
  COURSE_SECTIONS,
  COURSE_PARTICIPANTS,
  USER_PARTICIPANTS_PROGRESS,

  // Options ---
  LANGUAGES,
  RECRUITMENT_LANGUAGES,
  DEEPL_LANGUAGES,
  TRANSLATOR_LEVELS,

  // Travel ---
  TRAVEL_CALCULATION,

  // Statistics ---
  STATISTICS,

  EXPORT_STATISTICS_EXCEL,

  // Payrolls/Earnings ---
  PAYROLLS,

  // Text translation ---
  TEXT_TRANSLATION,
  CUSTOMER_TEXT_TRANSLATION,

  // Storage ---
  STORAGE,

  // Notifiers ---
  NOTIFIER_API_PATH,
  NOTIFIER_PROJECT_CODE,

  // Blog
  BLOG,

  //SURVEY
  SURVEY,

  // Booking Issues ---
  ISSUES,
  BOOKING_ISSUES, // TODO: Potential Duplicated value of ISSUES
  MY_ISSUES,

  BOOKING_MESSAGES,

  // s3 signed storage endpoint
  SIGNED_STORAGE,
  SIGNED_STORAGE_MULTIPART,
  SIGNED_STORAGE_MULTIPART_COMPLETE,

  //NOTIFICATION PREFERENCE
  NOTIFICATIONS_MARK_READ,
  NOTIFICATION_PREFERENCE,

  // Public ---
  PUBLIC_ACCEPT_BOOKING,
  PUBLIC_REJECT_BOOKING,

  // Customer
  CUSTOMER,
  CUSTOMER_CREATE,
  CUSTOMERS,

  SET_UI_URL,
  CUSTOMER_PAYMENT_METHODS,
  MAKE_PAYMENT_METHOD_DEFAULT,
  TRANSACTION_HISTORY,

  SALARY_FILES,
  FINANCIAL,
  TRANSLATOR_UNAVAILABLE_TIMES,
  TRANS_TARGET_URL,

  EXPORT_BOOKING,
  RECURRING_BOOKINGS,
  MULTIPLE_BOOKING,

  GENERATE_CERTIFICATE,
  INSURANCE_CERTIFICATE,
  EXPORT_GDPR,

  TRANSLATOR_AVAILABILITY,

  //User sessions
  USER_SESSIONS,
  USER_SESSIONS_LOGOUT,
  USER_SESSIONS_LOGOUT_ALL,

  TOWNS,
  TOWNS_FOR_CT,
  TRAININGS,

  INVOICE_TEXT_TRANSLATION_FEE,
  PRICE_LISTS,
  TEXT_TRANSLATION_COST_ESTIMATION,
  BOOKING_ATTEMPT,
  //translator availability
  TRANSLATOR_WEEKLY_SCHEDULE,

  AVAILABLE_TRANSLATOR_BOOKING_DATES,
  AVAILABLE_TRANSLATOR_BOOKING_DATES_TIME_SLOTS,

  // get sectors
  SECTORS,

  // register text translator
  REGISTER_TEXT_TRANSLATION,
  CUSTOMER_INTEGRATION_API,

  // TENANT
  MULTI_TENANT_BASE,
  TENANT,

  // MARKETING
  GET_CUSTOMER_MARKETINGS,

  UPDATE_USER_PAYMENT_METHOD,
  CHECK_CREDIT_WORTHINESS,
  CALENDAR_STATUS,
  CALCULATE_PROBABILITES_FOR_ASSIGN_BOOKING,
  BOOKING_TIME_SLOT,

  CUSTOMER_ACADEMY,
  GET_DOCUMENTS_LANGUAGE_RESULTS,
  TEXT_TRANSLATION_EXPRESS_HOURS,
  GET_TEXT_TRANSLATION_DEADLINE_SUGGESTIONS
}
