<template>
  <div class="app-layout" :class="layoutClass">
    <header :class="getBem(blockCls, 'header')">
      <el-row justify="left" align="middle">
        <el-col :xs="24" :sm="12" :md="12" :class="getBem(blockCls, 'header-brand')">
          <router-link :to="getLocaleRoutePath('/')" tabindex="0">
            <!--            <picture v-if="$store.getters['multitenancy/type'] === 2">-->
            <!--              <source-->
            <!--                class="brand-logo"-->
            <!--                srcset="@/assets/images/brand-logo-dk.webp"-->
            <!--                type="image/webp"-->
            <!--                :alt="$t('digitaltolk_logo')"-->
            <!--                width="220"-->
            <!--                height="44"-->
            <!--              />-->
            <!--              <img-->
            <!--                class="brand-logo"-->
            <!--                src="@/assets/images/brand-logo-dk.png"-->
            <!--                :alt="$t('digitaltolk_logo')"-->
            <!--                width="220"-->
            <!--                height="44"-->
            <!--              />-->
            <!--            </picture>-->
            <picture>
              <source
                class="brand-logo"
                srcset="@/assets/images/brand-logo.webp"
                type="image/webp"
                :alt="$t('digitaltolk_logo')"
                width="220"
                height="44"
              />
              <img
                class="brand-logo"
                src="@/assets/images/brand-logo.png"
                :alt="$t('digitaltolk_logo')"
                width="220"
                height="44"
              />
            </picture>
          </router-link>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :class="getBem(blockCls, 'header-contact')">
          <div :class="getBem(blockCls, 'header-contact-mail')">
            <a href="mailto:leyla@digitaltolk.se">
              <!--suppress HtmlUnknownTarget -->
              <img
                src="@/assets/images/mail-sm-icon.png"
                :class="getBem(blockCls, 'header-contact-mail-icon')"
                :alt="$t('email_address')"
              />
              <span>Leyla@digitaltolk.se</span>
            </a>
          </div>
          <div :class="getBem(blockCls, 'header-contact-phone')">
            <!--suppress HtmlUnknownTarget -->
            <img
              src="@/assets/images/phone-sm-icon.png"
              :class="getBem(blockCls, 'header-contact-phone-icon')"
              :alt="$t('phone_number')"
            />
            <span>070 483 01 00</span>
          </div>
        </el-col>
      </el-row>
    </header>
    <main>
      <!-- Banner -->
      <div :class="getBem(blockCls, 'banner')">
        <div :class="getBem(blockCls, 'banner-wrapper')">
          <div :class="getBem(blockCls, 'banner-verbiage')">
            <h3>Snabbt och enkel tillgång till tolkar genom vår personliga service</h3>
            <p>Kontakta oss idag:</p>
            <h2>070 483 01 00</h2>
          </div>
          <div :class="getBem(blockCls, 'banner-actions')">
            <el-button class="app-button app-button-secondary" @click="handleClickContact"> Kontakta oss </el-button>
            <el-button class="app-button app-button-secondary" @click="handleClickBookNow"> Eller boka här </el-button>
          </div>
        </div>
      </div>

      <!-- Content -->
      <div :class="getBem(blockCls, 'content')">
        <slot />
      </div>

      <!-- Testimonials -->
      <div :class="getBem(blockCls, 'testimonials')">
        <h2 :class="getBem(blockCls, 'testimonials-title')">Testimonials om våra tolkar</h2>
        <div :class="getBem(blockCls, 'testimonials-carousel')">
          <carousel :autoplay="true" :loop="true" :autoplay-timeout="5000" :per-page="1" :pagination-enabled="false">
            <slide>
              <p>
                Administratör och Modersmålsanvarig,<br />
                Västerholms Friskola
              </p>
              <h3>
                DigitalTolk har verkligen gjort skillnad! Det är enkelt att boka, tolkarna kommer i tid och är dessutom
                alltid lika trevliga!
              </h3>
            </slide>
            <slide>
              <p>Attorney Esq. AB</p>
              <h3>
                Alltid lika snabb och bra hjälp. Nu använder jag min tid till det jag ska göra, det vill säga ta hand om
                mina kunder. Med DigitalTolk slipper jag dessutom lägga ner 20-30 minuter odebiterbar tid på
                tolkadministration. Uppskattar verkligen tjänsten!
              </h3>
            </slide>
          </carousel>
        </div>
      </div>

      <div :class="getBem(blockCls, 'partners')">
        <h2 :class="getBem(blockCls, 'partners-title')">Några av våra nöjda referenskunder</h2>

        <div :class="getBem(blockCls, 'partners-carousel')">
          <carousel
            :autoplay="true"
            :loop="true"
            :per-page-custom="[
              [0, 1],
              [480, 2],
              [768, 3],
              [1024, 5]
            ]"
            :pagination-enabled="false"
          >
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners1.jpg" alt="Partner # 1" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners2.jpg" alt="Partner # 2" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners3.jpg" alt="Partner # 3" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners4.jpg" alt="Partner # 4" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners5.jpg" alt="Partner # 5" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners6.jpg" alt="Partner # 6" />
            </slide>
            <slide>
              <!--suppress HtmlUnknownTarget -->
              <img src="@/assets/images/partners7.jpg" alt="Partner # 7" />
            </slide>
          </carousel>
        </div>
      </div>
    </main>

    <footer :class="getBem(blockCls, 'footer')">
      <div :class="getBem(blockCls, 'footer-wrapper')">
        <div :class="getBem(blockCls, 'footer-title')">
          <h2>Kontakta oss</h2>
          <p>Vi erbjuder snabb och smidig tillgång till kvalitetstolkar.</p>
        </div>
        <div :class="getBem(blockCls, 'footer-content')">
          Alltid lika snabb och bra hjälp. Nu använder jag min tid till det jag ska göra, det vill säga ta hand om mina
          kunder.
        </div>
        <div :class="getBem(blockCls, 'footer-contact')">
          <div :class="getBem(blockCls, 'footer-contact-mail')">
            <a href="mailto:leyla@digitaltolk.se">
              <!--suppress HtmlUnknownTarget -->
              <img
                src="@/assets/images/mail-lg-icon.png"
                :class="getBem(blockCls, 'mail-icon')"
                :alt="$t('email_address')"
              />
              <span>Leyla@digitaltolk.se</span>
            </a>
          </div>
          <div :class="getBem(blockCls, 'footer-contact-phone')">
            <!--suppress HtmlUnknownTarget -->
            <img
              src="@/assets/images/phone-lg-icon.png"
              :class="getBem(blockCls, 'mail-icon')"
              :alt="$t('phone_number')"
            />
            <span>070 483 01 00</span>
          </div>
        </div>
      </div>
      <div :class="getBem(blockCls, 'copyright')">© {{ currentYear }} DigitalTolk - All Rights Reserved</div>
    </footer>

    <el-dialog v-model="isShowContactFormModal" class="app-modal app-modal-secondary" :title="$t('nav_contact')">
      <contact-form-partial @submit-success="isShowContactFormModal = false" />
    </el-dialog>
  </div>
  <!-- Component DIV Wrapper -->
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel'
import ContactFormPartial from '@/components/partials/ContactFormPartial'

export default {
  components: {
    ContactFormPartial
    // Carousel,
    // Slide
  },

  /*
    |--------------------------------------------------------------------------
    | Component > data
    |--------------------------------------------------------------------------
    */
  data() {
    return {
      blockCls: 'landing-layout',
      isShowContactFormModal: false
    }
  },

  /*
    |--------------------------------------------------------------------------
    | Component > computed
    |--------------------------------------------------------------------------
    */
  computed: {
    /**
     * Returns the current year value.
     *
     * @returns {number}
     */
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },

    /**
     * @returns {array}
     */
    layoutClass() {
      const viewportClass = this.isOnMobileViewport ? 'on-mobile-viewport' : 'on-desktop-viewport'
      const authClass = this.isUserAuthenticated ? 'user-authenticated' : 'user-not-authenticated'
      const showMobileNavClass = this.isShowMobileNav ? 'mobile-nav-shown' : 'mobile-nav-hidden'

      if (document != null) document.body.className = [viewportClass, authClass, showMobileNavClass].join(' ')

      return [this.getBem(this.blockClass)]
    }
  },

  methods: {
    handleClickContact() {
      this.isShowContactFormModal = true
    },

    handleClickBookNow() {
      const path = this.getLocaleRoutePath('/')
      this.$router.push({ path })
    }
  }
}
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';

//.v-modal {
//  z-index: 2000 !important;
//}

.landing-layout {
  width: 100%;
  background-color: #fff;

  &__header {
    padding: 0 $app-md-container-side-padding;
    height: 110px;

    .el-row {
      height: 100%;
    }

    &-brand {
      a {
        padding: 10px 0;
        display: block;
      }

      &-logo {
        width: auto;
        height: 100%;
        max-height: 55px;
        box-sizing: border-box;
      }
    }

    &-contact {
      text-align: right;

      &-mail,
      &-phone {
        display: inline-block;

        img {
          position: relative;
          width: auto;
          height: 14px;
        }
      }

      &-mail {
        margin-right: 30px;

        img {
          top: 2px;
          margin-right: 3px;
        }
      }

      &-phone {
        img {
          top: 1px;
        }
      }
    }
  }

  &__banner {
    width: 100%;
    height: 530px;
    background-image: url('@/assets/images/boka-dari-tolk-banner.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &-wrapper {
      position: absolute;
      padding: 30px 25px;
      top: 80px;
      right: 50px;
      background-color: transparentize(#000, 0.3);
      width: 400px;
      text-align: center;
    }

    &-verbiage {
      h3 {
        color: #fff;
        font-size: 22px;
        margin-top: 0;
        margin-bottom: 5px;
      }

      p {
        color: #fff;
        font-size: 14px;
        margin-top: 0;
        margin-bottom: 5px;
      }

      h2 {
        color: #ffb63b;
        font-size: 42px;
        margin-top: 0;
        margin-bottom: 15px;
      }
    }

    &-actions {
      .el-button {
        font-size: 16px;
      }
    }
  }

  &__content {
    $content-color: lighten($app-primary-text-color, 25%);
    padding: 50px $app-md-container-side-padding;

    p {
      text-align: justify;
    }

    color: $content-color;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    ul > li,
    ol > li {
      color: $content-color;
    }
  }

  &__testimonials {
    padding: 50px $app-md-container-side-padding;
    text-align: center;

    background-color: var(--app-primary-color);
    color: #fff;

    &-title {
      margin-top: 0;
      margin-bottom: 55px;
      font-size: 21px;
    }

    &-carousel {
      width: 75%;
      margin: 0 auto;
    }

    .VueCarousel-slide {
      p {
        font-size: 16px;
        margin-bottom: 45px;
      }

      h3 {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.7em;
      }
    }
  }

  &__partners {
    padding: 70px $app-md-container-side-padding;
    text-align: center;

    &-title {
      margin-top: 0;
      margin-bottom: 25px;
      font-size: 21px;
    }
  }

  &__footer {
    background-color: $app-gray;
    font-size: 18px;
    color: #fff;
    text-align: center;

    &-wrapper {
      padding: 70px 15px;
    }

    &-title {
      margin-bottom: 15px;

      h2 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 0;
      }

      h2,
      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &-content {
      margin-bottom: 55px;
    }

    &-contact {
      &-mail,
      &-phone {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;

        img,
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }

      &-mail {
        a,
        a:hover,
        a:focus,
        a:visited,
        a:active {
          color: #fff;
        }

        img {
          width: auto;
          height: 45px;
          margin-right: 10px;
        }
      }

      &-phone {
        img {
          width: auto;
          height: 50px;
          margin-right: 5px;
        }
      }
    }
  }

  &__copyright {
    padding: 20px 15px;
    text-align: center;
    background-color: var(--app-primary-color);
    font-size: 16px;
  }
}

// **************************************************
// Extra Small Viewport
// **************************************************
@media (max-width: $screen-xs-max) {
  .landing-layout {
    &__header {
      &-brand,
      &-contact {
        text-align: center;
      }

      &-brand {
        a {
          padding-bottom: 5px;
        }
      }

      &-contact {
        padding-bottom: 10px;

        &-mail {
          margin-right: 10px;
          font-size: 14px;
        }

        &-phone {
          font-size: 14px;
        }
      }
    }

    &__banner {
      height: 310px;
      background-size: auto 100%;
      background-position: -60px top;

      &-wrapper {
        position: relative;
        top: 40px;
        right: 0;
        margin: 0 auto;
        box-sizing: border-box;
        width: 90%;
      }

      &-verbiage {
        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }

        h2 {
          font-size: 38px;
        }
      }

      &-actions {
        .el-button {
          font-size: 14px;
          margin-left: 0;
          margin-bottom: 5px;
        }
      }
    }

    &__content {
      padding: 50px $app-xs-container-side-padding;
    }

    &__testimonials {
      padding: 50px $app-xs-container-side-padding;

      &-carousel {
        width: 100%;
      }
    }

    &__partners {
      padding: 50px $app-sm-container-side-padding;

      &-carousel {
        width: 100%;
      }
    }

    &__footer {
      font-size: 16px;

      &-wrapper {
        padding: 30px 15px;
      }

      &-title {
        h2 {
          font-size: 21px;
        }
      }

      &-content {
        margin-bottom: 30px;
      }

      &-contact {
        &-mail,
        &-phone {
          display: block;
        }

        &-mail {
          margin-bottom: 10px;

          img {
            height: 20px;
          }
        }

        &-phone {
          img {
            height: 25px;
          }
        }
      }
    }
  }
}

// **************************************************
// Small Viewport
// **************************************************
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .landing-layout {
    &__header {
      &-brand,
      &-contact {
        text-align: center;
      }

      &-brand {
        a {
          padding-bottom: 5px;
        }
      }

      &-contact {
        padding-bottom: 10px;
      }
    }

    &__banner {
      &-wrapper {
        position: relative;
        right: 0;
        margin: 0 auto;
      }
    }

    &__content {
      padding: 50px $app-sm-container-side-padding;
    }

    &__testimonials {
      padding: 50px $app-sm-container-side-padding;

      &-carousel {
        width: 90%;
      }
    }

    &__partners {
      padding: 50px $app-sm-container-side-padding;

      &-carousel {
        width: 90%;
      }
    }

    &__footer {
      font-size: 16px;

      &-wrapper {
        padding: 40px 15px;
      }

      &-content {
        margin-bottom: 30px;
      }

      &-contact {
        &-mail {
          margin-bottom: 10px;

          img {
            height: 20px;
          }
        }

        &-phone {
          img {
            height: 25px;
          }
        }
      }
    }
  }
}

// **************************************************
// Medium Viewport
// **************************************************
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .landing-layout {
    &__header {
      &-contact {
        &-mail {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
