/*
|--------------------------------------------------------------------------
| Store > Booking > Actions > loadTranslatorNormalBookings
|--------------------------------------------------------------------------
|
*/

import API from '@/modules/constants/api'
import { bookingsListFilterOptions, loadAll } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import assignIn from 'lodash/assignIn'
import store from '@/store'
import { STARTED, ASSIGNED, IN_REVIEW, DECLINED } from '@/modules/constants/bookingStatus'

/**
 * Action to load translator normal bookings.
 *
 * @param {Object} context - Current context of the Vuex module.
 * @param {Object} payload - Contains necessary values usable within the method.
 * @param {Integer} payload.page - The page offset to be displayed.
 * @param {Integer} [payload.customer_id] - The Authenticated Customer's ID.
 * @returns {Promise}
 */
export default async function (context, payload = {}) {
  context.commit('setTranslatorNormalBookingListLoading', true)

  const url = API.BOOKINGS
  const method = 'GET'
  const include = ['specific_translators', 'video_booking'].join(',')
  const append = ['rejected_files_count']
  const topControls = await store.getters['bookingList/topControls']
  if (!topControls?.booking_statuses?.length) {
    topControls.booking_statuses.push(STARTED, ASSIGNED)
  }
  let statuses = topControls?.booking_statuses ?? []

  /** If statuses has 'started(3)' status then also include 'in_review(23) and declined(24)' status */
  if (statuses.includes(STARTED)) {
    if (!statuses.includes(IN_REVIEW)) {
      statuses.push(IN_REVIEW)
    }

    if (!statuses.includes(DECLINED)) {
      statuses.push(DECLINED)
    }
  }

  /** If statuses doesn't have 'started(3)' status then also remove 'in_review(23) and declined(24)' status */
  if (!statuses.includes(STARTED)) {
    if (statuses.includes(IN_REVIEW)) {
      const inreviewStatusIndex = statuses.indexOf(IN_REVIEW)
      if (inreviewStatusIndex !== -1) {
        statuses.splice(inreviewStatusIndex, 1)
      }
    }

    if (statuses.includes(DECLINED)) {
      const declinedStatusIndex = statuses.indexOf(DECLINED)
      if (declinedStatusIndex !== -1) {
        statuses.splice(declinedStatusIndex, 1)
      }
    }
  }

  let options = {
    query_params: {
      include,
      append,
      // sort: 'due',
      'filter[status_id]': statuses?.toString(),
      'filter[type]': 'text_translation'
    },

    on_finally(ctx) {
      ctx.commit('setTranslatorNormalBookingListLoading', false)
      store.commit('booking/setBookingFilterQueryForExport', assignIn(options.query_params, filterQueries))
    }
  }

  const filterQueries = bookingsListFilterOptions(payload, {
    exclude: ['customer_id']
  })

  assignIn(options.query_params, filterQueries)

  return loadAll(this, context, url, method, 'setTranslatorNormalBookingList', options, axiosInstance)
}
