<template>
  <dt-dialog
    class="app-modal app-modal-secondary"
    :class="getBem(blockClass)"
    :title="$t('booking_update_title')"
    :show="isShowModal"
    top="3%"
    data-cy="booking-details-modal-v2"
    @closed="isShowModal = false"
  >
    <template #header>
      {{ $t('booking_detail') }}
    </template>
    <!-- Booking ID -->
    <div :class="['booking-id-row', isReviewAndAccept && isBookingTypePhysical ? 'padding10' : '']">
      <div class="label-capitalize">
        {{ $t('booking_id') }}
      </div>
      <div class="label-value" data-cy="booking-detail-id">
        {{ booking.id || '-' }}
      </div>
    </div>
    <div
      v-if="isShowModal"
      :class="['booking-details-modal', isReviewAndAccept && isBookingTypePhysical ? 'is-physical' : '']"
    >
      <el-row :gutter="20">
        <el-col :md="isReviewAndAccept && isBookingTypePhysical ? 12 : 24">
          <div class="specs-container no-seconday-prefs">
            <div class="more-info pd-top">
              <div class="primary-prefs-container">
                <!-- Booking Language -->
                <!-- from language -->
                <div v-if="!isBookingTypeTemporaryWork" class="row">
                  <div class="col">
                    <div class="label">
                      {{ isBookingTypeTextTranslation ? $t('from_language') : $t('language') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value" data-cy="booking-detail-language-from">
                      {{ languageOpts[booking.from_language_id] }}
                    </div>
                    <template v-if="booking.is_show_alternative_languages_field">
                      <div v-for="index in secondaryLanguagePlaceholdersForPrimaryPrefs" :key="index" class="value">
                        &nbsp;
                      </div>
                    </template>
                  </div>
                </div>
                <!-- to language -->
                <div v-if="isBookingTypeTextTranslation" class="row">
                  <div class="col">
                    <div class="label">
                      {{ isBookingTypeTextTranslation ? $t('to_language') : $t('language') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value" data-cy="booking-detail-language-from">
                      {{ languageOpts[booking.to_language_id] }}
                    </div>
                    <template v-if="booking.is_show_alternative_languages_field">
                      <div v-for="index in secondaryLanguagePlaceholdersForPrimaryPrefs" :key="index" class="value">
                        &nbsp;
                      </div>
                    </template>
                  </div>
                </div>

                <!-- Booking Time -->
                <div class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_time') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value2" data-cy="booking-detail-appointment">
                      {{ booking.due_date }}, {{ booking.due_time }} - {{ bookingEndTime }}
                      <span v-show="hasTimeSlots" class="animated-clock">
                        <FlexibleBookingTimeSlotsPopover
                          :show-selected-start-time="true"
                          :booking="booking"
                        ></FlexibleBookingTimeSlotsPopover>
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Alternative Times (Not available yet) -->
                <!-- <div v-if="hasTimeSlots" class="row">
                  <div class="col">
                    <div class="label">{{ $t('all_time_slots') }}</div>
                  </div>
                  <div class="col">
                    <div class="value" v-for="(slot, index) in bookingTimeSlots" :key="index">
                      {{ slot.date }}, {{ slot.start_time }} - {{ slot.end_time }}
                    </div>
                  </div>
                </div> -->

                <!-- Booking Duration -->
                <div v-if="!isBookingTypeTextTranslation" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('duration') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value" data-cy="booking-detail-duration">{{ duration }}</div>
                  </div>
                </div>

                <!-- Temporary Booking Instructions -->
                <div v-if="isBookingTypeTemporaryWork" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('temporary_work_instructions') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.instructions }}
                    </div>
                  </div>
                </div>

                <!-- Booking Type -->
                <div v-if="!isBookingTypeTemporaryWork" class="row">
                  <div class="col">
                    <div class="label">
                      {{ isBookingTypeTextTranslation ? $t('stat_booking_type') : $t('booking_type') }}
                    </div>
                  </div>
                  <div class="col">
                    <template v-if="!isVideoBooking">
                      <div class="value">
                        {{
                          $t('booking_type_' + booking.type, {
                            type: subtype
                          })
                        }}
                      </div>
                    </template>
                    <template v-else>
                      <div class="value">
                        {{ $t('booking_type_' + videoBookingProvider) }}
                      </div>
                    </template>
                    <sustainable-badge-booking-details
                      v-if="booking.type == 'phone' || isVideoBooking"
                      style="margin-top: 5px"
                    />
                  </div>
                </div>

                <div v-if="isTranslatorModel && booking?.direct_salary" class="text-color-primary bold">
                  <el-icon>
                    <Money />
                  </el-icon>
                  {{ $t('pay_direct_salary') }}
                </div>

                <!-- Text Translation Text to Translate -->
                <div
                  v-if="isBookingTypeTextTranslation && !empty(booking.text_translation.additional_text)"
                  class="row"
                >
                  <div class="col">
                    <div class="label">
                      {{ $t('text_translation_additional_text') }}
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="isShowFullText">
                      {{ booking.text_translation.additional_text }}
                      <span @click="toggleShowMore">
                        <b class="show-more">{{ $t('tt_show_less') }}</b>
                      </span>
                    </div>
                    <div v-else>
                      {{ limitTextLength(booking.text_translation.additional_text) }}
                      <span v-if="shouldShowShowMore(booking.text_translation.additional_text)" @click="toggleShowMore">
                        ... <b class="show-more">{{ $t('tt_show_more') }}</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <!-- Text Translation words count -->
                  <div v-if="isBookingTypeTextTranslation" class="row">
                    <div class="col">
                      <div class="label">
                        {{ $t('tt_approximate_word_count') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">{{ textTranslationApproximateWordsCount }}</div>
                    </div>
                  </div>
                </div>

                <!-- Text Translation isAskingPrice -->
                <div v-if="isBookingTypeTextTranslation && isAskingPrice" class="row tt-asking-price">
                  <div class="col">
                    <div class="label">
                      {{ $t('cost') }}
                    </div>
                  </div>
                  <div v-if="booking.text_translation.cost !== '0.00'" class="col row">
                    <div class="value">{{ booking.text_translation.cost }} kr</div>
                    <div class="actions">
                      <el-button type="primary" small class="app-button-secondary" @click="handleAcceptPrice()">
                        {{ $t('accept_price') }}
                      </el-button>
                      <el-button class="app-button-secondary-o" @click="handleRejectPrice()">
                        {{ $t('reject_price') }}
                      </el-button>
                    </div>
                  </div>
                </div>

                <div v-if="isVideoBooking" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('video_url') }}
                    </div>
                  </div>
                  <div class="col">
                    <a class="value link" :href="generateUrl(bookingVideo)" target="_blank">
                      {{ bookingVideo.provider === 'jitsi' ? $t('open_video_url') : bookingVideo.url }}
                    </a>
                  </div>
                </div>

                <!-- countries -->
                <template v-if="selectedCountryNames.length > 0">
                  <div class="row">
                    <div class="col">
                      <div class="label">
                        {{ $t('selected_countries') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ selectedCountryNames.join(', ') }}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="label">
                        {{ $t('countries_preference') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ booking.countries_required ? $t('country_must_have') : $t('country_nice_to_have') }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <el-divider />

            <!-- START OF BOOKER NAME AND TRANSLATOR INFO -->
            <div class="basic-info">
              <div class="primary-prefs-container">
                <template v-if="isBookingWasTaken && bookingTranslator">
                  <!-- Name  -->
                  <div class="row">
                    <div class="col">
                      <div class="label">
                        {{ isBookingTypeTextTranslation ? $t('translator_name1') : $t('translator_name') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">{{ bookingTranslator.name }} - {{ bookingTranslator.id }}</div>
                    </div>
                  </div>
                  <!-- Tolk ID -->
                  <!-- <div class="row">
                <div v-if="!isTranslatorModel" class="col">
                  <div class="label ">
                    {{ $t('tolk_id') }}
                  </div>
                </div>
                <div class="col">
                  <div class="value">
                    {{ bookingTranslator.id }}
                  </div>
                </div>
              </div> -->

                  <!-- Phone -->
                  <div class="row">
                    <div class="col">
                      <div class="label">
                        {{ $t('phone') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        <div v-if="isAllowNumberSettingsStatus" class="translator-mobile-long">
                          <i18n-t keypath="booking_details_translator_mobile">
                            <template #unified_number>
                              <a :href="`tel:${unifiedNumber}`" class="value link">
                                <strong>{{ unifiedNumber }}</strong>
                              </a>
                            </template>
                            <template #booking_id>
                              <strong>{{ booking.id }}</strong>
                            </template>
                          </i18n-t>
                        </div>
                        <template v-else>
                          <a :href="`tel:${bookingTranslator.mobile}`" class="value link">
                            {{ bookingTranslator.mobile || '-' }}
                          </a>
                        </template>
                      </div>
                    </div>
                  </div>

                  <!-- Email -->
                  <div v-if="!isTranslatorModel && booking.translator_email != null" class="row">
                    <div class="col">
                      <div class="label">
                        {{ isBookingTypeTextTranslation ? $t('translator1_email') : $t('translator_email') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ booking.translator_email }}
                      </div>
                    </div>
                  </div>

                  <!-- Gender  -->
                  <div
                    v-if="
                      !isTranslatorModel &&
                      booking?.payable_level != null &&
                      booking?.payable_level != 0 &&
                      !isBookingTypeTextTranslation
                    "
                    class="row"
                  >
                    <div class="col">
                      <div class="label">
                        {{ $t('assigned_translator_gender') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ $t(bookingTranslator.gender) }}
                      </div>
                    </div>
                  </div>

                  <!-- Level assigned  -->
                  <div
                    v-if="
                      !isTranslatorModel &&
                      booking?.payable_level != null &&
                      booking?.payable_level != 0 &&
                      !isBookingTypeTextTranslation
                    "
                    class="row"
                  >
                    <div class="col">
                      <div class="label">
                        {{ $t('assigned_translator_level') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ assignedTranslator(booking.payable_level) }}
                      </div>
                    </div>
                  </div>

                  <!-- DOB  -->
                  <div v-if="!isTranslatorModel && booking.translator_dob != null" class="row">
                    <div class="col">
                      <div class="label">
                        {{ isBookingTypeTextTranslation ? $t('translator_ssn') : $t('translator_dob') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ booking.translator_dob }}
                      </div>
                    </div>
                  </div>

                  <!-- Address -->
                  <div v-if="!isTranslatorModel && booking.translator_address != null" class="row">
                    <div class="col">
                      <div class="label">
                        {{ isBookingTypeTextTranslation ? $t('translator1_address') : $t('translator_address') }}
                      </div>
                    </div>
                    <div class="col">
                      <div class="value">
                        {{ booking.translator_address || '-' }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <el-divider v-if="isBookingWasTaken && bookingTranslator" />
            <!-- END OF BOOKER NAME AND TRANSLATOR INFO -->
            <div v-if="!isBookingTypeTemporaryWork" class="more-info">
              <div class="primary-prefs-container">
                <!-- Booker Name -->
                <div class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_booker_name') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.booker_name || '-' }}
                    </div>
                  </div>
                </div>

                <!-- Staff Name -->
                <div class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_staff_name') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.staff_name || '-' }}
                    </div>
                  </div>
                </div>

                <!-- Staff Number -->
                <div v-if="isBookingTypeTextTranslation" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_staff_phone') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.text_translation.phone || '-' }}
                    </div>
                  </div>
                </div>

                <div
                  v-if="!isTranslatorModel && bcfConfig.staff_phone_enabled && !isBookingTypeTextTranslation"
                  class="row"
                >
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_staff_phone') }}
                    </div>
                  </div>
                  <div class="col" style="display: flex; align-items: center; gap: 10px">
                    <div class="value">
                      {{ booking.staff_phone || '-' }}
                    </div>
                    <!-- <sustainable-badge-booking-details v-if="booking.type == 'phone' || isVideoBooking" /> -->
                  </div>
                </div>

                <!-- Contact Number -->
                <div class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('contact_number') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      <a :href="`tel:${bookingCustomer.mobile}`">{{ bookingCustomer.mobile }}</a>
                    </div>
                  </div>
                </div>

                <!-- Contact type -->
                <div class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('customer_type') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ $t('customer_type_' + bookingCustomer.customer_data.customer_type.id) }}
                    </div>
                  </div>
                </div>

                <!-- Refrence -->
                <div v-if="booking.reference != null || booking.reference !== ''" class="row">
                  <div class="col">
                    <div class="label">{{ $t('booking_reference') }}</div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.reference || '-' }}
                    </div>
                  </div>
                </div>

                <!-- Address -->
                <div v-if="isBookingTypePhysical" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('address') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.address }}
                    </div>
                  </div>
                </div>

                <!-- Address Details-->
                <div v-if="isBookingTypePhysical" class="row">
                  <div class="col">
                    <div class="label">
                      {{ $t('booking_address_instructions') }}
                    </div>
                  </div>
                  <div class="col">
                    <div class="value">
                      {{ booking.instructions }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isBookingTypeTemporaryWork" class="row">
              <div class="label more-booking-informations" style="margin-top: 20px" @click="toggleMoreInformations">
                {{ $t('more_booking_informations') }}
                <el-icon>
                  <ArrowUp v-if="showMoreInfo" />
                  <ArrowDown v-else />
                </el-icon>
              </div>
            </div>
          </div>

          <div v-if="showMoreInfo" class="booking-details">
            <div v-if="isBookingCancelled" class="row">
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('withdrawn_at') }}
                  </div>
                  <div class="value">
                    {{ booking.cancelled_at || '-' }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row two-col">
              <!-- additional_field -->
              <div class="col">
                <div class="block">
                  <div class="label">{{ bookingAdditionalFieldLabel }}</div>
                  <div class="value">
                    {{ bookingAdditionalField }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('department') }}
                  </div>
                  <div class="value">
                    {{ bookingCustomer.customer_data.department.name }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Company and muncipality -->
            <div class="row two-col">
              <div v-if="isTranslatorModel" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('company') }}
                  </div>
                  <div class="value">
                    {{ bookingCompany }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('municipality') }}
                  </div>
                  <div class="value">
                    {{ bookingMuncipality }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Information to interpreter -->
            <div v-if="informationToTranslator" class="row">
              <div class="block">
                <div class="label">
                  {{ $t('information_to_inteperator') }}
                </div>
                <div class="value">{{ informationToTranslator }}</div>
              </div>
            </div>

            <!-- Notifications via -->
            <div v-if="notificationsViaEmail || notificationsViaSms" class="row">
              <div class="block">
                <div class="label">
                  {{ $t('notifications_via') }}
                </div>
                <div class="value">
                  <span v-if="notificationsViaEmail">{{ $t('emails') }}: {{ notificationsViaEmail }}</span
                  ><br />
                  <span v-if="notificationsViaSms">{{ $t('sms') }}: {{ notificationsViaSms }}</span>
                </div>
              </div>
            </div>

            <!-- Specific prefrences -->
            <div class="row two-col">
              <!-- Specific translator -->
              <div v-if="!isTranslatorModel && specificTranslatorNames.length" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('specific_translators') }}
                  </div>
                  <div class="value">
                    <div v-for="stn in specificTranslatorNames" :key="stn" class="value">
                      {{ stn }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Excluded town -->
              <div v-if="!isTranslatorModel && excludedTownNames.length" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('excluded_towns') }}
                  </div>
                  <div class="value">
                    <div v-for="etown in excludedTownNames" :key="etown" class="value">
                      {{ etown }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Excluded translator -->
              <div v-if="!isTranslatorModel && excludedTranslatorNames.length" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('excluded_translators') }}
                  </div>
                  <div class="value">
                    <div v-for="etn in excludedTranslatorNames" :key="etn" class="value">
                      {{ etn }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Training translator -->
              <div v-if="canShowTrainings" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('translator_trainings') }}
                  </div>
                  <div class="value">
                    <div v-for="straning in selectedTrainingNames" :key="straning" class="value">
                      {{ straning }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Booking prefrences gender and levels -->
            <div v-if="!isTranslatorModel || isBookingWasTaken || isPotentialBooking" class="row two-col">
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{
                      isBookingTypeTextTranslation
                        ? $t('tt_selected_translator_levels')
                        : $t('translator_level_preferences')
                    }}
                  </div>
                  <div class="value">
                    <div v-for="stln in selectedTranslatorLevelNames" :key="stln" class="value">
                      {{ stln }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!isBookingTypeTextTranslation" class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('Gender Preference') }}
                  </div>
                  <div class="value">
                    <!-- {{ booking.gender || [$t('male'), $t('female')].join(', ') }} -->
                    {{ setGenderPreference(booking.gender) }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Text Translation Files Table-->
            <template
              v-if="
                isBookingTypeTextTranslation &&
                textTranslationNewStructuredFiles.length &&
                (booking.text_translation?.is_instant || booking.text_translation?.is_proof_reading)
              "
            >
              <el-table :data="textTranslationNewStructuredFiles" style="width: 100%" class="text-files-table">
                <el-table-column :label="$t('original')" :align="'left'">
                  <template #default="scope">
                    <div
                      class="original-column"
                      @mouseover="originalHoveredIndex = scope.row.original_file.id"
                      @mouseleave="originalHoveredIndex = null"
                    >
                      <span style="cursor: pointer" @click="openFile(scope.row.original_file)">
                        {{ addDotsForLongerFileName(scope.row.original_file.display_name, 24) }}
                        <el-icon
                          v-show="originalHoveredIndex === scope.row.original_file.id"
                          size="10"
                          style="margin-left: 1rem"
                          ><ArrowRightBold
                        /></el-icon>
                      </span>
                      <base-button
                        v-if="originalHoveredIndex === scope.row.original_file.id"
                        ghost
                        type="secondary"
                        size="tiny"
                        @click="downloadSelectedFile(scope.row.original_file)"
                      >
                        <base-icon class="icon" material-type="sharp" :size="22" color="#231F20"> download</base-icon>
                      </base-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Translated')" :align="'left'">
                  <template #default="scope">
                    <div
                      class="translated-column"
                      @mouseover="translatedHoveredIndex = scope.row.translated_file.id"
                      @mouseleave="translatedHoveredIndex = null"
                    >
                      <span style="cursor: pointer" @click="openFile(scope.row.translated_file)">
                        {{ addDotsForLongerFileName(scope.row.translated_file.display_name, 24) }}
                        <el-icon
                          v-show="translatedHoveredIndex === scope.row.translated_file.id"
                          size="10"
                          style="margin-left: 1rem"
                          ><ArrowRightBold
                        /></el-icon>
                      </span>
                      <base-button
                        v-if="translatedHoveredIndex === scope.row.translated_file.id"
                        ghost
                        type="secondary"
                        size="tiny"
                        @click="downloadSelectedFile(scope.row.translated_file)"
                      >
                        <base-icon class="icon" material-type="sharp" :size="22" color="#231F20"> download</base-icon>
                      </base-button>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column
                  v-if="booking.text_translation?.is_proof_reading"
                  :label="$t(subtype).replace('(', '').replace(')', '')"
                >
                  <template #default="scope">
                    <div class="translated-column">
                      <el-tag
                        v-if="scope.row.translated_file.pivot.submission_type === 'completed'"
                        size="medium"
                        type="success"
                      >
                        {{ $t('completed') }}
                      </el-tag>
                      <el-tag
                        v-else-if="scope.row.translated_file.pivot.submission_type === 'submited_for_proof_reading'"
                        size="medium"
                        type="warning"
                      >
                        {{ $t('requested') }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
            <!-- Text Translation auto Review Files Table-->
            <template
              v-if="
                isBookingCompleted &&
                isBookingTypeTextTranslation &&
                textTranslationAutoReviewFiles.length &&
                !booking.text_translation?.is_instant &&
                !booking.text_translation?.is_proof_reading
              "
            >
              <el-table :data="textTranslationAutoReviewFiles" style="width: 100%" class="auto-review-files-table">
                <el-table-column :label="$t('original')" width="250" :align="'left'">
                  <template #default="scope">
                    <div
                      class="original-column"
                      @mouseover="originalHoveredIndex = scope.row.original_file.id"
                      @mouseleave="originalHoveredIndex = null"
                    >
                      <span style="margin-left: 10px; cursor: pointer" @click="openFile(scope.row.original_file)">
                        {{ addDotsForLongerFileName(scope.row.original_file.display_name, 24) }}
                        <el-icon
                          v-show="originalHoveredIndex === scope.row.original_file.id"
                          size="10"
                          style="margin-left: 1rem"
                          ><ArrowRightBold
                        /></el-icon>
                      </span>
                      <button
                        v-show="originalHoveredIndex === scope.row.original_file.id"
                        class="download-btn"
                        @click="downloadSelectedFile(scope.row.original_file)"
                      >
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M7.67783 3.54175H6.75033V0.625081C6.75033 0.304248 6.48783 0.041748 6.16699 0.041748H3.83366C3.51283 0.041748 3.25033 0.304248 3.25033 0.625081V3.54175H2.32283C1.80366 3.54175 1.54116 4.17175 1.90866 4.53925L4.58616 7.21675C4.81366 7.44425 5.18116 7.44425 5.40866 7.21675L8.08616 4.53925C8.45366 4.17175 8.19699 3.54175 7.67783 3.54175ZM0.916992 9.37508C0.916992 9.69591 1.17949 9.95841 1.50033 9.95841H8.50033C8.82116 9.95841 9.08366 9.69591 9.08366 9.37508C9.08366 9.05425 8.82116 8.79175 8.50033 8.79175H1.50033C1.17949 8.79175 0.916992 9.05425 0.916992 9.37508Z"
                            fill="#231F20"
                          />
                        </svg>
                      </button>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('translated')">
                  <template #default="scope">
                    <div class="translated-file-item">
                      <div
                        v-for="file in scope.row?.non_versioned_files"
                        :key="file?.id"
                        class="translated-column"
                        @mouseover="translatedHoveredIndex = file.id"
                        @mouseleave="translatedHoveredIndex = null"
                      >
                        <div class="name-wrapper">
                          <span v-if="!file.pivot.is_versioned && file.pivot.is_resubmitted" class="red-dot"></span>
                          <span size="medium" class="file-name" @click="openFile(file)">
                            <span style="margin-right: 1rem; cursor: pointer">{{
                              addDotsForLongerFileName(file.display_name, 24)
                            }}</span>
                            <el-icon v-show="translatedHoveredIndex === file.id" size="10"> <ArrowRightBold /></el-icon
                          ></span>
                          <el-tag
                            v-if="file.pivot.submission_type === 'completed' && !file.pivot.is_versioned"
                            size="medium"
                            type="success"
                          >
                            {{ $t('tt_label_submitted') }}
                          </el-tag>
                          <el-tooltip
                            v-if="file.pivot.submission_type === 'rejected_translation'"
                            :content="$t(file.pivot.rejection_reason)"
                            placement="bottom"
                            effect="light"
                            :disabled="!file.pivot.rejection_reason || file.pivot.rejected_by_system_at"
                            popper-class="rejection-reason-tooltip"
                          >
                            <!-- <el-tag
                            v-if="file.pivot.rejected_by_system_at"
                            size="medium"
                            type="danger"
                          >
                            <i class="icon el-icon-warning-outline" />{{ $t('rejected_by_system') }}</el-tag
                          > -->
                            <el-tag size="medium" type="danger">
                              <i class="icon el-icon-warning-outline" />{{ $t('rejected') }}
                            </el-tag>
                          </el-tooltip>
                        </div>
                        <div class="">
                          <el-dropdown
                            trigger="click"
                            popper-class="translated-file-dropdown"
                            @command="handleDropDownCommand"
                          >
                            <span class="el-dropdown-link">
                              <button v-show="translatedHoveredIndex === file.id" class="dropdown-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" style="height: 1rem">
                                  <path
                                    d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
                                  />
                                </svg></button
                            ></span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  v-if="isSameMonthBooking && file.pivot.submission_type !== 'rejected_translation'"
                                  :command="{ action: 'resubmit', data: file }"
                                >
                                  {{ $t('request_resubmit') }}
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13.6449 2.35C12.1949 0.9 10.2049 0 7.99488 0C3.57488 0 0.00488281 3.58 0.00488281 8C0.00488281 12.42 3.57488 16 7.99488 16C11.7249 16 14.8349 13.45 15.7249 10H13.6449C12.8249 12.33 10.6049 14 7.99488 14C4.68488 14 1.99488 11.31 1.99488 8C1.99488 4.69 4.68488 2 7.99488 2C9.65488 2 11.1349 2.69 12.2149 3.78L8.99488 7H15.9949V0L13.6449 2.35Z"
                                      fill="#7B7979"
                                    />
                                  </svg>
                                </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'download', data: file }">
                                  {{ $t('download') }}
                                  <svg
                                    width="14"
                                    height="18"
                                    viewBox="0 0 14 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 6.5H10V0.5H4V6.5H0L7 13.5L14 6.5ZM6 8.5V2.5H8V8.5H9.17L7 10.67L4.83 8.5H6ZM0 15.5H14V17.5H0V15.5Z"
                                      fill="#7B7979"
                                    />
                                  </svg>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>

            <!-- Text Translation Files -->
            <template
              v-if="
                isBookingTypeTextTranslation &&
                !booking.text_translation?.is_instant &&
                !booking.text_translation?.is_proof_reading &&
                !textTranslationAutoReviewFiles.length
              "
            >
              <div class="row two-col">
                <!-- Text Translation Review Files -->
                <div
                  v-if="empty(booking.text_translation.additional_text) && !empty(textTranslationReviewFiles)"
                  class="col"
                >
                  <div class="block">
                    <div class="label">
                      {{ $t('text_translation_review_file') }}
                    </div>
                    <div class="files-wrapper">
                      <div v-for="file in textTranslationReviewFiles" :key="file.uid" class="file">
                        <svg class="icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.42L12.58 0.59C12.21 0.21 11.7 0 11.17 0ZM5 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12ZM5 8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8ZM5 4H10C10.55 4 11 4.45 11 5C11 5.55 10.55 6 10 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4Z"
                          />
                        </svg>

                        <div class="label text-color-success text-underline">
                          {{ addDotsForLongerFileName(file.display_name, 24) }}
                        </div>
                        <button class="icon-button" :disabled="is_waiting" @click="openFile(file)">
                          <div class="icon">
                            <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                              />
                            </svg>
                          </div>
                        </button>
                        <button class="icon-button success" :disabled="is_waiting" @click="downloadSelectedFile(file)">
                          <div class="icon">
                            <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM17 9L12.35 13.65C12.15 13.85 11.84 13.85 11.64 13.65L7 9H10V5H14V9H17Z"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Text Translation completed Files -->
                <div v-if="!empty(textTranslationCompletedFiles)" class="col">
                  <div class="block">
                    <div class="label">
                      {{ $t('completed_file') }}
                    </div>
                    <div class="files-wrapper">
                      <div v-for="file in textTranslationCompletedFiles" :key="file.uid" class="file">
                        <svg class="icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.42L12.58 0.59C12.21 0.21 11.7 0 11.17 0ZM5 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12ZM5 8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8ZM5 4H10C10.55 4 11 4.45 11 5C11 5.55 10.55 6 10 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4Z"
                          />
                        </svg>

                        <div class="label text-color-success text-underline">
                          {{ addDotsForLongerFileName(file.display_name, 24) }}
                        </div>
                        <button class="icon-button" :disabled="is_waiting" @click="openFile(file)">
                          <div class="icon">
                            <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                              />
                            </svg>
                          </div>
                        </button>
                        <button class="icon-button success" :disabled="is_waiting" @click="downloadSelectedFile(file)">
                          <div class="icon">
                            <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM17 9L12.35 13.65C12.15 13.85 11.84 13.85 11.64 13.65L7 9H10V5H14V9H17Z"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Files -->
            <div v-if="files.length" class="row">
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('attachments') }}
                  </div>
                  <div class="files-wrapper">
                    <div v-for="mFile in files" :key="mFile.uid" class="file">
                      <svg class="icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.42L12.58 0.59C12.21 0.21 11.7 0 11.17 0ZM5 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12ZM5 8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8ZM5 4H10C10.55 4 11 4.45 11 5C11 5.55 10.55 6 10 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4Z"
                        />
                      </svg>

                      <div class="label">
                        {{ mFile.name }}
                      </div>
                      <button class="icon-button" :disabled="is_waiting" @click="openFile(mFile)">
                        <div class="icon">
                          <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                            />
                          </svg>
                        </div>
                      </button>
                      <button class="icon-button success" :disabled="is_waiting" @click="downloadSelectedFile(mFile)">
                        <div class="icon">
                          <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM17 9L12.35 13.65C12.15 13.85 11.84 13.85 11.64 13.65L7 9H10V5H14V9H17Z"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isShowBspQuestions">
              <Divider />
              <div class="block mg-top">
                <div class="value">
                  {{ $t('answered_questions') }}
                </div>
              </div>
              <!-- Alternative question -->
              <div v-if="isShowAlternativeLanguageBspQuestion" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_accept_other_languages">
                    <template #selected>
                      <strong> {{ languageOpts[booking.from_language_id] }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ alternativeLanguages?.length == 0 ? $t('NO') : $t('YES') }}, {{ alternativeLanguages }}
                </div>
              </div>

              <!-- Physical question -->
              <div v-if="isShowPhysicalBspQuestion && !isBookingType('phone')" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_change_to_booking_type">
                    <template #type>
                      <strong>{{ $t('booking_type_phone').toLowerCase() }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ answerPhysical }}
                </div>
              </div>

              <!-- Physical to phone question -->
              <div v-if="isShowEncouragePhoneBookingBspQuestion && !isShowPhysicalBspQuestion" class="block mg-bottom">
                <div class="label">
                  {{ $t('bsp_accept_change_to_phone_booking_for_environmental') }}
                </div>
                <div class="value">
                  {{ answerEncouragePhone }}
                </div>
              </div>

              <!-- Gender question -->
              <div v-if="isShowTranslatorGenderBspQuestion" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_accept_other_gender">
                    <template #suggested>
                      <strong>{{ genderTerms.suggested.toLowerCase() }}</strong>
                    </template>
                    <template #selected>
                      <strong>{{ genderTerms.selected.toLowerCase() }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ answeredGender }}
                </div>
              </div>

              <!-- Specific translator question -->
              <div v-if="isShowSpecificTranslatorBspQuestion" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_accept_other_non_specified_translator">
                    <template #selected>
                      <strong>{{ specificTranslatorNames.join(', ') }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ answeredSpecifiedTranslator }}
                </div>
              </div>

              <!-- Translator level question -->
              <div v-if="isShowTranslatorLevelBspQuestion" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_accept_other_translator_levels">
                    <template #selected>
                      <strong>{{ selectedTranslatorLevelNames.join(', ') }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ answeredTranslatorLevel }}
                </div>
              </div>

              <!-- Country question -->
              <div v-if="isShowCountryBspQuestion" class="block mg-bottom">
                <div class="label">
                  <i18n-t keypath="bsp_accept_other_countries">
                    <template #selected>
                      <strong>{{ selectedCountryNames.join(', ') }}</strong>
                    </template>
                  </i18n-t>
                </div>
                <div class="value">
                  {{ answeredCountry }}
                </div>
              </div>

              <!-- Travel time question -->
              <div v-if="isShowTravelTimeBspQuestion" class="block mg-bottom">
                <div class="label">
                  {{ $t('bsp_accept_to_extend_travel_time') }}
                </div>
                <div class="value">
                  <template v-if="isShowPhysicalBspQuestion && booking?.booking_specific_preference?.physical">
                    Not applicable anymore as booking was converted from phone to physical.
                  </template>
                  <template v-else>
                    <span>{{ answerTravelTime }}</span> <br />
                    <span>Travel time hours: {{ bspTravelTimeHours }} </span> <br />
                    <span>Travel time KM: {{ bspTravelTimeKM }}</span>
                  </template>
                </div>
              </div>
            </div>

            <!-- Session time and video link -->
            <template v-if="isBookingWasTaken && !isBookingTypeTextTranslation">
              <Divider />
              <div class="row two-col">
                <div class="col">
                  <div class="block">
                    <div class="label">
                      {{ $t('booking_session_time') }}
                    </div>
                    <div class="value">
                      {{ booking.session_time || '-' }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div v-if="isTranslatorModel" class="block">
                    <div class="label">
                      {{ $t('session_comment_translator') }}
                    </div>
                    <div class="value">
                      {{ booking.session_comment_translator || '-' }}
                    </div>
                  </div>
                  <div v-else class="block">
                    <div class="label">
                      {{ $t('session_comment_customer') }}
                    </div>
                    <div class="value">
                      {{ booking.session_comment || '-' }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template
              v-if="isBookingWasTaken && isTranslatorModel && isBookingTypePhysical && !isBookingTypeVideoPhysical"
            >
              <Divider />

              <!-- Travel Time -->
              <div class="row two-col">
                <div class="col">
                  <div class="block">
                    <div class="label">
                      {{ $t('translator_max_travel_time') }}
                    </div>
                    <div class="value">
                      {{ maxTravelTime }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="block">
                    <div class="label">
                      {{ $t('translator_max_travel_km') }}
                    </div>
                    <div class="value">
                      {{ maxTravelKM }}
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Price list-->

            <template v-if="isTranslatorModel && !isUserEmployedTranslator">
              <Divider />
              <div class="row">
                <div class="col">
                  <div class="block">
                    <div class="label">
                      {{ $t('price_list') }}
                    </div>
                    <a href="#" class="value link" @click="handlePriceListLinkClick(booking.id, $event)">
                      {{ $t('click_here_to_see_price_list') }}
                    </a>
                    <!-- <i v-show="loading" class="el-icon-loading value" /> -->
                    <!--                    <el-icon v-show="loading" class="is-loading value">-->
                    <!--                      <Loading />-->
                    <!--                    </el-icon>-->
                  </div>
                </div>
              </div>
            </template>

            <!-- list all booking issue files -->
            <div v-if="!empty(issueFiles) && !isTranslatorModel" class="row">
              <div class="col">
                <div class="block">
                  <div class="label">
                    {{ $t('booking_issue_files') }}
                  </div>
                  <div class="files-wrapper">
                    <div v-for="mFile in issueFiles" :key="mFile.uid" class="file">
                      <svg class="icon" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.42L12.58 0.59C12.21 0.21 11.7 0 11.17 0ZM5 12H13C13.55 12 14 12.45 14 13C14 13.55 13.55 14 13 14H5C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12ZM5 8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8ZM5 4H10C10.55 4 11 4.45 11 5C11 5.55 10.55 6 10 6H5C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4Z"
                        />
                      </svg>

                      <div class="label text-color-success text-underline">
                        <!-- <i class="el-icon-document-copy" /> -->
                        <el-icon>
                          <DocumentCopy />
                        </el-icon>
                        {{ addDotsForLongerFileName(mFile.display_name, 24) }}
                      </div>
                      <button class="icon-button" :disabled="is_waiting" @click="openFile(mFile)">
                        <div class="icon">
                          <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                            />
                          </svg>
                        </div>
                      </button>
                      <button class="icon-button success" :disabled="is_waiting" @click="downloadSelectedFile(mFile)">
                        <div class="icon">
                          <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM17 9L12.35 13.65C12.15 13.85 11.84 13.85 11.64 13.65L7 9H10V5H14V9H17Z"
                            />
                          </svg>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          v-if="isReviewAndAccept && isBookingTypePhysical"
          v-loading="isLoadTravelTime"
          :md="12"
          class="location"
        >
          <div class="row">
            <div class="fullCol">
              <div class="row">
                <div class="iconsCol">
                  <img class="" srcset="@/assets/images/physicalStart.svg" alt="physicalStart" />
                  <div class="borderVertical"></div>
                  <img class="" srcset="@/assets/images/physicalLocation.svg" alt="physicalLocation" />
                </div>
                <!-- <div class="label"> -->
                <!-- <div class="row"> -->
                <div class="sourceLocation">
                  <div class="value">
                    {{ generateTime(booking.due_time, travelCalculation.driving.time) }} , {{ booking.due_date }}
                  </div>
                  <div class="secondary">{{ $t('your_location') }}</div>
                  <span>{{ address1() }}</span>
                  <div class="destLocation">
                    <div class="value">{{ booking.due_time }} , {{ booking.due_date }}</div>
                    <div class="secondary">{{ $t('customer_location') }}</div>
                    <span>{{ booking.address }}</span>
                  </div>
                </div>

                <!-- </div> -->

                <!-- </div> -->
              </div>
            </div>
          </div>
          <el-divider />
          <el-form :model="form" class="way-of-transit">
            <h3>{{ $t('select_way_of_transit') }}</h3>
            <span class="secondary">{{ $t('select_way_of_transit_desc') }}</span>
            <el-form-item>
              <el-radio-group v-model="form.traveling_by">
                <ul>
                  <li>
                    <el-radio label="driving">
                      <div class="travel-mode-icon">
                        <img
                          style="width: 20px; height: 20px"
                          srcset="@/assets/images/directions_car.svg"
                          alt="physicalEnd"
                        />
                        <span class="time">
                          {{ formatDuration(travelCalculation.driving.time) }}
                        </span>
                        <span class="secondary"> {{ travelCalculation.driving.distance }}km</span>
                      </div>
                    </el-radio>
                  </li>
                  <li>
                    <el-radio label="transit">
                      <div class="travel-mode-icon">
                        <img
                          style="width: 20px; height: 20px"
                          srcset="@/assets/images/directions_train.svg"
                          alt="physicalEnd"
                        />

                        <span class="time">
                          {{ formatDuration(travelCalculation.transit.time) }}
                        </span>
                        <span class="secondary"> {{ travelCalculation.transit.distance }}km</span>
                      </div>
                    </el-radio>
                  </li>
                </ul>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <!--          <div class="row">-->
          <!--            <div class="tag">-->
          <!--              <img-->
          <!--                style="width: 20px; height: 30px"-->
          <!--                srcset="@/assets/images/directions_car.svg"-->
          <!--                alt="physicalEnd"-->
          <!--              />-->
          <!--              <span class="kilometer">{{ travelCalculation.driving.distance }} km</span>-->
          <!--            </div>-->
          <!--            <div class="tag">-->
          <!--              <img-->
          <!--                style="width: 20px; height: 30px"-->
          <!--                srcset="@/assets/images/directions_train.svg"-->
          <!--                alt="physicalEnd"-->
          <!--              />-->
          <!--              <span class="kilometer">{{ travelCalculation.transit.distance }} km</span>-->
          <!--            </div>-->
          <!--          </div>-->
        </el-col>
      </el-row>
    </div>

    <template #footer-actions>
      <template v-if="isReviewAndAccept">
        <el-button size="large" @click="isShowModal = false">
          {{ $t('btn_close') }}
        </el-button>
        <el-button type="danger" size="large" @click="handleClickReject(booking)">
          {{ $t('btn_reject') }}
        </el-button>
        <el-button class="app-button-secondary" size="large" @click="handleClickAccept(booking)">
          {{ $t('btn_accept') }}
        </el-button>
      </template>
      <template v-else>
        <el-button type="default" size="large" @click="print()">
          {{ $t('btn_print') }}
        </el-button>

        <el-button
          v-if="!isTranslatorModel && showEditButton"
          type="default"
          class="app-button-default-o"
          @click="
            () => {
              $emit('edit', booking)
              isShowModal = false
            }
          "
        >
          {{ $t('edit') }}
        </el-button>

        <!-- Save buttons -->
        <el-button type="primary" class="app-button-secondary" @click="isShowModal = false">
          {{ $t('btn_ok') }}
        </el-button>
      </template>
    </template>
    <ResubmitTranslatedFileModal ref="resubmit-translated-file-modal" @submit="handleResubmitTranslatedFile" />
  </dt-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DtDialog from '../../../../../components/modals/Dialog.vue'
import ResubmitTranslatedFileModal from './ResubmitTranslatedFileModal.vue'
import { apiCall } from '@/modules/helpers/vuexActions'
import { axiosInstance } from '@/modules/axios-instances/internal-app-instance'
import { showFile } from '@/modules/helpers/file'
import Divider from '../../../../../components/v2/Divider.vue'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import findIndex from 'lodash/findIndex'
import findKey from 'lodash/findKey'
import isArray from 'lodash/isArray'
import getTranslatorLevels from '@/modules/constants/translatorLevel'
import { showSmallErrorNotif } from '@/modules/helpers/notifications'
import API, { FINANCIAL } from '@/modules/constants/api'
import { COMPLETED } from '@/modules/constants/bookingStatus'

import includes from 'lodash/includes'

export default {
  components: {
    DtDialog,
    Divider,
    ResubmitTranslatedFileModal
  },

  props: {
    isTranslatorModel: {
      type: Boolean,
      default: false
    },

    isPotentialBooking: {
      type: Boolean,
      default: false
    },

    showEditButton: {
      type: Boolean,
      default: true
    },
    isReviewAndAccept: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      blockClass: 'booking-details-dialog',
      isShowModal: false,
      translatorOptions: [],
      booking: {},
      loading: false,
      is_waiting: false,
      issueFiles: [],
      isShowFullText: false,
      translatedHoveredIndex: null,
      originalHoveredIndex: null,
      showMoreInfo: false,
      log: console.log,
      isLoadTravelTime: false,
      option: {},
      form: {
        traveling_by: 'driving'
      },
      travelCalculation: {
        driving: {
          time: 0,
          distance: 0
        },
        transit: {
          time: 0,
          distance: 0
        }
      }
    }
  },

  computed: {
    ...mapGetters('booking', ['languageOpts']),
    ...mapGetters('auth', ['isUserEmployedTranslator', 'userMetaData', 'isUserTranslator']),
    ...mapState('booking', ['bcfConfig']),

    ...mapGetters('multiTenancy', ['getTenantConfig']),

    formatGender() {
      let gender = this.booking.gender
      return gender === null || includes(gender, ',') ? `${this.$t('male')}, ${this.$t('female')}` : this.$t(gender)
    },
    duration() {
      if (this.booking.duration < 60) {
        return this.booking.duration + ' ' + this.$t('minutes')?.toLowerCase()
      }
      // return moment.duration(this.booking.duration, 'minutes').asHours()
      let hour = moment.duration(this.booking.duration, 'minutes').asHours()
      return hour + ' ' + (hour > 1 ? this.$t('hours') : this.$t('hour'))
    },

    selectedTranslatorLevelNames() {
      if (this.isBookingTypeTextTranslation) {
        if (!isEmpty(this.booking.language_pair_levels)) {
          return this.booking.language_pair_levels.map((languagePairLevel) =>
            this.translateLanguagePairLevelCode(languagePairLevel.code)
          )
        }
        return []
      }

      let sortedData = [...this.booking.translator_levels]

      sortedData = sortedData.sort((a, b) => b.translator_level_id - a.translator_level_id)

      console.log('sortedData', sortedData)

      const newOrder = [6, 5, 2, 4, 3, 1]

      for (let i = 0; i < sortedData.length; i++) {
        sortedData[i].order = newOrder.indexOf(sortedData[i].translator_level_id)
      }

      sortedData = sortedData.sort((a, b) => a.order - b.order)

      // console.log(sortedData)
      return sortedData.map((tLevel) => {
        return this.formatTranslatorLevel(tLevel.translator_level_id)
      })
    },

    selectedCountryNames() {
      return this.booking.countries.map((country) => {
        return this.$t('COUNTRY_CODE_' + country.iso)
      })
    },

    specificTranslatorNames() {
      let specificTranslatorIds = this.booking.specific_translators.map((t) => t.translator_id)
      return this.translatorOptions.filter((t) => specificTranslatorIds.includes(t.id)).map((t) => t.name)
    },

    excludedTranslatorNames() {
      let excludedTranslatorIds = this.booking.excluded_translators.map((t) => t.translator_id)
      return this.translatorOptions.filter((t) => excludedTranslatorIds.includes(t.id)).map((t) => t.name)
    },

    excludedTownNames() {
      return this.booking.excluded_towns.map((t) => t.name)
    },

    selectedTrainingNames() {
      return this.booking.translator_trainings.map((t) => t.name)
    },

    notificationEmail() {
      let smsNotification = this.notificationByType('email')
      return smsNotification ? smsNotification.recipient : '-'
    },

    notificationPhone() {
      let smsNotification = this.notificationByType('sms')
      return smsNotification ? smsNotification.recipient : '-'
    },

    isBookingCancelled() {
      return !isNil(this.booking.cancelled_at)
    },

    /**
     * Computed value for booking customer.
     * @returns {*}
     */
    bookingCustomer() {
      return isArray(this.booking.customer) ? this.booking.customer[0] : this.booking.customer
    },

    customerPhone() {
      return this.bookingCustomer.mobile
    },

    bookingCompany() {
      return this.booking?.customer?.department?.company?.name ?? '-'
    },

    bookingMuncipality() {
      return (
        this.booking?.customer?.department?.company?.municipality?.name ??
        this.booking?.customer?.customer_data?.department?.company?.municipality?.name
      )
    },
    bookingAdditionalFieldLabel() {
      if (this.booking.customer.booking_field?.additional_field_enabled) {
        return this.booking.additional_field_label ?? '-'
      }
      return '-'
    },
    bookingAdditionalField() {
      if (this.booking.customer.booking_field?.additional_field_enabled) {
        return this.booking?.additional_field ?? '-'
      }
      return '-'
    },

    maxTravelTime() {
      if (isNil(this.booking.travel_time)) return this.$t('not_specified')
      const maxTravel = this.booking.travel_time

      return !isNil(maxTravel)
        ? moment().startOf('day').add(maxTravel.travel_time, 'minutes').format('HH:mm')
        : this.$t('not_specified')
    },

    maxTravelKM() {
      if (isNil(this.booking.travel_time)) return this.$t('not_specified')
      const maxTravel = this.booking.travel_time

      return !isNil(maxTravel) ? maxTravel.travel_distance : this.$t('not_specified')
    },

    bookingEndTime() {
      let willEndAt = this.booking?.will_end_at ?? ''
      let endedAT = this.booking?.ended_at ?? ''

      willEndAt = willEndAt.split(' ').pop()
      endedAT = endedAT.split(' ').pop()

      return moment(endedAT ? endedAT : willEndAt, 'HH:mm:ss').format('HH:mm')
    },

    files() {
      return this.booking?.messages?.files || []
    },

    isBookingTypeVideoPhysical() {
      return ['video_physical'].includes(this.booking.type)
    },

    isBookingTypePhysical() {
      return ['physical', 'video_physical'].includes(this.booking.type)
    },

    isVideoBooking() {
      return ['video', 'video_skype', 'video_emergency'].includes(this.booking.type)
    },

    videoBookingProvider() {
      return this.booking.video_booking?.provider ?? this.booking.type
    },

    informationToTranslator() {
      return this.booking.messages?.message
    },

    /**
     * Returns boolean whether if the booking type is temporay work
     *
     * @returns {boolean}
     */
    isBookingTypeTemporaryWork() {
      return String(this.booking.type) === 'temporary_work'
    },

    /**
     * Returns boolean whether if the booking type is text translation.
     *
     * @returns {boolean}
     */
    isBookingTypeTextTranslation() {
      return String(this.booking.type) === 'text_translation'
    },

    /**
     * Returns boolean whether if the booking was entertained by the Translator at some point.
     *
     * @returns {Boolean}
     */
    isBookingWasTaken() {
      return !['pending', 'expired', 'cancelled', 'late_cancelled', 'late_cancelled_customer'].includes(
        this.booking.status.code
      )
    },

    bookingTranslator() {
      if (this.booking.status.code == 'planned') {
        return this.booking.planned_translator.translator
      } else {
        return this.booking.assigned_translator?.translator
      }
    },

    isBookingCompleted() {
      return this.booking.status_id === COMPLETED
    },
    /**
     * Returns the fail proof value for booking.video_booking.
     *
     * @returns {object}
     */
    bookingVideo() {
      if (!isNil(this.booking) && !isNil(this.booking.video_booking) && !isEmpty(this.booking.video_booking)) {
        return this.booking.video_booking
      } else {
        // Define value placeholders in here.
        return {}
      }
    },

    /**
     * Returns value to determine if the number settings status under
     * booking.customer.department is set to "allowed".
     *
     * @returns {boolean}
     */
    isAllowNumberSettingsStatus() {
      return this.booking?.customer?.department?.number_settings_status === 'allowed'
    },

    /**
     * Returns the unified number value under booking.customer.department.
     *
     * @return {number}
     */
    unifiedNumber() {
      return this.isAllowNumberSettingsStatus ? this.booking?.customer?.department?.number_settings_number : 0
    },

    /**
     * Returns boolean whether if booking has specific trainings.
     *
     * @returns {Boolean}
     */
    canShowTrainings() {
      return (
        (!this.isTranslatorModel || this.isBookingWasTaken || this.isPotentialBooking) &&
        (this.booking?.translator_trainings || []).length > 0
      )
    },
    hasTimeSlots() {
      return this.booking.booking_time_slots.length > 0
    },
    bookingTimeSlots() {
      return this.booking.acceptable_booking_dates ?? []
    },

    genderTerms() {
      const male = this.$t('male')
      const female = this.$t('female')
      return {
        selected: this.booking.gender === 'male' ? male : female,
        suggested: this.booking.gender === 'male' ? female : male
      }
    },

    alternativeLanguages() {
      const result = this.booking?.alternative_languages
        ?.map((entry) => this.languageOpts[entry.language_id])
        .join(', ')

      return result
    },

    notificationsVia() {
      let result = { email: [], sms: [] }
      this.booking?.notification_types.forEach((item) => {
        if (item.type == 'email') {
          result.email.push(item.recipient)
        } else if (item.type == 'sms') {
          result.sms.push(item.recipient)
        }
      })

      return result
    },

    notificationsViaEmail() {
      return this.notificationsVia.email.join(', ')
    },

    notificationsViaSms() {
      return this.notificationsVia.sms.join(', ')
    },

    isShowAlternativeLanguageBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_alternative_language
    },

    isShowPhysicalBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_physical
    },

    answerPhysical() {
      let verbiage = ''

      if (this.bsp) {
        const opt = this.booking?.booking_specific_preference?.physical

        if (opt === null) verbiage = 'Not Set'
        else if (opt === true) {
          verbiage = 'Convert my booking to phone'
        } else if (opt === false) {
          verbiage = "Don't convert to phone"
        } else if (opt === 'accept_phone_but_prefer_physical') {
          verbiage = 'I accept telephone, but prefer physical if possible.'
        }
      } else {
        verbiage = 'Value invalid'
      }

      return verbiage
    },

    isShowEncouragePhoneBookingBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_encourage_phone && this.booking.type === 'physical'
    },

    answerEncouragePhone() {
      const opt = this.booking.booking_specific_preference.encourage_phone
      let verbiage

      if (opt == 'accept_phone_but_prefer_physical') {
        verbiage = 'I accept telephone, but prefer physical if possible.'
      } else if (opt) {
        verbiage = 'Yes'
      } else {
        verbiage = 'No'
      }

      return verbiage
    },

    isShowTranslatorGenderBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_gender
    },

    answeredGender() {
      const bsp = this.booking?.booking_specific_preference?.gender
      if (bsp) {
        return this.$t('YES')
      } else {
        return this.$t('NO')
      }
    },

    isShowSpecificTranslatorBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_specific_translator
    },

    answeredSpecifiedTranslator() {
      const bsp = this.booking?.booking_specific_preference?.specific_translator
      if (bsp) {
        return this.$t('YES')
      } else {
        return this.$t('NO')
      }
    },

    isShowTranslatorLevelBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_translator_level
    },

    answeredTranslatorLevel() {
      const bsp = this.booking?.booking_specific_preference?.translator_level
      if (bsp) {
        return this.$t('YES')
      } else {
        return this.$t('NO')
      }
    },

    isShowCountryBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_country
    },

    answeredCountry() {
      const bsp = this.booking?.booking_specific_preference?.country
      if (bsp) {
        return this.$t('YES')
      } else {
        return this.$t('NO')
      }
    },

    isShowTravelTimeBspQuestion() {
      const bsp = this.booking?.booking_specific_preference
      return bsp?.was_asked_travel_time
    },

    answeredTravelTime() {
      const bsp = this.booking?.booking_specific_preference?.travel_time
      if (bsp) {
        return this.$t('YES')
      } else {
        return this.$t('NO')
      }
    },

    bspTravelTimeHours() {
      let minutes = this.booking?.booking_specific_preference?.travel_time_minutes

      if (!isNil(minutes)) {
        return minutes > 0 ? minutes / 60 : 0
      } else {
        return 'Not Set'
      }
    },

    /**
     * @returns {int|string}
     */
    bspTravelTimeKM() {
      let km = this.booking?.booking_specific_preference?.travel_time_km
      return !isNil(km) ? km : 'Not Set'
    },

    isShowBspQuestions() {
      return (
        this.isShowAlternativeLanguageBspQuestion ||
        this.isShowEncouragePhoneBookingBspQuestion ||
        this.isShowPhysicalBspQuestion ||
        this.isShowSpecificTranslatorBspQuestion ||
        this.isShowTranslatorGenderBspQuestion ||
        this.isShowTranslatorLevelBspQuestion ||
        this.isShowCountryBspQuestion ||
        this.isShowTravelTimeBspQuestion
      )
    },
    /**
     *
     **/
    textTranslationReviewFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'review'
        })
      }
      return []
    },

    /**
     * textTranslationNewStructuredFiles
     *
     */
    textTranslationNewStructuredFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        let hasTranslatedFiles = false

        if (this.booking.text_translation?.is_proof_reading) {
          const originalFile = this.booking.text_translation?.files.find(
            (file) => file.pivot.submission_type === 'review'
          )

          const proofreadingFile = this.booking.text_translation?.files.find(
            (file) => file.pivot.submission_type === 'submited_for_proof_reading'
          )

          const completedFile = this.booking.text_translation?.files.find(
            (file) => file.pivot.submission_type === 'completed'
          )

          if (proofreadingFile || completedFile) {
            hasTranslatedFiles = true
          }

          if (originalFile) {
            if (completedFile) {
              originalFile.translated_files = [completedFile]
            } else if (proofreadingFile) {
              originalFile.translated_files = [proofreadingFile]
            } else {
              originalFile.translated_files = []
            }
          }
        }

        const filteredFiles = this.booking?.text_translation?.files?.filter(
          (file) => file?.pivot?.submission_type === 'review' && file?.translated_files?.length > 0
        )

        const newStructuredFiles = filteredFiles.map((file) => {
          const originalFile = { ...file }

          let translatedFile = {}
          // Filter for non-versioned files to find the main translated file
          const nonVersionedFiles = file?.translated_files?.filter((tFile) => tFile?.pivot?.is_versioned === 0)

          if (nonVersionedFiles.length > 0) {
            // Take the first non-versioned file as the main translated file
            translatedFile = nonVersionedFiles[0]
          }

          const versionedFiles = file.translated_files.filter((tFile) => tFile?.pivot?.is_versioned === 1)

          return {
            original_file: originalFile,
            translated_file: translatedFile,
            versioned_files: versionedFiles,
            non_versioned_files: nonVersionedFiles || [],
            hasTranslatedFiles
          }
        })
        return newStructuredFiles
      }
      return []
    },

    /**
     * textTranslationAutoReviewFiles
     *
     */
    textTranslationAutoReviewFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files)
      ) {
        const filteredFiles = this.booking?.text_translation?.files?.filter(
          (file) => file?.pivot?.submission_type === 'review' && file?.translated_files?.length
        )

        const newStructuredFiles = filteredFiles.map((file) => {
          const originalFile = { ...file }

          let translatedFile = {}
          // Filter for non-versioned files to find the main translated file
          const nonVersionedFiles = file?.translated_files?.filter((tFile) => !tFile?.pivot?.is_versioned)
          const nonVersionedCompletedFiles = nonVersionedFiles.filter(
            (tFile) =>
              tFile?.pivot?.submission_type === 'completed' || tFile?.pivot?.submission_type === 'rejected_translation'
          )
          if (nonVersionedCompletedFiles.length) {
            // Take the first non-versioned file as the main translated file
            translatedFile = nonVersionedCompletedFiles[0]
          }

          const versionedFiles = file.translated_files.filter((tFile) => tFile.pivot.is_versioned)

          return {
            original_file: originalFile,
            translated_file: translatedFile,
            versioned_files: versionedFiles,
            non_versioned_files: nonVersionedCompletedFiles || []
          }
        })
        return newStructuredFiles
      }
      return []
    },

    /**
     * textTranslationCompletedFiles
     **/
    textTranslationCompletedFiles() {
      if (
        !isNil(this.booking) &&
        !isNil(this.booking.text_translation) &&
        !isNil(this.booking.text_translation.files) &&
        this.booking.status_id === COMPLETED
      ) {
        return this.booking.text_translation.files.filter(function (o) {
          return o.pivot.submission_type === 'completed'
        })
      }
      return []
    },
    textTranslationApproximateWordsCount() {
      if (this.booking.text_translation?.words_amount > 0) {
        return this.booking.text_translation?.words_amount
      }
      return this.textTranslationReviewFiles.reduce((total, file) => {
        const wordsCount = file.pivot.words_count ?? 0
        return total + wordsCount
      }, 0)
    },
    isAskingPrice() {
      return (
        this.isBookingTypeTextTranslation &&
        this.booking.text_translation.is_asking_price &&
        ![4, 5, 6, 7, 8, 9, 10, 12, 13, 21, 22].includes(this.booking.status_id)
      )
    },
    isInstantBooking() {
      return this.isBookingTypeTextTranslation && this.booking.text_translation?.is_instant
    },
    isProofReadBooking() {
      return this.isBookingTypeTextTranslation && this.booking.text_translation?.is_proof_reading
    },
    subtype() {
      if (this.isInstantBooking) {
        return this.$t('tt_label_instant')
      } else if (this.isProofReadBooking) {
        return this.$t('tt_label_proof_read')
      }

      return ''
    },

    isSameMonthBooking() {
      if (!this.booking) {
        return true
      }

      return moment(this.booking.due).isSame(moment(), 'month')
    },
    levelAdditionalText() {
      let additional = {
        certified_in_health: '',
        certified_in_law: '',
        certified_health: '',
        level_certified_law: ''
      }
      if (this.getTenantConfig?.name.toLowerCase() === 'uk') {
        additional = {
          certified_in_health: '- DPSI Health',
          certified_in_law: '- DPSI Law',
          certified_health: '- DPSI Health',
          certified_law: '- DPSI Health',
          certified: '- DPSI Holder'
        }
      }
      return additional
    }
  },

  methods: {
    ...mapActions('booking', [
      'getTowns',
      'getBookingIssueFiles',
      'getBookingDetails',
      'getTranslatorBatchEntry',
      'getBookingTravelCalculation'
    ]),

    ...mapActions('auth', ['loadTrainingOpts', 'loadTranslatorOpts']),
    ...mapActions('textTranslation', ['acceptPrice', 'rejectPrice', 'resubmitTranslatedFile']),

    /**
     * Method to be used outside of this component via $ref.
     * This will open the modal.
     *
     * @params {object} booking - current target booking.
     * @returns {void}
     */
    open(booking, option = {}) {
      this.showMoreInfo = false
      this.option = option
      //reset the issue files
      this.issueFiles = []
      this.getBookingDetails({
        id: booking.id,
        bookingStatus: booking.status.code,
        append: 'acceptable_booking_dates'
      })
        .then((response) => {
          this.loading = true
          this.booking = response.data.data.booking

          console.log(this.booking, 'this.booking')
          if (!this.isTranslatorModel) {
            let translators = this.booking.specific_translators.map((translator) => translator.translator_id)
            translators = [
              ...translators,
              ...this.booking.excluded_translators.map((translator) => translator.translator_id)
            ]
            this.loadTranslators(translators)
          }

          //only request this when booking is completed
          if (booking.status_id === 4) {
            this.getBookingIssueFiles({
              id: booking.id,
              params: { type: 'low_feedback' }
            }).then((r) => {
              this.issueFiles = r.data.data
            })
          }

          if (this.isUserTranslator && booking.type == 'physical') {
            this.isLoadTravelTime = true
            this.getBookingTravelCalculation({ id: booking.id })
              .then((resp) => {
                this.travelCalculation = resp?.data?.data
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
              .finally(() => {
                this.isLoadTravelTime = false
                this.loading = false
              })
          }
        })
        .finally(() => (this.isShowModal = true))
    },

    print() {
      window.print()
    },

    loadTranslators(translatorIds) {
      this.loadTranslatorOpts({ translator_id: translatorIds.join(',') })
        .then((r) => {
          this.translatorOptions = r.data.data[Object.keys(r.data.data)[0]]
        })
        .catch((e) => {
          showSmallErrorNotif(e)
        })
    },

    notificationByType(type) {
      return this.booking?.notification_types?.find((nt) => {
        return nt.type == type
      })
    },

    removeFile(uid) {
      let index = findIndex(this.booking.message_file, (file) => {
        return file.uid !== uid
      })

      this.files.splice(index, 1)

      this.booking.message_files = this.files
      this.booking.message_files = this.files
    },

    /**
     * Helper method for formatting Translator level values.
     *
     * @param {int|string} levelId - value to be formatted.
     * @returns {string}
     */
    formatTranslatorLevel(levelId) {
      const key = findKey(getTranslatorLevels(), (id) => id === parseInt(levelId))

      let additional = this.levelAdditionalText
      if (!isNil(key)) {
        return this.$t(`translator_level_${key.toLowerCase()}`, { additional: additional[key.toLowerCase()] ?? '' })
      }

      return ''
    },

    generateUrl(bookingVideo) {
      if (bookingVideo.url?.substring(0, 4) === 'http') {
        return bookingVideo.url
      } else {
        return '//' + bookingVideo.url
      }
    },
    generateTime(time, duration) {
      return moment(time, 'HH:mm').subtract(duration, 'minutes').format('HH:mm')
    },

    handlePriceListLinkClick(bookingId, event) {
      event.preventDefault()
      this.loading = true
      apiCall(
        {
          url: `${FINANCIAL}/${bookingId}/salary-setting`,
          method: 'GET'
        },
        axiosInstance
      )
        .then((r) => {
          const priceLink = r.data.data.salaries_setting.price_link
          priceLink ? window.open(priceLink, '_blank') : showSmallErrorNotif(this.$t('price_list_not_found'))
        })
        .catch((e) => {
          showSmallErrorNotif(e)
        })
        .finally(() => {
          this.loading = false
        })
    },

    downloadSelectedFile(item) {
      this.is_waiting = true
      let o = {
        params: `path=${item.path}&display_name=${item.display_name}`,
        filename: item.display_name
      }

      let url = `${API.STORAGE}/get?${o.params}`

      return axiosInstance.get(url).then((r) => {
        this.forceDownloadFileUrl(r.data.data.url, o.filename)
      })
    },
    /**
     * Helper methodss for handling download files to s3
     */
    openFile(item) {
      showFile(item)
    },

    /**
     * Helper methods for handling download files to s3
     */
    forceDownloadFileUrl(url, filename) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          this.is_waiting = false
        })
        .catch(console.error)
    },
    addDotsForLongerFileName(string, limit) {
      let dots = '...'
      let fileExt = string.split('.')
      if (fileExt[0].length > limit) {
        // you can also use substr instead of substring
        string = fileExt[0].substring(0, limit) + dots + fileExt[1]
      }

      return string
    },
    /**
     * helpers to check file is empty or not
     */
    empty(v) {
      return isEmpty(v) || isNil(v)
    },

    assignedTranslator(level) {
      let additional = this.levelAdditionalText
      const objLevel = {
        1: this.$t('layman', { additional: additional['layman'] ?? '' }), // /ÖT- Övrig tolk
        2: this.$t('certified', { additional: additional['certified'] ?? '' }), //AT - Auktoriserad tolk
        3: this.$t('read_courses'), //GrT - Grundutbildad tolk
        4: this.$t('trained'), //UT - Utbildad tolk
        5: this.$t('certified_in_health', { additional: additional['certified_in_health'] ?? '' }), //ST - Sjukvårdstolk
        6: this.$t('certified_in_law', { additional: additional['certified_in_law'] ?? '' }) //RT - Rättstolk
      }

      return objLevel[level]
    },

    setGenderPreference(gender) {
      return !isNil(gender) && !isEmpty(gender) ? this.$t(`${gender}`) : [this.$t('male'), this.$t('female')].join(', ')
    },

    toggleMoreInformations() {
      this.showMoreInfo = !this.showMoreInfo
    },

    /**
     * Small helper to determine what booking type is current on process.
     *
     * @param {string} type - The value to be evaluated.
     * @return {boolean}
     */
    isBookingType(type) {
      return this.booking.type === type
    },
    /**
     * @return {string}
     */
    translateLanguagePairLevelCode(code) {
      switch (code) {
        case 'certified':
          return this.isBookingTypeTextTranslation
            ? this.$t('pair_level_certified')
            : this.$t('pair_level_certified_interpreter')
        case 'senior':
          return this.isBookingTypeTextTranslation
            ? this.$t('pair_level_senior_translator')
            : this.$t('pair_level_senior_interpreter')
        case 'non-certified':
          return this.isBookingTypeTextTranslation
            ? this.$t('tt_pair_level_non_certified')
            : this.$t('pair_level_non_certified_interpreter')
        default:
          return code
      }
    },
    /**
     * @return {string}
     */
    limitTextLength(text, limit = 413) {
      return text.slice(0, limit)
    },
    /**
     * @return {boolean}
     */
    shouldShowShowMore(text, limit = 413) {
      return text.length > limit
    },
    toggleShowMore() {
      this.isShowFullText = !this.isShowFullText
    },
    /**
     * method for customer accepting a text translation cost
     */
    handleAcceptPrice() {
      let payload = {
        id: this.booking.text_translation.id
      }
      this.acceptPrice(payload).then(() => {
        this.$emit('updateList')
        this.isShowModal = false
      })
    },
    /**
     * method for customer reject a text translation cost
     */
    handleRejectPrice() {
      let payload = {
        id: this.booking.text_translation.id
      }
      this.rejectPrice(payload).then(() => {
        this.$emit('updateList')
        this.isShowModal = false
      })
    },

    handleDropDownCommand(command) {
      if (command.action === 'resubmit') {
        this.$refs['resubmit-translated-file-modal'].open(command.data)
      }
      if (command.action === 'download') this.downloadSelectedFile(command.data)
    },

    //
    handleResubmitTranslatedFile(item = {}) {
      let payload = {
        id: this.booking.text_translation.id,
        file_id: item.id
      }
      if (item.reason) {
        payload.reason = item.reason
      }

      this.resubmitTranslatedFile(payload).then(() => {
        this.isShowModal = false
      })
    },
    address1() {
      if (!isNil(this.userMetaData.translator_data) && !isNil(this.userMetaData.translator_data.address)) {
        return this.userMetaData.translator_data.address
      }
    },

    formatDuration(durationInMinutes) {
      let duration = moment.duration(durationInMinutes, 'minutes')

      let hours = Math.floor(duration.asHours())
      let minutes = Math.floor(duration.asMinutes()) - hours * 60

      let formattedDuration
      if (hours < 1) {
        formattedDuration = `${minutes}m`
      } else {
        formattedDuration = `${hours}h ${minutes}m`
      }
      return formattedDuration
    },
    close() {
      this.isShowModal = false
      this.$emit('close')
    },
    handleClickAccept(booking) {
      if (this.option?.time_slot) {
        this.form.time_slot = this.option.time_slot
      }
      // console.log(this.form)
      this.$emit('clickAccept', booking, false, this.form)
      // this.close()
    },
    handleClickReject(booking) {
      this.$emit('clickReject', booking)
      this.close()
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables/index.scss';
@import '@/assets/scss/global/index.scss';

.booking-details-dialog.el-dialog {
  padding: 0;

  .mg-top {
    margin: 15px 0;
  }

  .mg-bottom {
    margin-bottom: 15px;
  }

  .more-booking-informations {
    border: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    padding: 10px;
    border-radius: 3px;
    background: #f7f7f7;
    cursor: pointer;

    i {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .more-booking-informations:hover {
    border: 1px solid #d3d3d3;
    background: #ebebeb;
  }

  @media (max-width: $screen-xs-max) {
    .more-booking-informations {
      width: 100%;
    }
  }

  .booking-id-row {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 10px;
    margin: 5px 0 10px 0;
    width: 100%;
    padding: 0px calc(119px - 20px);

    &.padding10 {
      padding: 0 10px !important;
    }

    .col {
      width: 100%;
    }

    &.two-col {
      .col {
        width: 50%;
      }
    }

    .label-capitalize {
      font-size: 18px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #8d8f9d;
    }

    .label-value {
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #555555;
    }
  }

  .el-dialog__header {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    padding: 17px 30px !important;

    .header {
      justify-content: flex-start !important;
      color: var(--app-defult-color) !important;
      // padding: 0px calc(119px - 20px);
    }

    .el-dialog__close {
      color: #8d8f9d;
      font-size: 28px;
      right: 10px;
      top: 5px;
    }
  }

  .el-dialog__body {
    .popup-content {
      height: unset;
      max-height: unset;
    }
  }

  .is-physical {
    padding: 0 !important;
  }

  .booking-details-modal {
    display: flex;
    flex-flow: column;
    gap: 20px;
    // margin-top: -6px;

    * {
      box-sizing: border-box;
      word-break: break-word;
    }

    padding: 0px calc(119px - 20px);

    .heading {
      font-weight: 600;
      font-size: 16px;
      line-height: 125%;
      color: #555555;
      margin: 0;
      padding: 0;
    }

    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #bcc1cc;
      border-radius: 4px;
      padding: 4px 8px;
      gap: 8px;
      cursor: pointer;

      svg {
        width: 12px;
        height: 12px;
        fill: #736e69;
      }

      .text {
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: #736e69;
      }
    }

    .common-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 2px;
    }

    .kilometer {
      padding-left: 5px;
    }

    .location {
      h3 {
        margin: 0 !important;
      }

      .row {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 14px;

        .col {
          width: 100%;
        }

        &.two-col {
          .col {
            width: 50%;
          }
        }
      }

      .iconsCol {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
      }

      .borderVertical {
        height: 50px;
        border-right: 2px dashed #737373;
      }

      .sourceLocation {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 56px;
      }

      .secondary {
        color: #aab1c2;
      }

      .destLocation {
        margin-top: 38px;
      }

      .fullCol {
        width: 100%;
        position: relative;
        margin-top: 5px;
      }

      .travel-mode-icon {
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 5px;
        //.secondary{
        //  margin: 0 5px ;
        //}
        .time {
          margin: 0 10px;
        }
      }

      .way-of-transit {
        //.secondary {
        //}

        ul {
          margin: 10px 0;
          padding-inline-start: 0 !important;

          li {
            border: 1px solid #e8e8e8;
            border-radius: 20px;
            padding: 0 10px;
            margin: 5px 0;
            background-color: #d6d6d6;
          }
        }
      }
    }

    .specs-container {
      display: flex;
      flex-direction: column;
      padding: 30px 30px 30px 20px;
      width: 100%;
      background: #f7f7f7;
      border: 1px solid #e7f4f0;
      border-radius: 6px;
      gap: 5px;

      .label {
        font-weight: 600;
        font-size: 14px;
        line-height: 125%;
        color: #555555;
      }

      .value {
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: #736e69;
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        &.link {
          display: inline;
          font-weight: 600;
          text-decoration-line: underline;
          color: var(--app-primary-color);
        }
      }

      .value2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: #736e69;
        display: flex;
        flex-flow: row;

        &.link {
          display: inline;
          font-weight: 700;
          text-decoration-line: underline;
          color: var(--app-primary-color);
        }
      }

      .title {
        @extend .value;
        display: flex;
        justify-content: space-between;
      }

      &.no-seconday-prefs {
        .basic-info,
        .more-info,
        .booking-details {
          .primary-prefs-container {
            width: 100%;

            .row {
              width: 100%;

              .show-more {
                cursor: pointer;
              }

              .col {
                width: 50%;
              }
            }

            .tt-asking-price {
              .col {
                align-content: flex-end;

                &.row {
                  display: flex;
                  gap: 2rem;
                  align-items: center;
                }
              }

              .el-button {
                padding: 8px 10px !important;
              }
            }
          }
        }
      }

      .basic-info,
      .more-info,
      .booking-details {
        display: flex;
        flex-direction: row;
        gap: 30px;
        width: 100%;

        .primary-prefs-container,
        .secondary-prefs-container {
          width: 447px;

          .row {
            display: flex;
            gap: 20px;
            // margin-bottom: 32px;
            margin-bottom: 12px;

            width: 100%;

            &:last-child {
              margin-bottom: 0px;
            }

            .col:nth-child(1) {
              min-width: 179px;
            }

            .col:nth-child(2) {
              min-width: 247px;
            }
          }
        }

        .secondary-prefs-line {
          display: flex;
          width: 0px;
          border: 1px solid #e4e7ed;
        }

        .secondary-prefs-container {
          display: flex;
          width: 257px;
          align-items: start;
          flex-flow: column;

          .title {
            width: 100%;
          }
        }
      }

      .pd-top {
        padding-top: 0px !important;
      }

      .more-info {
        padding-top: 12px;
      }

      .additional-specs-preview {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0px 0px;
        width: 722px;
        height: 40px;
        border-top: 1px solid #d9d9d9;

        .wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0px;
          gap: 10px;
          cursor: pointer;

          .text {
            font-weight: 600;
            font-size: 16px;
            line-height: 125%;
            color: var(--app-primary-color);
          }

          .arrow {
            fill: #736e69;
            width: 9.31px;
            height: 5.49px;
          }
        }
      }
    }

    .booking-details {
      display: flex;
      flex-flow: column;
      padding: 0px 20px;
      gap: 16px;
      position: relative;

      .btn {
        position: absolute;
        right: 20px;
        top: 0;
      }

      .row {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
        row-gap: 14px;

        .col {
          width: 100%;
        }

        &.two-col {
          .col {
            width: 50%;
          }
        }
      }

      .block {
        @extend .common-block;

        .label {
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          color: #8a91a0;
        }

        .value {
          @extend .label;
          font-weight: 600;
          color: #555555;

          &.link {
            font-weight: 600;
            text-decoration-line: underline;
            color: var(--app-primary-color);
          }
        }
      }

      .files-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 10px;
        flex-wrap: wrap;

        .file {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
          gap: 8px;
          background: #e4e8f0;
          border-radius: 4px;

          .icon {
            width: 15px;
            height: 15px;
            fill: #aab1c2;
          }

          .label {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #555555;
          }

          .icon-button {
            outline: none;
            border: none;
            width: 28px;
            height: 24px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 4px;
            border-radius: 4px;

            &:disabled {
              cursor: not-allowed;
            }

            &.success {
              background-color: var(--app-primary-color);

              .icon svg {
                fill: #ffffff;
              }
            }

            .icon {
              width: 20px;
              height: 20px;
              padding: 2px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                fill: #555555;
                width: 16px;
                height: 10.67px;
              }
            }
          }
        }
      }

      .text-files-table {
        thead {
          th {
            background: rgba(248, 248, 248, 1);
          }
        }

        .el-table__body-wrapper {
          .el-table__cell {
            padding: 0 1rem;

            .cell {
              height: 4rem;
              padding: 0;

              .translated-column {
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;

                .dropdown-btn {
                  padding: 2px 8px;
                  background: #fff;
                  border-radius: 4px;
                  border: 1px solid;
                }
              }

              .original-column {
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;

                .download-btn {
                  cursor: pointer;
                  padding: 2px 4px;
                  background: #fafafa;
                  border-radius: 4px;
                  border: 1px solid;
                }
              }
            }
          }
        }
      }

      .auto-review-files-table {
        thead {
          th {
            background: rgba(248, 248, 248, 1);
          }
        }

        .el-table__body-wrapper {
          .el-table__cell {
            padding: 0 1rem;

            .cell {
              padding: 0;

              .translated-file-item {
                display: flex;
                flex-direction: column;
                row-gap: 12px;
                padding: 8px 0px;

                .translated-column {
                  display: flex;
                  justify-content: space-between;
                  height: 100%;
                  align-items: center;

                  .dropdown-btn {
                    padding: 2px 8px;
                    background: #fff;
                    border-radius: 4px;
                    border: 1px solid;
                  }
                }
              }

              .original-column {
                display: flex;
                justify-content: space-between;
                height: 100%;
                align-items: center;

                .download-btn {
                  cursor: pointer;
                  padding: 2px 4px;
                  background: #fafafa;
                  border-radius: 4px;
                  border: 1px solid;
                }
              }
            }
          }
        }
      }
    }

    .booking-question {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 20px 40px;
      isolation: isolate;
      border-top: 1px solid #bcc1cc;
      gap: 22px;
      position: relative;

      .btn {
        position: absolute;
        top: 30px;
        right: 20px;
      }

      .block {
        @extend .common-block;
        gap: 4px;

        .question {
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          color: #555555;
        }

        .answer {
          @extend .question;
          font-weight: 600;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .booking-details-modal {
      padding: 0px;
    }
    .row {
      display: flex;
      flex-direction: column;
    }
  }
}

.translated-file-dropdown {
  width: 12rem;

  .el-dropdown-menu__item {
    display: flex;
    justify-content: space-between;
  }
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  html,
  body {
    overflow: hidden !important;

    * {
      visibility: hidden;
      overflow: hidden !important;
    }
  }

  img[alt='Upsales pixel'] {
    display: none;
  }

  .booking-details-dialog {
    .popup-content {
      * {
        visibility: visible;
      }

      visibility: visible !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
      overflow: visible;
      padding: 0px !important;

      .booking-details-modal {
        padding: 0px !important;
        overflow: visible;
      }
    }
  }
}
</style>
