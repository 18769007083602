/*
|--------------------------------------------------------------------------
| Store > Statistics > State
|--------------------------------------------------------------------------
| File that contains the state property for the current Vuex scope.
*/

export default function () {
  return {
    customerStatistics: {
      data: {},
      loading: false
    },

    translatorStatistics: {
      data: {},
      loading: false
    }
  }
}
